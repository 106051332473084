import React, { PureComponent } from 'react'
import { RouteComponentProps, withRouter } from '@deal/router'
import { IdentityConsumer } from '#src/app/context/Identity'
import { WithHostAwareConfigProps, withHostAwareConfig } from '#src/app/containers/HostAwareConfig'
import PageContent from '#src/app/components/PageContent'
import ExternalUrlAwareRedirect from '#src/app/components/ExternalUrlAwareRedirect'
import AssumeOperatorIdentity from '../AssumeOperatorIdentity'

export default class Authenticate extends PureComponent<React.PropsWithChildren> {
  public render() {
    return (
      <IdentityConsumer>
        {({ myself }) => {
          if (myself) {
            if (myself.operator) {
              return this.props.children
            } else if (myself.realUser.assignedOperatorSeat) {
              return <AssumeOperatorIdentity operatorId={myself.realUser.assignedOperatorSeat.id} />
            } else {
              return (
                <PageContent>
                  <h1>
                    You are not associated with any operator at this point. To request access,{' '}
                    <a href="mailto:support@curated.com" target="_top">
                      contact support
                    </a>
                    .
                  </h1>
                </PageContent>
              )
            }
          } else {
            return <RedirectToLogin />
          }
        }}
      </IdentityConsumer>
    )
  }
}

const RedirectToLogin = withRouter(
  withHostAwareConfig((props: RouteComponentProps<any> & WithHostAwareConfigProps) => {
    const { config, location } = props
    const consumer = config.get('consumer')
    const ops = config.get('ops')

    const returnUrl =
      process.env.target === 'web'
        ? window.location.href
        : `${ops.protocol}://${ops.host}:${ops.port}${location.pathname}${location.search}`

    const loginUrl = `${consumer.protocol}://${consumer.host}:${consumer.port}/auth/login?then=${returnUrl}`
    return <ExternalUrlAwareRedirect to={loginUrl} />
  })
)
