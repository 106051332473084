import { OperatorFragment } from '#src/app/fragments/Operator.generated'

enum OperatorRole {
  ORDER_SUPPORT = 'orderSupport',
  ORDER_SUPPORT_LEAD = 'orderSupportLead',
  LEAD_SUPPORT = 'leadSupport',
  FULFILLMENT_AGENT = 'fulfillmentAgent'
}

export interface OperatorPermissions {
  isAdmin: boolean
  isLeadSupport: boolean
}

export default function (operator: OperatorFragment): OperatorPermissions {
  const { isAdmin, assignedRoles } = operator
  const roles = assignedRoles.map(role => role.name)
  return {
    isAdmin,
    isLeadSupport: roles.includes(OperatorRole.LEAD_SUPPORT) || isAdmin
  }
}
