import React, { useEffect, useState } from 'react'

export default function clientOnly<P>(InnerComponent: React.ComponentType<React.PropsWithChildren<P>>) {
  const WrapperComponent: React.FC<React.PropsWithChildren<P>> = props => {
    const [isMounted, setIsMounted] = useState(false)
    useEffect(() => setIsMounted(true), [])

    if (isMounted) {
      return <InnerComponent {...props} />
    } else {
      return null
    }
  }

  return WrapperComponent
}
