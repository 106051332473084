import React from 'react'

// Context
type ApolloReconnectContext = (() => void) | null
const ApolloReconnect = React.createContext<ApolloReconnectContext>(null)

// Consumer
const ApolloReconnectConsumer = ApolloReconnect.Consumer

// Provider
// This provider is a small wrapper around the context provider, in order to handle the loading state
const ApolloReconnectProvider = ApolloReconnect.Provider

export { ApolloReconnectProvider, ApolloReconnectConsumer }
