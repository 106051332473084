import React from 'react'
import { Link } from '@deal/router'
import { Button, Logo } from '@deal/components'
import NotFoundImage from './not-found.png'
import styles from './styles.css'

const NotFound = () => (
  <section className={styles.container}>
    <header className={styles.header}>
      <Link to="/">
        <Logo mark="combo" color="dark" className={styles.desktopIcon} />
        <Logo mark="icon" color="dark" className={styles.mobileIcon} />
      </Link>
    </header>
    <div className={styles.image}>
      <img src={NotFoundImage} />
    </div>
    <h1 className={styles.headline}>Oops. The page you were looking for doesn&apos;t exist.</h1>
    <p className={styles.body}>You may have mistyped the address or the page has moved.</p>
    <div className={styles.action}>
      <Link to="/">
        <Button>Take me home</Button>
      </Link>
    </div>
  </section>
)

export default NotFound
