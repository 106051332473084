import React, { useContext } from 'react'
import { CallInfo } from '#src/app/context/CallHandling/CallHandlingProvider'
import type { Connection } from 'twilio-client'

export type CallHandlingContextType =
  | {
      setConnection: (conn: Connection) => void
      setCallRecipient: (callRecipient: CallInfo) => void
      isCallInProgress: () => boolean
    }
  | undefined

const CallHandlingContext = React.createContext<CallHandlingContextType>(undefined)

const CallHandlingContextProvider = CallHandlingContext.Provider
const CallHandlingContextConsumer = CallHandlingContext.Consumer

const useCallHandlingContext = () => {
  const callHandlingContext = useContext(CallHandlingContext)

  if (!callHandlingContext) {
    throw new Error('Invoked `CallHandlingContext` outside of provider')
  }

  return callHandlingContext
}

export {
  useCallHandlingContext,
  CallHandlingContextConsumer,
  CallHandlingContextProvider,
  CallHandlingContext
}
