import React, { useContext } from 'react'
import { ExperimentClient, ExperimentClientProvider } from '@deal/experiment-js'
import { IdentityContext } from '#src/app/context/Identity'

export interface UserExperimentClientProviderProps {
  experimentClient: ExperimentClient
  overrideId?: string
  forceExperimentDefaults?: boolean
}

const UserExperimentClientProvider: React.FC<React.PropsWithChildren<
  UserExperimentClientProviderProps
>> = ({ overrideId, experimentClient, children }) => {
  const { myself } = useContext(IdentityContext)
  const operatorId = myself && myself.operator && myself.operator.id
  const isImpersonating = myself && myself.user.id !== myself.realUser.id

  return (
    <ExperimentClientProvider
      experimentClient={experimentClient}
      id={overrideId || operatorId}
      options={{
        trackImpression: !isImpersonating
      }}
    >
      {children}
    </ExperimentClientProvider>
  )
}

export default UserExperimentClientProvider
