import React, { useEffect, useState } from 'react'
import cookies from 'js-cookie'
import { useTwilioDeviceContext } from '#src/app/context/TwilioDevice'
import CurrentCallPopup from '#src/app/components/CallHandler/CurrentCallPopup'
import type { Connection } from 'twilio-client'
import { CallHandlingContextProvider } from '..'

export interface CallInfo {
  displayName?: string
  phoneNumber: string
}

interface CallHandlingProviderProps {
  children: React.ReactNode
}

const CallHandlingProvider: React.FC<React.PropsWithChildren<CallHandlingProviderProps>> = ({ children }) => {
  const { device } = useTwilioDeviceContext()
  const [connection, setConnection] = useState<Connection>()
  const [callInfo, setCallInfo] = useState<CallInfo>()

  useEffect(() => {
    const onDialing = (conn: Connection) => setConnection(conn)
    const onDisconnect = () => {
      setConnection(undefined)
      setCallInfo(undefined)
    }

    if (device) {
      device.on('dialing', onDialing)
      device.on('disconnect', onDisconnect)

      return () => {
        device.off('dialing', onDialing)
        device.off('disconnect', onDisconnect)
      }
    }
  }, [device])

  /**
   * Call info is set when the outgoing call is made.
   * Always try to show the displayName, if not, show the phone number.
   * As a last resort we pull the phone number from the connection.
   * If that fails, show 'Unknown'
   */
  const displayName =
    callInfo?.displayName ||
    callInfo?.phoneNumber ||
    connection?.customParameters.get('To') ||
    'Unknown'

  return (
    <>
      {connection && <CurrentCallPopup displayName={displayName} connection={connection} />}
      {/* For outbound calls, if the call info isn't set, we can only show the phone number. */}
      <CallHandlingContextProvider
        value={{
          setCallRecipient: (callRecipient: CallInfo) => setCallInfo(callRecipient),
          setConnection: (conn: Connection) => setConnection(conn),
          // this cookie is set when CurrentCallPopup is mounted from any window of ops
          isCallInProgress: () => !!cookies.get('curated_call_in_progress')
        }}
      >
        {children}
      </CallHandlingContextProvider>
    </>
  )
}

export default CallHandlingProvider
