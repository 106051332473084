import React, { useEffect, useState } from 'react'
import { Connection } from 'twilio-client'
import ReactDOM from 'react-dom'
import cookies from 'js-cookie'
import classnames from 'classnames'
import { useInterval } from '@deal/dom-hooks'
import { BubbleLoader, TextField } from '@deal/components'
import { toastError } from '#src/app/utils/toast'
import { handleTwilioError } from '#src/app/services/calling'
import clientOnly from '#src/app/hocs/clientOnly'
import UnmuteIcon from './icons/unmute.svg'
import PhoneIcon from './icons/phone.svg'
import MuteIcon from './icons/mute.svg'
import KeypadIcon from './icons/keypad.svg'
import styles from './styles.css'

const Timer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [callTime, setCallTime] = useState(0)

  useInterval(() => setCallTime(callTime + 1), 1000)

  const formatTime = (secondsElapsed: number) => {
    secondsElapsed = secondsElapsed < 0 ? 0 : secondsElapsed
    const minutes = Math.floor(secondsElapsed / 60) || '00'
    const seconds = secondsElapsed % 60
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }

  return <div className={styles.time}>{formatTime(callTime)}</div>
}

interface CurrentCallPopupProps {
  displayName: string
  connection: Connection
}

const CurrentCallPopup: React.FC<React.PropsWithChildren<CurrentCallPopupProps>> = ({ displayName, connection }) => {
  const [callAnswered, setCallAnswered] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [showKeypad, setShowKeypad] = useState(false)

  useEffect(() => {
    const onCallAccepted = () => setCallAnswered(true)
    const onMuteStatusChanged = (isConnectionMuted: boolean) => setIsMuted(isConnectionMuted)
    const onError = (error: Connection.Error) => handleTwilioError(error)

    connection.on('accept', onCallAccepted)
    connection.on('mute', onMuteStatusChanged)
    connection.on('error', onError)

    // there's a case where an agent could be on a call, and attempt to make an outgoing call for some reason.
    // we set a cookie so that different windows of ops can know if a call is already in progress.
    cookies.set('curated_call_in_progress', 'true')
    const removeCallCookie = () => cookies.remove('curated_call_in_progress')
    window.addEventListener('beforeunload', removeCallCookie)

    return () => {
      connection.off('accept', onCallAccepted)
      connection.off('mute', onMuteStatusChanged)
      connection.off('error', onError)
      removeCallCookie()
      window.removeEventListener('beforeunload', removeCallCookie)
    }
  }, [])

  return ReactDOM.createPortal(
    <div className={callAnswered ? styles.acceptedCall : styles.connectingCall}>
      {callAnswered ? (
        <div className={styles.details}>
          <div>{`Call with ${displayName}`}</div>
          <Timer />
        </div>
      ) : (
        <div className={styles.details}>
          <div>{`Calling ${displayName}`}</div>
          <BubbleLoader className={styles.loader} />
        </div>
      )}
      <div className={styles.buttonContainer}>
        <div className={styles.endButton} onClick={() => connection.disconnect()}>
          <PhoneIcon />
          End
        </div>
        <div
          className={classnames(styles.button, { [styles.selected]: isMuted })}
          onClick={() => connection.mute(!isMuted)}
        >
          {isMuted ? <UnmuteIcon /> : <MuteIcon />}
          {isMuted ? 'Unmute' : 'Mute'}
        </div>
        <div
          className={classnames(styles.button, {
            [styles.selected]: showKeypad,
            [styles.disabled]: !callAnswered
          })}
          onClick={() => callAnswered && setShowKeypad(!showKeypad)}
        >
          <KeypadIcon />
          {showKeypad ? 'Hide' : 'Keypad'}
        </div>
      </div>
      {showKeypad && (
        <div className={styles.keypad}>
          <TextField
            labelHidden
            label="keypad"
            size="small"
            placeholder="use your keyboard as a keypad..."
            // Send the last digit, but keep the rest of the digits as history
            onChange={e => {
              const validDigitsRegex = /\b[0-9]\b|\#|\*/
              const newDigit = e.target.value.charAt(e.target.value.length - 1)
              if (validDigitsRegex.test(newDigit)) {
                connection.sendDigits(newDigit)
              } else {
                toastError(`'${newDigit}' is not a valid digit. Only 0-9, *, and # are valid.`)
              }
            }}
          />
        </div>
      )}
    </div>,
    document.body
  );
}

export default clientOnly(CurrentCallPopup)
