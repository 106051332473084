// @ts-nocheck
import type * as Types from '../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type TrackOnlineActivityMutationVariables = Types.Exact<{
  input: Types.TrackOnlineActivityInput;
}>;


export type TrackOnlineActivityMutation = { __typename: 'Mutation', trackOnlineActivity: { __typename: 'TrackOnlineActivityPayload', hasError: boolean } };


export const TrackOnlineActivityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"TrackOnlineActivity"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TrackOnlineActivityInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"trackOnlineActivity"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasError"}}]}}]}}]} as unknown as DocumentNode;
export type TrackOnlineActivityMutationFn = Apollo.MutationFunction<TrackOnlineActivityMutation, TrackOnlineActivityMutationVariables>;
export type TrackOnlineActivityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TrackOnlineActivityMutation, TrackOnlineActivityMutationVariables>, 'mutation'>;

    export const TrackOnlineActivityComponent = (props: TrackOnlineActivityComponentProps) => (
      <ApolloReactComponents.Mutation<TrackOnlineActivityMutation, TrackOnlineActivityMutationVariables> mutation={TrackOnlineActivityDocument} {...props} />
    );
    

/**
 * __useTrackOnlineActivityMutation__
 *
 * To run a mutation, you first call `useTrackOnlineActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackOnlineActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackOnlineActivityMutation, { data, loading, error }] = useTrackOnlineActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackOnlineActivityMutation(baseOptions?: Apollo.MutationHookOptions<TrackOnlineActivityMutation, TrackOnlineActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackOnlineActivityMutation, TrackOnlineActivityMutationVariables>(TrackOnlineActivityDocument, options);
      }
export type TrackOnlineActivityMutationHookResult = ReturnType<typeof useTrackOnlineActivityMutation>;
export type TrackOnlineActivityMutationResult = Apollo.MutationResult<TrackOnlineActivityMutation>;
export type TrackOnlineActivityMutationOptions = Apollo.BaseMutationOptions<TrackOnlineActivityMutation, TrackOnlineActivityMutationVariables>;