import config from '#src/app/config'

export default function persistedQueries(urlString: string): boolean {
  const url = new URL(urlString)
  const queryParams = new URLSearchParams(url.search)
  const persistedQueries = queryParams.get('persistedQueries')
  return persistedQueries
    ? persistedQueries.toLowerCase() == 'true'
    : config.get('persistedQueries')
}
