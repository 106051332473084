// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type HeartbeatSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type HeartbeatSubscription = { __typename: 'Subscription', heartbeat: boolean };


export const HeartbeatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"Heartbeat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"heartbeat"}}]}}]} as unknown as DocumentNode;
export type HeartbeatComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<HeartbeatSubscription, HeartbeatSubscriptionVariables>, 'subscription'>;

    export const HeartbeatComponent = (props: HeartbeatComponentProps) => (
      <ApolloReactComponents.Subscription<HeartbeatSubscription, HeartbeatSubscriptionVariables> subscription={HeartbeatDocument} {...props} />
    );
    

/**
 * __useHeartbeatSubscription__
 *
 * To run a query within a React component, call `useHeartbeatSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHeartbeatSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeartbeatSubscription({
 *   variables: {
 *   },
 * });
 */
export function useHeartbeatSubscription(baseOptions?: Apollo.SubscriptionHookOptions<HeartbeatSubscription, HeartbeatSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<HeartbeatSubscription, HeartbeatSubscriptionVariables>(HeartbeatDocument, options);
      }
export type HeartbeatSubscriptionHookResult = ReturnType<typeof useHeartbeatSubscription>;
export type HeartbeatSubscriptionResult = Apollo.SubscriptionResult<HeartbeatSubscription>;