// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CreateVoiceTokenMutationVariables = Types.Exact<{
  id: Types.Scalars['AnyId']['input'];
}>;


export type CreateVoiceTokenMutation = { __typename: 'Mutation', createVoiceToken: { __typename: 'CreateVoiceTokenPayload', token: string } };


export const CreateVoiceTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateVoiceToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AnyId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createVoiceToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"messagingUserId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}}]}}]}}]} as unknown as DocumentNode;
export type CreateVoiceTokenMutationFn = Apollo.MutationFunction<CreateVoiceTokenMutation, CreateVoiceTokenMutationVariables>;
export type CreateVoiceTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateVoiceTokenMutation, CreateVoiceTokenMutationVariables>, 'mutation'>;

    export const CreateVoiceTokenComponent = (props: CreateVoiceTokenComponentProps) => (
      <ApolloReactComponents.Mutation<CreateVoiceTokenMutation, CreateVoiceTokenMutationVariables> mutation={CreateVoiceTokenDocument} {...props} />
    );
    

/**
 * __useCreateVoiceTokenMutation__
 *
 * To run a mutation, you first call `useCreateVoiceTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVoiceTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVoiceTokenMutation, { data, loading, error }] = useCreateVoiceTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateVoiceTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateVoiceTokenMutation, CreateVoiceTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVoiceTokenMutation, CreateVoiceTokenMutationVariables>(CreateVoiceTokenDocument, options);
      }
export type CreateVoiceTokenMutationHookResult = ReturnType<typeof useCreateVoiceTokenMutation>;
export type CreateVoiceTokenMutationResult = Apollo.MutationResult<CreateVoiceTokenMutation>;
export type CreateVoiceTokenMutationOptions = Apollo.BaseMutationOptions<CreateVoiceTokenMutation, CreateVoiceTokenMutationVariables>;