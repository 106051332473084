import React, { ComponentType } from 'react'
import ReactDOM from 'react-dom/client'
import { loadableReady } from '@loadable/component'
import schemaFragments from '#src/generated/schema.fragments'
import schemaNonNormalizableTypes from '#src/generated/schema.non-normalizable-types'
import schemaRelayStylePaginationFields from '#src/generated/schema.relay-style-pagination-fields'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from '@deal/router'
import { HelmetProvider } from 'react-helmet-async'
import { AnalyticsProvider } from '#src/app/containers/Analytics'
import { IdentityProvider } from '#src/app/context/Identity'
import { MyselfQuery, MyselfDocument } from '#src/app/context/Identity/Myself.generated'
import {
  TrackOnlineActivityDocument,
  TrackOnlineActivityMutation,
  TrackOnlineActivityMutationVariables
} from '#src/app/mutations/TrackOnlineActivity.generated'
import StalePageRefresher from '@deal/refresh-stale-page'
import PageInteractionObserver from '@deal/page-interaction-observer'
import { createBrowserExperimentClient, BrowserExperimentClientConfig } from '@deal/experiment-js'
import UserExperimentClientProvider from '#src/app/containers/UserExperimentClientProvider'
import { createBrowserGraphQLClient, ViewerContext as APIViewerContext } from '@deal/api-client-js'
import { PageInteractedEvent, TrackingClient } from '@deal/web-tracking'
import { ViewerContext } from '@deal/web-tracking/constants'
import rawConfig from '#src/app/config'
import App from '#src/app/containers/App'
import { ErrorResponse } from '@apollo/client/link/error'
import Heartbeat from '#src/app/components/Heartbeat'
import { ApolloReconnectProvider } from '#src/app/containers/ApolloReconnect'
import { HostnameProvider, Hostname } from '#src/app/containers/Hostname'
import { createHostAwareConfig, HostAwareConfigProvider } from '#src/app/containers/HostAwareConfig'
import { UserAgentProvider } from '#src/app/containers/UserAgent'
import { TwilioDeviceProvider } from '#src/app/context/TwilioDevice'
import loggerClient from '#src/app/services/loggerClient'
import { getForceTraceId } from '#src/app/services/tracing'
import persistedQueries from '#src/app/services/persistedQueries'

// Refresh the page/tab if it's been backgrounded for over 1 hour
StalePageRefresher.monitor()

// Hostname
const fullHostname = window.location.hostname.toLowerCase()
const hostname = fullHostname.endsWith(rawConfig.get('gcpHostSuffix'))
  ? Hostname.Gcp
  : Hostname.Curated

const config = createHostAwareConfig(hostname)

// Allow override of optimizely variations via the url
const urlQueryString = window.location.search
const urlParams = new URLSearchParams(urlQueryString)
const forceDefaultTreatments =
  urlParams.get('forceDefaultTreatments') === 'true' || window.__IS_BOT__
const experimentOverrides = urlParams.get('experimentOverrides') || undefined
const experimentBucketingKey = urlParams.get('experimentBucketingKey') || undefined

// Callback for Apollo GraphQL errors
const handleError = (error: ErrorResponse) => {
  const { graphQLErrors, networkError, operation } = error
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      loggerClient.captureGQLError(error, operation)
    })
  }
  if (networkError) {
    loggerClient.captureNetworkError(networkError, operation)
  }
}

const handleNetworkError = (networkError: Error) => {
  // @ts-ignore `networkError` is incompletely typed
  //   See: https://github.com/apollographql/apollo-link/issues/300
  if (networkError && networkError.statusCode == 401) {
    const consumer = config.get('consumer')

    // Redirect to login on 401
    const url = window.location.href
    window.location.href = `${consumer.protocol}://${consumer.host}:${consumer.port}/auth/login?then=${url}`
  }
}

// GraphQL client
const { apolloClient, reconnect: apolloReconnect } = createBrowserGraphQLClient({
  protocol: config.get('api.protocol'),
  webSocketProtocol: config.get('api.webSocketProtocol'),
  host: config.get('api.host'),
  port: config.get('api.port'),
  initialCacheState: window.__APOLLO_STATE__,
  cachePossibleTypes: schemaFragments.possibleTypes,
  cacheNonNormalizableTypes: schemaNonNormalizableTypes,
  cacheRelayStylePaginationFields: schemaRelayStylePaginationFields,
  experimentOverrides,
  isBot: () => window.__IS_BOT__,
  viewerContext: () => APIViewerContext.OPERATIONS,
  onIdentityMismatch: () => {
    if (
      window.confirm(
        'It looks like you logged into a different account in another tab. Most features will not work as expected until you refresh the page. Click "Ok" to refresh now.'
      )
    ) {
      window.location.reload()
    }
  },
  onError: handleError,
  onNetworkError: handleNetworkError,
  clientAwarenessName: 'operations-app-client-' + config.get('environment'),
  clientAwarenessVersion: window.__APP_VERSION__,
  forceTrace: () => getForceTraceId(window.location.href),
  persistedQueries: persistedQueries(window.location.href),
  tenantId: () => null
})
delete window.__APOLLO_STATE__

// Rendering
apolloClient
  .query<MyselfQuery>({
    query: MyselfDocument
  })
  .then(({ data }) => {
    const userId = data.me?.id
    const realUserId = data.me?.realUser.id
    const operatorId = data.me?.operator?.id

    // @ts-ignore
    const tracking = window.tracking as TrackingClient

    if (userId !== realUserId) {
      tracking.disable()
    }

    if (operatorId) {
      tracking.identify({ userId, operatorId }, ViewerContext.Operator)
    }

    // Inform the backend that this user is active on the site
    const trackOnlineActivity = () => {
      // Only track non-impersonated, logged-in users
      if (!operatorId || userId !== realUserId) {
        return
      }

      apolloClient.mutate<TrackOnlineActivityMutation, TrackOnlineActivityMutationVariables>({
        mutation: TrackOnlineActivityDocument,
        variables: {
          input: {
            id: operatorId
          }
        }
      })

      tracking.track(new PageInteractedEvent())
    }

    // Track initial user activity (loading this page)
    trackOnlineActivity()

    // Track ongoing user activity (moving mouse, scrolling, etc.)
    const interactionObserver = new PageInteractionObserver(() => {
      trackOnlineActivity()
    })
    interactionObserver.observe()

    // Experiment client
    const fallbackTreatments = window.__EXPERIMENT_TREATMENTS__
    const holdouts = window.__EXPERIMENT_HOLDOUTS__

    const experimentClientConfig: BrowserExperimentClientConfig = {
      graphqlConfig: {
        apolloClient,
        fetchUpdatesIntervalMillis: 60000
      },
      initialExperiments: [],
      experimentOverrides,
      forceDefaultTreatments,
      fallback: fallbackTreatments,
      holdouts,
      trackingClient: () => window.tracking,
      luxClient: () => window.LUX,
      heapClient: () => window.heap
    }
    const experimentClient = createBrowserExperimentClient(experimentClientConfig)

    delete window.__EXPERIMENT_TREATMENTS__
    delete window.__EXPERIMENT_HOLDOUTS__

    // Rendering method
    const renderer = window.__APP_RENDERER__ || 'client'
    delete window.__APP_RENDERER__

    // Rendering function
    const render = (AppComponent: ComponentType<React.PropsWithChildren<unknown>>) => {
      const Root = (
        <HostnameProvider hostname={hostname}>
          <HostAwareConfigProvider>
            <ApolloProvider client={apolloClient}>
              <UserAgentProvider>
                <AnalyticsProvider client={tracking}>
                  <IdentityProvider>
                    <UserExperimentClientProvider
                      experimentClient={experimentClient}
                      overrideId={experimentBucketingKey}
                    >
                      <TwilioDeviceProvider>
                        <Heartbeat />
                        <ApolloReconnectProvider value={apolloReconnect}>
                          <BrowserRouter>
                            <HelmetProvider>
                              <AppComponent />
                            </HelmetProvider>
                          </BrowserRouter>
                        </ApolloReconnectProvider>
                      </TwilioDeviceProvider>
                    </UserExperimentClientProvider>
                  </IdentityProvider>
                </AnalyticsProvider>
              </UserAgentProvider>
            </ApolloProvider>
          </HostAwareConfigProvider>
        </HostnameProvider>
      )

      if (renderer === 'server') {
        loadableReady(() => {
          ReactDOM.hydrateRoot(document.getElementById('deal')!, Root)
        })
      } else {
        const root = ReactDOM.createRoot(document.getElementById('deal')!)
        root.render(Root)
      }
    }

    // Initial render
    render(App)
  })
