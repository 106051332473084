import React, { useContext } from 'react'
import { UAParser } from 'ua-parser-js'
import { DealRequest } from '@deal/web-express-server'


export type UserAgent =
  | {
      browser: UAParser.IBrowser
      device: UAParser.IDevice
      os: UAParser.IOS
      engine: UAParser.IEngine
      cpu: UAParser.ICPU
      raw: string
    }
  | undefined

interface UserAgentProviderProps {
  req?: DealRequest
}

// Context
type UserAgentContextType = UserAgent
const UserAgentContext = React.createContext<UserAgentContextType>(undefined)

// Consumer
const UserAgentConsumer = UserAgentContext.Consumer

// Provider
const UserAgentProvider: React.FC<React.PropsWithChildren<UserAgentProviderProps>> = ({ req, children }) => {
  const parser = new UAParser(req && req.headers['user-agent'])

  return (
    <UserAgentContext.Provider
      value={{
        browser: parser.getBrowser(),
        device: parser.getDevice(),
        os: parser.getOS(),
        engine: parser.getEngine(),
        cpu: parser.getCPU(),
        raw: parser.getUA()
      }}
    >
      {children}
    </UserAgentContext.Provider>
  )
}

const useUserAgentContext = () => {
  const userAgentContext = useContext(UserAgentContext)

  if (!userAgentContext) {
    throw new Error('Invoked UserAgentContext outside of provider')
  }

  return userAgentContext
}

export { UserAgentProvider, UserAgentConsumer, UserAgentContext, useUserAgentContext }
