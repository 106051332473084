import { Permission } from '#src/generated/types'
import { useHasPermission } from '#src/app/utils/permission'
import { useIdentityContext } from '#src/app/context/Identity'
import { useOpenVipTicketCountSubscription } from './OpenVipTicketsCount.generated'

export const useOpenVipTicketsCount = () => {
  const { myself } = useIdentityContext()
  const [canViewTickets] = useHasPermission(Permission.TICKET_SEARCH)

  const { data } = useOpenVipTicketCountSubscription({
    skip: !myself || !canViewTickets
  })

  return data?.openVipTicketsCount.count || 0
}
