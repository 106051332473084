// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type OpenVipTicketCountSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type OpenVipTicketCountSubscription = { __typename: 'Subscription', openVipTicketsCount: { __typename: 'OpenVipTicketCountNotification', count: any } };


export const OpenVipTicketCountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"OpenVipTicketCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"openVipTicketsCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]} as unknown as DocumentNode;
export type OpenVipTicketCountComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OpenVipTicketCountSubscription, OpenVipTicketCountSubscriptionVariables>, 'subscription'>;

    export const OpenVipTicketCountComponent = (props: OpenVipTicketCountComponentProps) => (
      <ApolloReactComponents.Subscription<OpenVipTicketCountSubscription, OpenVipTicketCountSubscriptionVariables> subscription={OpenVipTicketCountDocument} {...props} />
    );
    

/**
 * __useOpenVipTicketCountSubscription__
 *
 * To run a query within a React component, call `useOpenVipTicketCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOpenVipTicketCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenVipTicketCountSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOpenVipTicketCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OpenVipTicketCountSubscription, OpenVipTicketCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OpenVipTicketCountSubscription, OpenVipTicketCountSubscriptionVariables>(OpenVipTicketCountDocument, options);
      }
export type OpenVipTicketCountSubscriptionHookResult = ReturnType<typeof useOpenVipTicketCountSubscription>;
export type OpenVipTicketCountSubscriptionResult = Apollo.SubscriptionResult<OpenVipTicketCountSubscription>;