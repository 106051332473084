import React, { useContext, useEffect, useRef, useState } from 'react'
import { Permission, useHasPermission } from '#src/app/utils/permission'
import { useIdentityContext } from '#src/app/context/Identity'
import type { Device } from 'twilio-client'
import { useCreateVoiceTokenMutation } from './CreateVoiceToken.generated'

// Context
type TwilioDeviceContextType =
  | { device: Device | undefined; setCanCreateDevice: () => void }
  | undefined

const TwilioDeviceContext = React.createContext<TwilioDeviceContextType>(undefined)

// Consumer
const TwilioDeviceConsumer = TwilioDeviceContext.Consumer

// Provider
// This provider is a small wrapper around the context provider, in order to handle the loading state
const TwilioDeviceProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [device, setDevice] = useState<Device>()
  const [canCreateDevice, setCanCreateDevice] = useState(false)
  const { myself, operator } = useIdentityContext()
  const [canMakeCalls] = useHasPermission(Permission.INAPP_OUTGOING_PHONE_CALL)
  const [createVoiceToken] = useCreateVoiceTokenMutation()

  useEffect(() => {
    if (device) {
      device.destroy()
      setDevice(undefined)
    }

    // device should only be created after the user interacts with the page. Otherwise, there is an AudioContext error.
    // https://support.twilio.com/hc/en-us/articles/360003699893-Chrome-66-AudioContext-and-Twilio-JavaScript-Client-1-4
    // For outgoing calls, setCanCreateDevice is called on OutgoingCallPopup mount since you need to click to open the popup.
    // For incoming calls, there is a 'Get started' button that calls setCanCreateDevice.
    if (operator && canMakeCalls && canCreateDevice) {
      createVoiceToken({
        variables: {
          id: operator.id
        }
      }).then(({ data }) =>
        // Cannot import directly. The side-effects breaks SSR
        import('twilio-client').then(twilio => {
          if (data) {
            setDevice(
              new twilio.Device(data.createVoiceToken.token, {
                enableRingingState: true,
                enableIceRestart: true
              })
            )
          }
        })
      )
    }
  }, [myself, canCreateDevice])

  const refreshIntervalRef = useRef<any>()

  // Need to reset the token every 12 hours.
  useEffect(() => {
    if (refreshIntervalRef.current) {
      clearInterval(refreshIntervalRef.current)
      refreshIntervalRef.current = undefined
    }

    if (operator && canMakeCalls && device) {
      refreshIntervalRef.current = setInterval(() => {
        createVoiceToken({
          variables: {
            id: operator.id
          }
        }).then(({ data }) => {
          if (data) {
            device.setup(data.createVoiceToken.token, { enableRingingState: true })
          }
        })
      }, 12 * 60 * 60 * 1000)
    }
  }, [device])

  return (
    <TwilioDeviceContext.Provider
      value={{
        device,
        setCanCreateDevice: () => {
          if (!canCreateDevice) {
            setCanCreateDevice(true)
          }
        }
      }}
    >
      {children}
    </TwilioDeviceContext.Provider>
  )
}

const useTwilioDeviceContext = () => {
  const twilioDeviceContext = useContext(TwilioDeviceContext)

  if (!twilioDeviceContext) {
    throw new Error('Invoked `TwilioDeviceContext` outside of provider')
  }

  return twilioDeviceContext
}

export { TwilioDeviceProvider, TwilioDeviceConsumer, TwilioDeviceContext, useTwilioDeviceContext }
