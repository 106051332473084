import React, { useContext } from 'react'
import { TrackingClient } from '@deal/web-tracking'
export * from './withAnalytics'

interface AnalyticsProviderProps {
  client: TrackingClient
}

// Context
const AnalyticsContext = React.createContext<TrackingClient | undefined>(undefined)

// Consumer
const AnalyticsConsumer = AnalyticsContext.Consumer

// Provider
const AnalyticsProvider: React.FC<React.PropsWithChildren<AnalyticsProviderProps>> = ({
  client,
  children
}) => {
  return <AnalyticsContext.Provider value={client}>{children}</AnalyticsContext.Provider>
}

const useAnalyticsContext = () => {
  const analyticsContext = useContext(AnalyticsContext)

  if (!AnalyticsContext) {
    throw new Error('Invoked AnalyticsContext outside of provider')
  }

  return analyticsContext
}

export {
  AnalyticsProvider,
  AnalyticsProviderProps,
  AnalyticsConsumer,
  AnalyticsContext,
  useAnalyticsContext
}
