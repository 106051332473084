import React from 'react'
import { ToastContainer as ToastifyToastContainer } from 'react-toastify'
import ReactDOM from 'react-dom'
import clientOnly from '#src/app/hocs/clientOnly'
import styles from './styles.css'
import 'react-toastify/dist/ReactToastify.css'

const ToastContainer: React.FC<React.PropsWithChildren<unknown>> = () =>
  ReactDOM.createPortal(
    <ToastifyToastContainer position="bottom-right" hideProgressBar className={styles.toast} />,
    document.body
  )

export default clientOnly(ToastContainer)
