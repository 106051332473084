import React from 'react'
import { ToastOptions, toast } from 'react-toastify'
import classnames from 'classnames'
import SealIcon from './seal-cropped.svg'
import SealUpsideDownIcon from './seal-cropped-180.svg'
import styles from './styles.css'

export const toastSuccess = (toastMessage: string | React.ReactNode, options?: ToastOptions) => {
  const { className, bodyClassName, ...rest }: ToastOptions = options || {}
  toast(
    () => (
      <div>
        <SealIcon className={styles.successIcon} />
        {toastMessage}
      </div>
    ),
    {
      className: classnames(styles.successContainer, className as string),
      bodyClassName: classnames(styles.successBody, bodyClassName as string),
      ...rest
    }
  )
}

export const toastError = (toastMessage: string | React.ReactNode, options?: ToastOptions) => {
  const { className, bodyClassName, autoClose = 10000, ...rest }: ToastOptions = options || {}
  toast(
    () => (
      <div>
        <SealUpsideDownIcon className={styles.errorIcon} />
        {toastMessage}
      </div>
    ),
    {
      className: classnames(styles.errorContainer, className as string),
      bodyClassName: classnames(styles.errorBody, bodyClassName as string),
      autoClose,
      ...rest
    }
  )
}
