import React from 'react'

export enum Hostname {
  Curated,
  Gcp
}

const HostnameContext = React.createContext<Hostname>(Hostname.Curated)
const HostnameConsumer = HostnameContext.Consumer

const HostnameProvider: React.FC<React.PropsWithChildren<{ hostname: Hostname }>> = ({ hostname, children }) => (
  <HostnameContext.Provider value={hostname}>{children}</HostnameContext.Provider>
)

export { HostnameProvider, HostnameConsumer }
