import has from 'lodash/has'
import get from 'lodash/get'

export interface Config {
  get: (path: string) => any
  has: (path: string) => boolean
  toObject: () => object
}

const createConfigClient = (configValues: object): Config => {
  // Emulate the node-config API
  return {
    get: (path: string) => {
      return get(configValues, path)
    },
    toObject: () => {
      return configValues
    },
    has: (path: string) => {
      return has(configValues, path)
    }
  }
}

export default createConfigClient
