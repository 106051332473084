import React, { useEffect, useState } from 'react'
import { Notification } from '@deal/components'
import { useAssumeOperatorIdentityMutation } from '../../mutations/AssumeOperatorIdentity.generated'

interface Props {
  operatorId: string
  onSuccess?: () => void
}

const SetAsOperator: React.FC<React.PropsWithChildren<Props>> = ({ operatorId, onSuccess }) => {
  const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined)
  const [assumeOperatorIdentity] = useAssumeOperatorIdentityMutation({
    variables: {
      operatorId: operatorId
    },
    onCompleted: data => {
      if (!data.assumeOperatorIdentity.myself) {
        setErrorMessage(data.assumeOperatorIdentity.error || 'Setting operation identity failed')
      } else if (onSuccess) {
        onSuccess()
      }
    }
  })
  useEffect(() => {
    assumeOperatorIdentity()
  }, [operatorId])

  if (errorMessage) {
    return <Notification type="error">{errorMessage}</Notification>
  } else {
    return (
      <Notification type="info">Please wait while we set up your operator profile...</Notification>
    )
  }
}

export default SetAsOperator
