export function getForceTraceId(urlString: string): string | null {
  const url = new URL(urlString)
  const queryParams = new URLSearchParams(url.search)
  const forceTraceParam = queryParams.get('forceTrace')

  if (forceTraceParam) {
    return forceTraceParam
  }

  return null
}
