// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SupportConversationsCountSubscriptionVariables = Types.Exact<{
  needResponse?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type SupportConversationsCountSubscription = { __typename: 'Subscription', supportConversationsCount: { __typename: 'SupportConversationCountNotification', conversationsCount: any } };


export const SupportConversationsCountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"SupportConversationsCount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"needResponse"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"supportConversationsCount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"needResponse"},"value":{"kind":"Variable","name":{"kind":"Name","value":"needResponse"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"conversationsCount"}}]}}]}}]} as unknown as DocumentNode;
export type SupportConversationsCountComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<SupportConversationsCountSubscription, SupportConversationsCountSubscriptionVariables>, 'subscription'>;

    export const SupportConversationsCountComponent = (props: SupportConversationsCountComponentProps) => (
      <ApolloReactComponents.Subscription<SupportConversationsCountSubscription, SupportConversationsCountSubscriptionVariables> subscription={SupportConversationsCountDocument} {...props} />
    );
    

/**
 * __useSupportConversationsCountSubscription__
 *
 * To run a query within a React component, call `useSupportConversationsCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSupportConversationsCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportConversationsCountSubscription({
 *   variables: {
 *      needResponse: // value for 'needResponse'
 *   },
 * });
 */
export function useSupportConversationsCountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SupportConversationsCountSubscription, SupportConversationsCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SupportConversationsCountSubscription, SupportConversationsCountSubscriptionVariables>(SupportConversationsCountDocument, options);
      }
export type SupportConversationsCountSubscriptionHookResult = ReturnType<typeof useSupportConversationsCountSubscription>;
export type SupportConversationsCountSubscriptionResult = Apollo.SubscriptionResult<SupportConversationsCountSubscription>;