import { Connection } from 'twilio-client'
import { toastError } from '#src/app/utils/toast'
import loggerClient from '#src/app/services/loggerClient'

export const handleTwilioError = (error: Connection.Error) => {
  // Twilio error messages aren't user friendly, this returns something users can understand and see suggestions to troubleshoot.
  // Twilio errors sometimes contain a twilioError object that has better errors, explanations, and solutions. Use when present.
  // https://www.twilio.com/docs/api/errors

  switch (error.twilioError?.code) {
    case 53001:
      toastError(
        'Unable to establish a call connection. Ensure that you are on a stable internet connection.'
      )
      break

    case 31005:
    case 53405:
      toastError('Call connection was interrupted. Please try again.')
      break

    default:
      switch (error.code) {
        case 31201:
          toastError(
            'Unable to access your microphone. Ensure that your microphone is working and that your browser has access to it. Also check for conflicting apps that might be accessing or attempting to access the microphone.'
          )
          break

        default:
          const errorMessage = error.twilioError?.message || error.message
          loggerClient.captureError(new Error(errorMessage), { error })
          toastError(errorMessage)
      }
  }
}
