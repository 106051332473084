/**
 * Global style imports
 *
 * These must be imported first. Webpack will maintain source order, and we want to be able to override
 *   styles from 3rd party libraries with application styles.
 */
import '@deal/standard-css/curated.min.css'
import '@deal/components/es/index.css'
import '@deal/bluxome/es/index.css'
import '@deal/chat-firebase/es/index.css'
import '@deal/path-flow/es/index.css'
import '@deal/dom-hooks/bodyScrollLock.min.css'
import 'react-table/react-table.css'
import 'react-data-grid/lib/styles.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './fonts.css'
import './styles.css'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { BroadcastChannel } from 'broadcast-channel'
import { matchPath, useHistory } from '@deal/router'
import { DeviceType, usePrevious } from '@deal/dom-hooks'
import { ComponentsProvider } from '@deal/components'
import { AtomicStateProvider } from '@deal/atomic-state'
import loggerClient from '#src/app/services/loggerClient'
import Router from '#src/app/routes'
import {
  MenuItem,
  useNavigationMenus,
  useRecentNavigationMenuItems
} from '#src/app/hooks/useNavigationMenus'
import { useIdentityContext } from '#src/app/context/Identity'
import CallHandlingProvider from '#src/app/context/CallHandling/CallHandlingProvider'
import Authenticate from '#src/app/containers/Authenticate'
import { ApolloReconnectConsumer } from '#src/app/containers/ApolloReconnect'
import config from '#src/app/config'
import ToastContainer from '#src/app/components/ToastContainer'
import { useUserAgentContext } from '../UserAgent'
import { useAnalyticsContext } from '../Analytics'
import Favicon32 from './favicons/favicon-32x32.png'
import Favicon16 from './favicons/favicon-16x16.png'
import AppleTouchIcon from './favicons/apple-touch-icon.png'

// HACK: The `react-markdown` types import the `ReactType` type from 'react'
//   which has been removed in recent versions of the React typings. We can
//   not install multiple conflicting versions of the React typings, so for
//   now we will patch the typings to include `ReactType`. We should upgrade
//   the `react-markdown` package as soon as possible and remove this type.
declare module 'react' {
  export type ReactType = any
}

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  let authBroadcastChannel: any = null

  const { myself } = useIdentityContext()
  const analytics = useAnalyticsContext()
  const history = useHistory()
  const previousMyself = usePrevious(myself)

  const userAgent = useUserAgentContext()

  // Keep track of recently visited pages
  const { addRecentNavigationItem } = useRecentNavigationMenuItems()
  const menus = useNavigationMenus()
  const allMenuItems: MenuItem[] = menus.reduce((items, menu) => {
    if (menu.menuItems) {
      items = items.concat(menu.menuItems)
    }

    return items
  }, [] as MenuItem[])

  useEffect(() => {
    /**
     * As soon as the App component mounts, set the `historyEventListener` if it isn't already set.
     * We'll use this to track the page views.
     */
    const unregisterHistoryListener = history.listen((location, action) => {
      analytics?.page({
        navigation_type: `client_${action.toLowerCase() as Lowercase<typeof action>}`
      })

      allMenuItems.forEach(menuItem => {
        // remove search params from menu link before comparison
        const match = matchPath(location.pathname, menuItem.to.split('?').at(0)!)
        if (match) {
          addRecentNavigationItem(menuItem)
        }
      })
    })

    /**
     * Identify the user, a null value is acceptable here
     */
    loggerClient.identifyUser({
      id: myself?.user.id
    })

    /**
     * Register the author change listener
     */
    registerAuthChangeListener()

    if (previousMyself && previousMyself.operator?.id !== myself?.operator?.id) {
      broadcastOnAuthChange(myself?.operator?.id)
    }

    /**
     * Clean up the event listener and close the broadcast channel on unmount
     */
    return () => {
      unregisterHistoryListener?.()
      authBroadcastChannel?.close()
    }
  }, [myself, previousMyself])

  /**
   * We use this auth change listener to bind to any identity change. This prevents
   * authorzation errors that would occur on other open tabs.
   */
  const registerAuthChangeListener = () => {
    authBroadcastChannel = new BroadcastChannel('deal-ops-auth')
    authBroadcastChannel.onmessage = (message: any) => {
      const currentOperatorId = myself ? myself.operator?.id : null
      const newOperatorId = message.operatorId

      if (currentOperatorId !== newOperatorId) {
        // Redirect the user to the login screen / ops home
        const ops = config.get('ops')

        window.location.href = `${ops.protocol}://${ops.host}:${ops.port}`
      }
    }

    broadcastOnAuthChange(myself ? myself.operator?.id : null)
  }

  /**
   * Broadcast the authorization change so all other tabs with the same origin can act appropriately
   */
  const broadcastOnAuthChange = (newOperatorId: string) => {
    if (authBroadcastChannel) {
      authBroadcastChannel.postMessage({ operatorId: newOperatorId })
    }
  }

  const env: string = config.get('environment')
  const envTag = env === 'staging' ? ' [STAGING]' : env.startsWith('dev') ? ' [DEV]' : ''

  const deviceType = userAgent.device.type as DeviceType

  return (
    <>
      <Helmet>
        <title>{envTag} Curated Ops</title>
        <meta
          property="og:title"
          content="Curated.com: Your trusted advisor to always getting the best value"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ops.curated.com" />
        <meta
          property="og:description"
          content="Get expert recommendations, price negotiation, and handpicked deals for products, experiences, travel, and more!"
        />
        <meta
          name="description"
          content="Get expert recommendations, price negotiation, and handpicked deals for products, experiences, travel, and more!"
        />
        <meta name="keywords" content="curated" />
        <meta name="msapplication-TileColor" content="#00c469" />
        <meta name="theme-color" content="#00c469" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
      </Helmet>
      <ApolloReconnectConsumer>
        {reconnect => {
          if (reconnect && previousMyself?.operator?.id !== myself?.operator?.id) {
            reconnect()
          }
          return null
        }}
      </ApolloReconnectConsumer>
      <AtomicStateProvider>
        <ComponentsProvider value={{ deviceType }}>
          <Authenticate>
            <ToastContainer />
            <CallHandlingProvider>
              <Router />
            </CallHandlingProvider>
          </Authenticate>
        </ComponentsProvider>
      </AtomicStateProvider>
    </>
  )
}

export default App
