import { Permission } from '#src/generated/types'
import { OperatorFragment } from '#src/app/fragments/Operator.generated'
import { useIdentityContext } from '#src/app/context/Identity'

export function hasPermission(
  operator: OperatorFragment | null | undefined,
  permission: Permission
) {
  return !!operator?.isAdmin || !!operator?.permissions.includes(permission)
}

export function useHasPermission(...permissions: Permission[]): boolean[] {
  const { operator } = useIdentityContext()

  return permissions.map(permission => hasPermission(operator, permission))
}

export function useIsAdmin(): boolean {
  const { operator } = useIdentityContext()
  return !!operator?.isAdmin
}

export { Permission }
