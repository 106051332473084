import React from 'react'
import { Grid, GridItem } from '@deal/components'

interface PageContentProps extends React.HTMLAttributes<HTMLDivElement> {
  gridWrapperClassName?: string
  gridClassName?: string
  margins?: boolean
}

const PageContent: React.FC<React.PropsWithChildren<PageContentProps>> = ({
  gridClassName,
  gridWrapperClassName,
  margins,
  children,
  ...rest
}) => {
  return (
    <Grid wrapperClassName={gridWrapperClassName} className={gridClassName} margins={margins}>
      <GridItem col={2} {...rest}>
        {children}
      </GridItem>
    </Grid>
  )
}

export default PageContent
