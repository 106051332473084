
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AIGuestChatSuggestion": [
      "AIGuestChatActionSuggestion",
      "AIGuestChatMessageSuggestion"
    ],
    "AcceptLeadTransferRequestError": [
      "DuplicateLeadAcceptLeadTransferRequestError",
      "NotValidAcceptLeadTransferRequestError"
    ],
    "AcknowledgePurchaseOrderRevisionError": [
      "AcknowledgePurchaseOrderRevisionAcknowledgeFailedError",
      "AcknowledgePurchaseOrderRevisionFeedbackExistsConflictError",
      "PurchaseOrderRevisionEditNotInAwaitingAcknowledgementStateError"
    ],
    "ActivateGigRequestError": [
      "InvalidStateActivateGigRequestError",
      "NotParentGigRequestError"
    ],
    "ActivatePartnerUserError": [
      "InvalidAccountStatePartnerAccountEditError"
    ],
    "ActivatePromotionError": [
      "DefaultErrorMessage"
    ],
    "AddAddressWithValidationError": [
      "InvalidAddressAddAddressWithValidationError"
    ],
    "AddCommTemplateChannelError": [
      "NotInDraftStateAddCommTemplateChannelError"
    ],
    "AddConsumerProductReviewModerationObservationError": [
      "DefaultErrorMessage"
    ],
    "AddConsumerProductReviewReactionError": [
      "NotActiveAddConsumerProductReviewReactionError",
      "SelfReviewAddConsumerProductReviewReactionError"
    ],
    "AddEmailTemplateDataSourceError": [
      "DefaultErrorMessage"
    ],
    "AddExperimentRevisionTreatmentError": [
      "DefaultErrorMessage"
    ],
    "AddExpertCuratedItemToWishlistError": [
      "AlreadyInWishlistAddExpertCuratedItemToWishlistError",
      "HasCustomizationAddExpertCuratedItemToWishlistError",
      "HasDiscountAddExpertCuratedItemToWishlistError",
      "InArchivedStateAddExpertCuratedItemToWishlistError"
    ],
    "AddGiftCardToWishlistError": [
      "AlreadyHasGiftCardAddGiftCardToWishlistError",
      "InArchivedStateAddGiftCardToWishlistError"
    ],
    "AddGiftCardWishlistItemToOrderError": [
      "AddOwnItemAddGiftCardWishlistItemToOrderError",
      "AlreadyArchivedAddGiftCardWishlistItemToOrderError",
      "InvalidAmountAddGiftCardWishlistItemToOrderError",
      "NotPublicAddGiftCardWishlistItemToOrderError",
      "UnexpectedDetailTypeAddGiftCardWishlistItemToOrderError"
    ],
    "AddGigSubmissionReviewError": [
      "NotInReviewAddGigSubmissionReviewError"
    ],
    "AddGigUserClaimLimitOverrideError": [
      "DefaultErrorMessage"
    ],
    "AddInfluencerCuratedItemToListsError": [
      "DefaultErrorMessage"
    ],
    "AddItemsToOrderError": [
      "AddItemsToOrderNotAvailableError",
      "AddTooManyTrialSellablesToOrderError",
      "MixingTrialAndPurchaseSellablesError"
    ],
    "AddItemsToOrderItem": [
      "AddItemsToOrderBundleSelection",
      "Sellable"
    ],
    "AddMembershipToOrderError": [
      "AlreadyMemberAddMembershipToOrderError",
      "MembershipAlreadyInCartAddMembershipToOrderError",
      "NoMembershipTypeAvailableAddMembershipToOrderError"
    ],
    "AddProductBundleComponentError": [
      "ProductBundleUpdateNotInDraftError"
    ],
    "AddProductBundleToWishlistError": [
      "AlreadyInWishlistAddProductBundleToWishlistError",
      "BundleSelectionNotAvailableAddProductBundleToWishlistError",
      "InArchivedStateAddProductBundleToWishlistError"
    ],
    "AddProductCustomizationFeatureError": [
      "ProductCustomizationUpdateFeatureGroupNotFoundError",
      "ProductCustomizationUpdateNotInDraftError"
    ],
    "AddProductCustomizationFeatureGroupError": [
      "ProductCustomizationUpdateNotInDraftError"
    ],
    "AddSellableExpertiseRuleMappingError": [
      "SellableExpertiseRuleNoRequestAttributeValuesError",
      "SellableExpertiseRuleNoSellableAttributeValuesError",
      "SellableExpertiseRuleNotInDraftError",
      "SellableExpertiseRuleSellableAttributeAddedAlreadyError"
    ],
    "AddSellableToWishlistError": [
      "AlreadyInWishlistAddSellableToWishlistError",
      "InArchivedStateAddSellableToWishlistError"
    ],
    "AddSellableWishlistItemToOrderError": [
      "AddOwnItemAddSellableWishlistItemToOrderError",
      "AlreadyArchivedAddSellableWishlistItemToOrderError",
      "CompletelyPurchasedAddSellableWishlistItemToOrderError",
      "NotAvailableAddSellableWishlistItemToOrderError",
      "NotPublicAddSellableWishlistItemToOrderError",
      "UnexpectedDetailTypeAddSellableWishlistItemToOrderError"
    ],
    "AddTakeImageSellableEditorialReviewRevisionError": [
      "AddTakeImageSellableEditorialReviewRevisionNoTakeFoundError"
    ],
    "AddTemplateDataSourceReferenceError": [
      "DefaultErrorMessage"
    ],
    "AddWarrantyToOrderError": [
      "SellableNotInCartAddWarrantyToOrderError",
      "WarrantyAlreadyInCartAddWarrantyToOrderError"
    ],
    "AddressFields": [
      "Address",
      "PostalAddress"
    ],
    "ApplyRewardCreditPromotionPostPurchaseError": [
      "DefaultErrorMessage"
    ],
    "ApprovableEntityDependencyResolutionError": [
      "NotEligibleStateApprovableEntityDependencyResolutionError",
      "NotFoundApprovableEntityDependencyResolutionError",
      "RecursiveUsageApprovableEntityDependencyResolutionError"
    ],
    "ApprovalRequestActivityEntryDetail": [
      "ApproveApprovalRequestActivityEntryDetail",
      "CloseApprovalRequestActivityEntryDetail",
      "CommentApprovalRequestActivityEntryDetail",
      "PublishApprovalRequestActivityEntryDetail",
      "RejectApprovalRequestActivityEntryDetail",
      "SubmitForReviewApprovalRequestActivityEntryDetail"
    ],
    "ApprovalRequestAuthor": [
      "BusinessUser",
      "Operator",
      "PartnerUser",
      "SystemUser"
    ],
    "ApprovalRequestCreationError": [
      "AlreadySubmittedApprovalRequestCreationError",
      "ApprovableEntityNotReadyApprovalRequestCreationError",
      "ApprovalEntitiesChangedApprovalRequestCreationError",
      "ArchiveFailedApprovalRequestCreationError",
      "DependencyResolutionFailedApprovalRequestCreationError"
    ],
    "ApprovalRequestEntity": [
      "AttributeSchemaApprovalRequestEntity",
      "GenericApprovalRequestEntity",
      "GigSubmissionApprovalRequestEntity",
      "PathSchemaApprovalRequestEntity",
      "PathSchemaFragmentApprovalRequestEntity",
      "TriggerApprovalRequestEntity",
      "VendorAgreementApprovalRequestEntity"
    ],
    "ApprovalRequestPublishError": [
      "ApprovableEntitySwitchToPublishFailedApprovalRequestPublishError",
      "ApproveFailedApprovalRequestPublishError",
      "InvalidStateApprovalRequestPublishError",
      "NotApprovedApprovalRequestPublishError",
      "NotAuthorizedApprovalRequestPublishError"
    ],
    "ApprovalRequestSubmitDraftEditError": [
      "AlreadySubmittedApprovalRequestSubmitDraftEditError",
      "DependencyRequiredApprovalRequestSubmitDraftEditError",
      "NoSuchDependencyApprovalRequestSubmitDraftEditError"
    ],
    "ApproveApprovalRequestError": [
      "DefaultErrorMessage"
    ],
    "ApproveConsumerProductReviewError": [
      "DefaultErrorMessage"
    ],
    "ApproveExpertAccountChangeRequestError": [
      "ExpertAccountChangeRequestNotInSubmittedStateError"
    ],
    "ApproveGigApplicationError": [
      "ClaimRequestApproveGigApplicationError",
      "DraftExistsGigClaimError",
      "InvalidStateApproveGigApplicationError"
    ],
    "ApproveLeadRPALExclusionOverrideRequestError": [
      "ApproveLeadRPALExclusionOverrideRequestAlreadyApprovedError"
    ],
    "ApprovePostPublishGigSubmissionRevisionError": [
      "InvalidStateApprovePostPublishGigSubmissionRevisionError",
      "PostPublishNotEnabledApprovePostPublishGigSubmissionRevisionError"
    ],
    "ArchiveAffiliateCampaignRevisionError": [
      "DefaultErrorMessage",
      "UpdateAffiliateCampaignHasBeenUsedError"
    ],
    "ArchiveAffiliateSourceRevisionError": [
      "DefaultErrorMessage",
      "UpdateAffiliateSourceHasBeenUsedError"
    ],
    "ArchiveArticleRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveCategoryAssetRevisionError": [
      "MustArchiveThroughApprovalArchiveCategoryAssetRevisionError"
    ],
    "ArchiveCategoryChangeRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveCategoryExpertPerformanceSettingRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveCategoryNavigationGroupRevisionError": [
      "HasCategoryNavigationInGroupCategoryNavigationGroupRevisionError",
      "MustArchiveThroughApprovalCategoryNavigationGroupRevisionError"
    ],
    "ArchiveCategoryNavigationRevisionError": [
      "MustArchiveThroughApprovalCategoryNavigationRevisionError"
    ],
    "ArchiveCommTemplateError": [
      "MustArchiveThroughApprovalCommTemplateError"
    ],
    "ArchiveCommTemplateFragmentError": [
      "MustArchiveThroughApprovalCommTemplateFragmentError"
    ],
    "ArchiveContentSelectionRuleRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveConversationStarterSetRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveDraftVendorAgreementRevisionError": [
      "InvalidStateVendorAgreementRevisionError"
    ],
    "ArchiveEmailTemplateRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveExperimentMetricRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveExperimentRevisionError": [
      "ExperimentArchiveInUseError"
    ],
    "ArchiveExpertLeadCampaignError": [
      "ArchiveExpertLeadCampaignAlreadyArchivedError",
      "ArchiveExpertLeadCampaignArchiveEmailFailedError"
    ],
    "ArchiveFedExBillRevisionError": [
      "ArchiveFedExBillRevisionInvalidStateError",
      "ArchiveFedExBillRevisionMustArchiveThroughApprovalError"
    ],
    "ArchiveFedExRateCardRevisionError": [
      "ArchiveFedExRateCardRevisionMustArchiveThroughApprovalError"
    ],
    "ArchiveFedExSurchargeRevisionError": [
      "ArchiveFedExSurchargeRevisionMustArchiveThroughApprovalError"
    ],
    "ArchiveFedExZoneLocatorRevisionError": [
      "ArchiveFedExZoneLocatorRevisionMustArchiveThroughApprovalError"
    ],
    "ArchiveGigRequestError": [
      "ActiveGigSubmissionArchiveGigRequestError",
      "InvalidGigApplicationStateArchiveGigRequestError"
    ],
    "ArchiveGigSubmissionRevisionError": [
      "ApprovalErrorArchiveGigSubmissionRevisionError",
      "ArchiveGigContentErrorArchiveGigSubmissionRevisionError",
      "MustArchiveThroughApprovalArchiveGigSubmissionRevisionError",
      "NotParentGigSubmissionRevisionError"
    ],
    "ArchiveInfluencerCuratedListError": [
      "DefaultErrorMessage"
    ],
    "ArchiveMilestonePayPlanRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchivePageTemplateRevisionError": [
      "ArchivePageTemplateRevisionUsagesPresentError"
    ],
    "ArchivePathSchemaRevisionError": [
      "InvalidStateArchivePathSchemaRevisionError",
      "UsagesPresentArchivePathSchemaRevisionError"
    ],
    "ArchiveProductBundleRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveProductCustomizationRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveProductQuestionError": [
      "DefaultErrorMessage"
    ],
    "ArchivePromotionSelectionRuleRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchivePromptTemplateRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchivePurchaseRequestRevisionError": [
      "ArchivePurchaseRequestRevisionMustArchiveThroughApprovalError",
      "ArchivePurchaseRequestRevisionPublishedCannotBeArchivedError"
    ],
    "ArchiveSearchQueryRewriteRuleError": [
      "DefaultErrorMessage"
    ],
    "ArchiveSellableEditorialReviewRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveSellableExpertOwnerReviewRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveSellableExpertiseRequestError": [
      "DefaultErrorMessage"
    ],
    "ArchiveSellableExpertiseRuleError": [
      "DefaultErrorMessage"
    ],
    "ArchiveSellableSearchRuleRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveSellableSearchSitemapRuleRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveSuggestedActionRevisionError": [
      "ArchiveSuggestedActionSystemActionNotAllowedError"
    ],
    "ArchiveTemplateDataSourceRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveTextLinkifyRuleError": [
      "DefaultErrorMessage"
    ],
    "ArchiveUpsRateCardRevisionError": [
      "ArchiveUpsRateCardRevisionMustArchiveThroughApprovalError"
    ],
    "ArchiveUpsSurchargeRevisionError": [
      "ArchiveUpsSurchargeRevisionMustArchiveThroughApprovalError"
    ],
    "ArchiveUpsZoneLocatorRevisionError": [
      "ArchiveUpsZoneLocatorRevisionMustArchiveThroughApprovalError"
    ],
    "ArchiveVirtualCategoryError": [
      "ArchiveVirtualCategoryChildNodesHaveActiveError"
    ],
    "ArchiveVirtualCategoryTreeRevisionError": [
      "DefaultErrorMessage"
    ],
    "ArchiveWishlistError": [
      "DefaultErrorMessage"
    ],
    "ArchiveWishlistItemError": [
      "InArchivedStateArchiveWishlistItemError"
    ],
    "ArticleAuthor": [
      "BusinessUser",
      "Operator",
      "User"
    ],
    "ArticleContentBlock": [
      "ArticleAttributesContentBlock",
      "ArticleCuratedTakeContentBlock",
      "ArticleDynamicContentBlock",
      "ArticleEmbedMediaContentBlock",
      "ArticleExpertNoteContentBlock",
      "ArticleExpertQAContentBlock",
      "ArticleExpertQuoteContentBlock",
      "ArticleExpertRatingContentBlock",
      "ArticleInlinePathContentBlock",
      "ArticleMediaFileContentBlock",
      "ArticleOEmbedContentBlock",
      "ArticleRichTextContentBlock",
      "ArticleSellableComparisonContentBlock",
      "ArticleSellableContentBlock",
      "ArticleSellableListContentBlock",
      "ArticleSellableReviewContentBlock"
    ],
    "ArticleDynamicableContentBlock": [
      "ArticleInlinePathContentBlock"
    ],
    "AttributeOrError": [
      "Attribute",
      "NoSuchAttributeError"
    ],
    "AttributeSchemaAuthor": [
      "ImportedIdentity",
      "Operator",
      "SystemUser"
    ],
    "AttributeSchemaMetadata": [
      "ConsumerWarningAttributeSchemaMetadata",
      "ExpertNoteAttributeSchemaMetadata",
      "ExpertProductRecommendationFilterAttributeSchemaMetadata",
      "GoogleShoppingFeedAttributeSchemaMetadata",
      "HiddenAttributeSchemaMetadata",
      "ProConAttributeSchemaMetadata",
      "ProductRatingAttributeSchemaMetadata",
      "SearchFacetCappedNumericAttributeSchemaMetadata",
      "SearchFacetDecimalScaleAttributeSchemaMetadata",
      "SearchFacetFixedIntegerBucketAttributeSchemaMetadata",
      "SearchFacetHistogramBucketAttributeSchemaMetadata",
      "SearchFacetNameAliasAttributeSchemaMetadata",
      "SearchFilterFieldAttributeSchemaMetadata"
    ],
    "AttributeSchemaRevisionOrAttributeName": [
      "AttributeName",
      "AttributeSchemaRevision"
    ],
    "AttributeSchemaRevisionPayload": [
      "CopyAttributeSchemaRevisionAsNewVersionPayload",
      "CreateAttributeSchemaRevisionPayload",
      "DeleteDraftAttributeSchemaRevisionPayload",
      "DeprecateAttributeSchemaRevisionPayload",
      "PublishAttributeSchemaRevisionPayload",
      "RemoveDraftEnumEntriesFromAttributeSchemaRevisionPayload",
      "RemoveMetadataFromAttributeSchemaRevisionPayload",
      "RemoveValidatorFromAttributeSchemaRevisionPayload",
      "ReorderEnumEntriesInAttributeSchemaRevisionPayload",
      "UpdateAttributeSchemaRevisionPayload",
      "UpsertEnumEntriesIntoAttributeSchemaRevisionPayload",
      "UpsertMetadataIntoAttributeSchemaRevisionPayload",
      "UpsertValidatorIntoAttributeSchemaRevisionPayload"
    ],
    "AttributeValidatorReference": [
      "CharacterCountAttributeValidatorReference",
      "ContactAvailableAttributeValidatorReference",
      "IdEntityTypeAttributeValidatorReference",
      "InRangeDecimalAttributeValidatorReference",
      "InRangeIntegerAttributeValidatorReference",
      "OAuthTokenNotMappedAttributeValidatorReference",
      "RegexAttributeValidatorReference",
      "SmsCapablePhoneNumberAttributeValidatorReference",
      "ValidEmailAddressAttributeValidatorReference"
    ],
    "AttributeValue": [
      "AttributeValueDate",
      "AttributeValueDateRange",
      "AttributeValueDateTime",
      "AttributeValueDateTimeRange",
      "AttributeValueDecimal",
      "AttributeValueDecimalRange",
      "AttributeValueEnumeration",
      "AttributeValueId",
      "AttributeValueInteger",
      "AttributeValueIntegerRange",
      "AttributeValueText",
      "AttributeValueTime",
      "AttributeValueTimeRange",
      "AttributeValueZoneId"
    ],
    "BlockAddressError": [
      "NotActualAddressBlockAddressError"
    ],
    "BulkSellableRemoveAttributeError": [
      "NotFullyAppliedSellableEditError",
      "VariationDimensionSellableRemoveAttributeError"
    ],
    "BulkSellableRemoveRawAttributeError": [
      "NotFullyAppliedSellableEditError",
      "VariationDimensionSellableRemoveAttributeError"
    ],
    "BulkSetSellableAttributeError": [
      "NotFullyAppliedSellableEditError"
    ],
    "BulkSetSellableRawAttributeError": [
      "NotFullyAppliedSellableEditError"
    ],
    "BulkUpdateContentSelectionRulePromotionCodeError": [
      "InvalidPromotionCodeBulkUpdateContentSelectionRuleError"
    ],
    "BusinessUserActivity": [
      "ExpertActivatedActivity",
      "ExpertDeactivatedActivity",
      "ExpertLeadGateAddedActivity",
      "ExpertLeadGateRemovedActivity",
      "StartOnCallActivity",
      "StartReceivingLeadsActivity",
      "StopOnCallActivity",
      "StopReceivingLeadsActivity"
    ],
    "CalculateFedExRateError": [
      "CalculateFedExRateInvalidZipCodeNumberError",
      "CalculateFedExRateInvalidZoneError",
      "CalculateFedExRateNoZoneFoundError"
    ],
    "CalculateUpsRateError": [
      "CalculateUpsRateInvalidZipCodeNumberError",
      "CalculateUpsRateNoZoneFoundError"
    ],
    "CallParticipant": [
      "BusinessUser",
      "Operator",
      "User"
    ],
    "CampaignAudienceIntegration": [
      "CampaignAudienceFacebookIntegration",
      "CampaignAudienceGoogleIntegration"
    ],
    "CampaignDetail": [
      "CampaignExpertUpdateDetail"
    ],
    "CampaignSettings": [
      "LeadCampaignSettings"
    ],
    "CampaignUnion": [
      "Campaign"
    ],
    "CancelHollingsworthOrderError": [
      "CancelHollingsworthOrderAlreadyCancelledError",
      "CancelHollingsworthOrderAlreadyShippedError",
      "CancelHollingsworthOrderInsufficientQuantityError",
      "CancelHollingsworthOrderInvalidLineItemError",
      "CancelHollingsworthOrderNotFoundError"
    ],
    "CancelLeadRPALExclusionOverrideRequestError": [
      "CancelLeadRPALExclusionOverrideRequestNotSubmittedError"
    ],
    "CancelPurchaseOrderRevisionError": [
      "CancelPurchaseOrderRevisionConfirmedCannotBeArchivedByAdminError",
      "CancelPurchaseOrderRevisionConfirmedCannotBeArchivedError",
      "CancelPurchaseOrderRevisionMustArchiveThroughApprovalError",
      "CancelPurchaseOrderRevisionUnableToCancelError"
    ],
    "CancelScheduledExpertLeadCampaignError": [
      "CancelScheduledExpertLeadCampaignCampaignNotExistError",
      "CancelScheduledExpertLeadCampaignCancelFailedError"
    ],
    "CancelSellableStockAlertError": [
      "AlreadyAlertedCancelSellableStockAlertError"
    ],
    "CancelWarehouseOrderError": [
      "CancelWarehouseOrderAlreadyCancelledError",
      "CancelWarehouseOrderAlreadyShippedError",
      "CancelWarehouseOrderHttpError",
      "CancelWarehouseOrderNotExistWarehouseOrderError",
      "CancelWarehouseOrderNotWare2GoOrderError"
    ],
    "CategoryChangeAction": [
      "CategoryChangeCreateAction",
      "CategoryChangeDeactivateAction",
      "CategoryChangeMoveAction",
      "CategoryChangeUpdateAction"
    ],
    "CategoryNavigationLink": [
      "CategoryNavigationArticleLink",
      "CategoryNavigationCustomWebLink",
      "CategoryNavigationDepartmentLink",
      "CategoryNavigationFacetedCategoryLink",
      "CategoryNavigationFacetedDealsLink"
    ],
    "CategoryPageViewModel": [
      "BrowseCategoryPageViewModel",
      "FacetedCategoryPageViewModel"
    ],
    "ChangeIntoTestedProductError": [
      "ChangeIntoTestedProductAlreadyExistsError",
      "ChangeIntoTestedProductCreateFailedError",
      "ChangeIntoTestedProductInactiveStateError"
    ],
    "ChannelTemplate": [
      "EmailTemplate",
      "FacebookTemplate",
      "InAppTemplate",
      "PushNotificationTemplate",
      "SlackTemplate",
      "SmsTemplate"
    ],
    "CheckExerciseStatusError": [
      "MissingEntityFetcherCheckExerciseStatusError",
      "MissingExerciseEvaluatorCheckExerciseStatusError",
      "NotInProgressCheckExerciseStatusError",
      "WrongEntityTypeCheckExerciseStatusError"
    ],
    "ClaimConsumerReferralRewardCreditError": [
      "ClaimRewardCreditFailureClaimConsumerReferralRewardCreditError",
      "NotEligibleClaimConsumerReferralRewardCreditError"
    ],
    "CloseAccountError": [
      "ExpertCloseAccountError",
      "OperatorCloseAccountError"
    ],
    "CloseApprovalRequestError": [
      "DefaultErrorMessage"
    ],
    "CoOwnerPointsSource": [
      "ByolPurchaseCoOwnerPointsSource",
      "ContentCoOwnerPointsSource",
      "LegacyCoOwnerPointsSource",
      "RepeatPurchaseCoOwnerPointsSource",
      "TransferCoOwnerPointsSource"
    ],
    "CoOwnerQualification": [
      "CoOwnerQualificationCriteriaBased",
      "CoOwnerQualificationManualOverride"
    ],
    "CommentOnApprovalRequestError": [
      "DefaultErrorMessage"
    ],
    "CommonPathEditorPayload": [
      "ExecutePathEditorMoveNodePayload",
      "ExecutePathEditorNodeConfigCommandPayload",
      "ExecutePathEditorPasteNodePayload",
      "ExecutePathEditorRedoPayload",
      "ExecutePathEditorRemoveEdgePayload",
      "ExecutePathEditorRemoveNodePayload",
      "ExecutePathEditorSetEdgePayload",
      "ExecutePathEditorUndoPayload",
      "TogglePathEditorNodeExpansionPayload"
    ],
    "CommonPathSchemaRevision": [
      "PathSchemaFragmentRevision",
      "PathSchemaRevision"
    ],
    "ConditionDefinition": [
      "AlwaysTrueConditionDefinition",
      "LogicalConditionDefinition",
      "StringBinaryConditionDefinition",
      "SubjectIdInConditionDefinition"
    ],
    "ConfirmOrderCancellationError": [
      "InvalidQuantityChangeOrderError",
      "InvalidStateChangeOrderError",
      "ItemProcessedOrShippedChangeOrderError",
      "NotEligibleChangeOrderError",
      "RequestTimedOutChangeOrderError"
    ],
    "ConfirmOrderPriceChangeError": [
      "InvalidStateChangeOrderError",
      "PendingChangeExistsChangeOrderError",
      "RefundAmountExceededChangeOrderError"
    ],
    "ConfirmPurchaseOrderRevisionError": [
      "ConfirmPurchaseOrderRevisionFailedError",
      "PurchaseOrderRevisionEditNotInAwaitingAcknowledgementStateError"
    ],
    "ConsumerProductReviewLMSummaryGenerateError": [
      "EmptyContentConsumerProductReviewLMSummaryGenerateError",
      "EmptyThemeConsumerProductReviewLMSummaryGenerateError",
      "InvalidLLMResultConsumerProductReviewLMSummaryGenerateError",
      "NotEnoughInformationConsumerProductReviewLMSummaryGenerateError",
      "ParseErrorConsumerProductReviewLMSummaryGenerateError",
      "UnableToGenerateConsumerProductReviewLMSummaryGenerateError"
    ],
    "ConsumerProductReviewLMThemeSentimentGenerateError": [
      "EmptyContentConsumerProductReviewLMThemeSentimentGenerateError",
      "NotEnoughInformationConsumerProductReviewLMThemeSentimentGenerateError",
      "ParseErrorConsumerProductReviewLMThemeSentimentGenerateError",
      "UnableToGenerateConsumerProductReviewLMThemeSentimentGenerateError"
    ],
    "ConsumerProductReviewSource": [
      "ConsumerProductReviewSourceCurated",
      "ConsumerProductReviewSourceImported"
    ],
    "ConsumerSearchResult": [
      "ConsumerSearchResultArticle",
      "ConsumerSearchResultCategoryPage",
      "ConsumerSearchResultRedirect",
      "ConsumerSearchResultSellables"
    ],
    "ContentSelectionMethod": [
      "EmptyContentSelectionMethod",
      "ExperimentContentSelectionMethod",
      "HardcodedContentSelectionMethod",
      "HardcodedPathSelectionMethod"
    ],
    "ContentSelectionRuleDetail": [
      "PathSelectionRuleDetail",
      "TemplateSelectionRuleDetail"
    ],
    "ContentSelectionToolServe": [
      "ExperimentContentSelectionToolServe",
      "PageTemplateContentSelectionToolServe"
    ],
    "ContentTemplateSelection": [
      "ExperimentContentTemplateSelection",
      "PageTemplateExperimentSelection",
      "PageTemplateSelection",
      "SingleContentTemplateSelection"
    ],
    "ContentValue": [
      "ContentAsset",
      "ContentBooleanValue",
      "ContentFloatValue",
      "ContentIntValue",
      "ContentObject",
      "ContentStringValue",
      "ContentValueArray"
    ],
    "ConversationStarterSelection": [
      "ExperimentConversationStarterSelection",
      "SingleConversationStarterSelection"
    ],
    "ConversationStarterTemplate": [
      "LLMConversationStarterTemplate",
      "StaticConversationStarterTemplate"
    ],
    "CopyAsDraftExperimentMetricRevisionError": [
      "DefaultErrorMessage"
    ],
    "CopyAsDraftExperimentRevisionError": [
      "ExperimentCopyAsDraftDraftExistsError"
    ],
    "CopyAsDraftMilestonePayPlanRevisionError": [
      "MilestonePayPlanDraftExistsError"
    ],
    "CopyAsDraftProductBundleRevisionError": [
      "ProductBundleDraftExistsError"
    ],
    "CopyAsDraftProductCustomizationRevisionError": [
      "ProductCustomizationDraftExistsError"
    ],
    "CopyAsDraftPromptTemplateRevisionError": [
      "DefaultErrorMessage"
    ],
    "CopyAsDraftPublisherRevisionError": [
      "CopyAsDraftPublisherRevisionDraftExistsError"
    ],
    "CopyAsDraftSearchQueryRewriteRuleError": [
      "DefaultErrorMessage"
    ],
    "CopyAsDraftSellableEditorialReviewRevisionError": [
      "CopyAsDraftSellableEditorialReviewRevisionCreateFailedError",
      "CopyAsDraftSellableEditorialReviewRevisionNotAllowedToCopyFromDraftError"
    ],
    "CopyAsDraftSellableEditorialTopListRevisionError": [
      "DefaultErrorMessage"
    ],
    "CopyAsDraftSellableExpertOwnerReviewRevisionError": [
      "DefaultErrorMessage"
    ],
    "CopyAsDraftSuggestedActionRevisionError": [
      "SuggestedActionDraftExistsError"
    ],
    "CopyAsDraftSuggestedMessageRevisionError": [
      "SuggestedMessageDraftExistsError"
    ],
    "CopyAsDraftTenantRevisionError": [
      "CopyAsDraftTenantRevisionDraftExistsError"
    ],
    "CopyAsNextTemplateDataSourceRevisionError": [
      "DefaultErrorMessage"
    ],
    "CopyBrandProfileRevisionAsNextRevisionError": [
      "DraftExistsCreateNewDraftBrandProfileRevisionError"
    ],
    "CopyCategoryAssetRevisionAsNextRevisionError": [
      "DraftExistsCopyCategoryAssetRevisionAsNextRevisionError"
    ],
    "CopyCategoryExpertPerformanceSettingRevisionAsDraftError": [
      "CategoryExpertPerformanceSettingDraftExistsError"
    ],
    "CopyGigApplicationAsDraftError": [
      "DraftExistsGigClaimError",
      "GigTypeBlacklistedGigClaimError",
      "InactiveGigRequiredCopyGigApplicationAsDraftError",
      "MaxClaimReachedGigClaimError",
      "MaxSubmissionsReachedGigClaimError",
      "NotParentGigRequestError",
      "NotWhitelistedGigClaimError",
      "RejectedGigClaimError",
      "RequestExpiredGigClaimError"
    ],
    "CopyInfluencerCuratedListAsDraftError": [
      "DefaultErrorMessage"
    ],
    "CopyRewardCreditGrantTemplateRevisionAsNextRevisionError": [
      "DraftExistsCopyRewardCreditGrantTemplateRevisionAsNextRevisionError"
    ],
    "CopySchedulingUserAvailabilityToNextPeriodError": [
      "CopySchedulingUserAvailabilityToNextPeriodInvalidTimeRangeError",
      "CopySchedulingUserAvailabilityToNextPeriodNoShiftDemandsError"
    ],
    "CopySellableExpertiseRuleAsDraftError": [
      "SellableExpertiseRuleDraftExistsError"
    ],
    "CopyTriggerIntoTenantError": [
      "AlreadyExistsCopyTriggerIntoTenantError",
      "InvalidTenantCopyTriggerIntoTenantError",
      "NotPublishedCopyTriggerIntoTenantError"
    ],
    "CopyVendorAgreementRevisionError": [
      "DraftExistsCopyVendorAgreementRevisionError",
      "InvalidStateVendorAgreementRevisionError",
      "RevisionExistsCopyVendorAgreementRevisionError"
    ],
    "CostAdjustment": [
      "PurchaseJointBusinessPlan",
      "PurchaseOrderNetDown"
    ],
    "CostAdjustmentLineItem": [
      "PurchaseJointBusinessPlanLineItem",
      "PurchaseOrderNetDownLineItem"
    ],
    "CreateAccountPathNodeFlowViewModelError": [
      "AccountExistsCreateAccountPathNodeFlowViewModelError"
    ],
    "CreateAffiliateAdError": [
      "CreateAffiliateAdAdGroupNotPublishError",
      "CreateAffiliateAdLengthExceedsLimitError"
    ],
    "CreateAffiliateAdGroupError": [
      "CreateAffiliateAdGroupCampaignNotPublishError",
      "CreateAffiliateAdGroupLengthExceedsLimitError"
    ],
    "CreateAffiliateCampaignRevisionError": [
      "CreateAffiliateCampaignRevisionDraftExistsError",
      "CreateAffiliateCampaignRevisionLengthExceedsLimitError",
      "CreateAffiliateCampaignRevisionSourceNotPublishError"
    ],
    "CreateAffiliateError": [
      "CreateAffiliateLengthExceedsLimitError",
      "CreateAffiliateSlugExistsError"
    ],
    "CreateAffiliateSourceRevisionError": [
      "CreateAffiliateSourceRevisionAlreadyExistsError",
      "CreateAffiliateSourceRevisionDraftExistsError"
    ],
    "CreateBrandError": [
      "DefaultErrorMessage"
    ],
    "CreateBusinessUserTeamError": [
      "InvalidSlackChannelBusinessUserTeamEditError",
      "SlackChannelNotFoundBusinessUserTeamEditError"
    ],
    "CreateCategoryAssetRevisionError": [
      "DraftExistsCreateCategoryAssetRevisionError",
      "EmptyContentCreateCategoryAssetRevisionError",
      "ImageListHasEmptyFieldCreateCategoryAssetRevisionError",
      "MarkdownHasEmptyFieldCreateCategoryAssetRevisionError",
      "NounHasEmptyFieldCreateCategoryAssetRevisionError",
      "PlainTextHasEmptyFieldCreateCategoryAssetRevisionError",
      "ValidationErrorCreateCategoryAssetRevisionError"
    ],
    "CreateCategoryAttributeChangeRevisionError": [
      "CreateCategoryAttributeChangeRevisionDraftExistsError",
      "CreateCategoryAttributeChangeRevisionHasOtherUncompletedChangeError"
    ],
    "CreateCategoryChangeRevisionError": [
      "CreateCategoryChangeRevisionDraftExistsError",
      "CreateCategoryChangeRevisionHasOtherUncompletedChangeError"
    ],
    "CreateCategoryExpertPerformanceSettingDraftError": [
      "DefaultErrorMessage"
    ],
    "CreateCategoryMarginOverrideError": [
      "CategoryMarginOverrideExistsError",
      "CategoryTargetMarginInvalidEffectiveTimeError"
    ],
    "CreateCategoryNavigationGroupRevisionError": [
      "DraftExistsCreateCategoryNavigationGroupRevisionError",
      "SlugExistsCreateCategoryNavigationGroupRevisionError"
    ],
    "CreateCategoryNavigationRevisionError": [
      "DraftExistsCreateCategoryNavigationRevisionError"
    ],
    "CreateCategoryNavigationSectionError": [
      "NotInDraftStateCreateCategoryNavigationSectionError"
    ],
    "CreateCategoryNavigationSectionItemError": [
      "NotInDraftStateCreateCategoryNavigationSectionItemError"
    ],
    "CreateCommTemplateError": [
      "DraftExistsCreateCommTemplateError"
    ],
    "CreateCommTemplateFragmentError": [
      "DraftExistsCreateCommTemplateFragmentError"
    ],
    "CreateConsumerProductReviewError": [
      "AlreadyReviewedCreateConsumerProductReviewError",
      "InvalidOrderLineItemTypeCreateConsumerProductReviewError",
      "InvalidPostTokenConsumerProductReviewError",
      "NotPurchasedCreateConsumerProductReviewError",
      "ParentOrderLineItemCreateConsumerProductReviewError"
    ],
    "CreateContentSelectionRuleError": [
      "ContentSelectionRuleInvalidPathError",
      "ContentSelectionRuleInvalidPromotionError",
      "ContentSelectionRuleInvalidRegExError"
    ],
    "CreateContentSelectionRuleRevisionError": [
      "ContentSelectionRevisionValidationError",
      "CreateContentSelectionRevisionDraftExistError",
      "CreateContentSelectionRevisionInvalidPathError"
    ],
    "CreateContentSelectionToolRevisionError": [
      "CreateContentSelectionToolRevisionComplexExperiment",
      "CreateContentSelectionToolRevisionCreateExperimentFailure",
      "CreateContentSelectionToolRevisionDraftExistError",
      "CreateContentSelectionToolRevisionUpdateExperimentFailure"
    ],
    "CreateConversationStarterSetRevisionError": [
      "CreateConversationStarterSetRevisionDraftExistError"
    ],
    "CreateCuratedDistributionCenterError": [
      "CreateCuratedDistributionCenterDuplicatedCodeError"
    ],
    "CreateDeepDiveGigRequestError": [
      "DefaultErrorMessage"
    ],
    "CreateDemoProductRequestError": [
      "CreateDemoProductRequestGenericError"
    ],
    "CreateEmailTemplateRevisionError": [
      "CreateEmailTemplateRevisionDraftExistsError"
    ],
    "CreateExperimentMetricRevisionError": [
      "DefaultErrorMessage"
    ],
    "CreateExperimentRevisionError": [
      "ExperimentCreateExistsError",
      "ExperimentCreateInvalidNameError"
    ],
    "CreateExpertAdvancementRuleError": [
      "InvalidRankRuleExpertAdvancementRuleError"
    ],
    "CreateExpertAffiliateOutreachError": [
      "LeadExistsUnderOtherExpertCreateExpertAffiliateOutreachError",
      "LeadExistsUnderRequestingExpertCreateExpertAffiliateOutreachError",
      "OutreachExistsCreateExpertAffiliateOutreachError",
      "UserExistsCreateExpertAffiliateOutreachError"
    ],
    "CreateExpertApplicationReferralError": [
      "InvalidEmailEditExpertApplicationError",
      "MissingNameEditExpertApplicationError",
      "MissingReferralEditExpertApplicationError"
    ],
    "CreateExpertBulkUpdateTaskError": [
      "DefaultErrorMessage"
    ],
    "CreateExpertLeadCampaignError": [
      "CreateExpertLeadCampaignCreateEmailFailureError"
    ],
    "CreateExpertPersonnelNoteError": [
      "DefaultErrorMessage"
    ],
    "CreateExpertTestedProductError": [
      "CreateExpertTestedProductCreateFailedError",
      "CreateExpertTestedProductSellableDuplicatedError"
    ],
    "CreateExpertWishListProductError": [
      "CreateExpertWishListProductCreateFailedError",
      "CreateExpertWishListProductSellableDuplicatedError"
    ],
    "CreateExternalAdCampaignCategoryRuleError": [
      "AlreadyExistsExternalAdCampaignCategoryRuleError"
    ],
    "CreateFedExBillRevisionError": [
      "CreateFedExBillRevisionDraftExistsError",
      "CreateFedExBillRevisionDuplicatedDistributionCenterCodeError",
      "CreateFedExBillRevisionInvalidStateError",
      "FedExBillRevisionEditInvalidBillingPeriodError"
    ],
    "CreateFedExRateCardRevisionError": [
      "CreateFedExRateCardRevisionDraftExistsError",
      "CreateFedExRateCardRevisionDuplicatedServiceTypeError",
      "FedExRateCardRevisionEditInvalidEffectiveTimeError",
      "FedExRateCardRevisionEditInvalidWeightError"
    ],
    "CreateFedExSurchargeRevisionError": [
      "CreateFedExSurchargeRevisionDraftExistsError",
      "CreateFedExSurchargeRevisionDuplicatedSurchargeTypeError",
      "FedExSurchargeRevisionEditInvalidEffectiveTimeError"
    ],
    "CreateFedExZoneLocatorRevisionError": [
      "CreateFedExZoneLocatorRevisionDraftExistsError",
      "FedExZoneLocatorRevisionEditInvalidEffectiveTimeError",
      "FedExZoneLocatorRevisionEditInvalidZipCodeNumberError",
      "FedExZoneLocatorRevisionEditZipCodeOverlapError"
    ],
    "CreateFeedItemError": [
      "ContentRejectedCreateFeedItemError"
    ],
    "CreateFeedPostActionPathNodeConfigPostType": [
      "CreateFeedPostActionPathNodeConfigProductRecommendationsPostType"
    ],
    "CreateGigApplicationError": [
      "ApplicationNotRequiredCreateGigApplicationError",
      "DraftExistsGigClaimError",
      "GigTypeBlacklistedGigClaimError",
      "MaxClaimReachedGigClaimError",
      "MaxSubmissionsReachedGigClaimError",
      "NotParentGigRequestError",
      "NotQualifiedGigClaimError",
      "NotWhitelistedGigClaimError",
      "RejectedGigClaimError",
      "RequestExpiredGigClaimError"
    ],
    "CreateGigRequestError": [
      "InvalidParentStateGigRequestError",
      "ParentHasParentGigRequestError"
    ],
    "CreateGigSubmissionRevisionError": [
      "AlreadyClaimedGigClaimError",
      "ApplicationRequiredGigClaimError",
      "ClaimedGigTypesOfSameProductError",
      "ClaimedSameProductQuickReviewBeforeError",
      "CreateGigSubmissionRevisionNoAvailableGigRequestError",
      "DraftExistsGigClaimError",
      "GigTypeBlacklistedGigClaimError",
      "GigTypeNotQualifiedGigClaimError",
      "MaxClaimReachedGigClaimError",
      "MaxSubmissionsReachedGigClaimError",
      "NotParentGigRequestError",
      "NotWhitelistedGigClaimError",
      "RejectedGigClaimError",
      "RequestExpiredGigClaimError",
      "WeeklyLimitExceededGigClaimError"
    ],
    "CreateGroupSalesQuoteRequestError": [
      "CreateGroupSalesQuoteRequestEmailAddressInvalidError"
    ],
    "CreateInfluencerProfileError": [
      "DefaultErrorMessage"
    ],
    "CreateInternalOrderError": [
      "AlreadyPublishedCreateInternalOrderError",
      "NoSellableFoundCreateInternalOrderError",
      "SourceNotEligibleCreateInternalOrderError"
    ],
    "CreateInventoryAdjustmentError": [
      "CreateInventoryAdjustmentDuplicatedError",
      "CreateInventoryAdjustmentHollingsworthNotSupportManualCreateError",
      "CreateInventoryAdjustmentInvalidAdjustedAtError",
      "CreateInventoryAdjustmentInvalidAdjustedQuantityError",
      "CreateInventoryAdjustmentInvalidDistributionCenterError",
      "CreateInventoryAdjustmentInvalidReturnQuantityError",
      "CreateInventoryAdjustmentInventoryItemNotExistsError",
      "CreateInventoryAdjustmentNotSupportedReasonTypeError"
    ],
    "CreateLeadRPALExclusionOverrideRequestError": [
      "CreateLeadRPALExclusionOverrideRequestAlreadyExistsError",
      "CreateLeadRPALExclusionOverrideRequestTooManyRejectionsError"
    ],
    "CreateNewDraftBrandProfileRevisionError": [
      "DraftExistsCreateNewDraftBrandProfileRevisionError"
    ],
    "CreateNewDraftInfluencerCuratedListError": [
      "DefaultErrorMessage"
    ],
    "CreateNewDraftRewardCreditGrantTemplateRevisionError": [
      "DraftExistsCreateNewDraftRewardCreditGrantTemplateRevisionError"
    ],
    "CreateNewMilestonePayPlanError": [
      "DefaultErrorMessage"
    ],
    "CreateNewProductBundleError": [
      "DefaultErrorMessage"
    ],
    "CreateNewProductCustomizationError": [
      "DefaultErrorMessage"
    ],
    "CreateNewSellableExpertOwnerReviewRevisionError": [
      "DefaultErrorMessage"
    ],
    "CreateNewSuggestedMessageByExpertError": [
      "DefaultErrorMessage"
    ],
    "CreateOrderCancellationError": [
      "InvalidQuantityChangeOrderError",
      "ItemProcessedOrShippedChangeOrderError",
      "NotEligibleChangeOrderError",
      "PartialBundleCreateOrderCancellationError",
      "UnableToCancelMerchantOrderCreateOrderCancellationError"
    ],
    "CreateOrderPriceChangeError": [
      "NotEligibleChangeOrderError",
      "PendingChangeExistsChangeOrderError",
      "RefundAmountExceededChangeOrderError"
    ],
    "CreateOrderReturnError": [
      "ClearanceItemCreateOrderReturnError",
      "InvalidQuantityChangeOrderError",
      "InvalidStateChangeOrderError",
      "NotEligibleChangeOrderError",
      "NotEligibleForStoreCreditRefundCreateOrderReturnError",
      "PendingChangeExistsChangeOrderError"
    ],
    "CreatePageTemplateRevisionError": [
      "CreateAsPreMadePageTemplateRevisionFailedError",
      "CreateAsPreMadePageTemplateRevisionInvalidStateError",
      "CreatePageTemplateRevisionAlreadyExistError",
      "CreatePageTemplateRevisionDraftExistsError",
      "CreatePageTemplateRevisionMissingRequiredParameterError",
      "CreatePageTemplateRevisionNotFoundPreMadeTemplateWithIdError",
      "CreatePageTemplateRevisionNotPreMadeTemplateIdError",
      "CreatePageTemplateRevisionShouldBePageTemplateTypeError"
    ],
    "CreatePartReplacementRequestError": [
      "InvalidItemCreatePartReplacementRequestError",
      "InvalidTrackingCreatePartReplacementRequestError",
      "NoMerchantCreatePartReplacementRequestError"
    ],
    "CreatePartnerContactInfoError": [
      "DefaultErrorMessage"
    ],
    "CreatePartnerDistributionCenterConfigRevisionError": [
      "CreatePartnerDistributionCenterConfigRevisionDraftExistsError",
      "CreatePartnerDistributionCenterConfigRevisionDuplicatedNameError",
      "CreatePartnerDistributionCenterConfigRevisionPartnerAccountStateNotActiveError"
    ],
    "CreatePartnerOrderInvoiceError": [
      "CreatePartnerOrderInvoiceDuplicatedInvoiceNumberError",
      "CreatePartnerOrderInvoiceEmptyLineItemsError",
      "CreatePartnerOrderInvoiceIncorrectCostShipmentsError",
      "CreatePartnerOrderInvoiceIncorrectInvoicedQuantityLineItemsError",
      "CreatePartnerOrderInvoiceInvalidInvoiceDateError",
      "CreatePartnerOrderInvoiceInvalidShippingCostError",
      "CreatePartnerOrderInvoiceNoCommentForDifferentDropShipFeeError",
      "CreatePartnerOrderInvoiceNoReasonForDifferentUnitCostError",
      "CreatePartnerOrderInvoiceNotAuthorizedError"
    ],
    "CreatePartnerUserError": [
      "AlreadyExistsCreatePartnerUserError",
      "InvalidAccountStatePartnerAccountEditError",
      "UserCreationFailedCreatePartnerUserError",
      "UserEmailNotFoundCreatePartnerUserError"
    ],
    "CreatePathGraphFlowError": [
      "EphemeralPathNotSupportedCreatePathGraphFlowError",
      "IncompatibleLayoutTypeCreatePathGraphFlowError",
      "MustUseEphemeralPathCreatePathGraphFlowError",
      "NoSuchPathSchemaCreatePathGraphFlowError"
    ],
    "CreatePathSchemaRevisionError": [
      "DraftExistsCreatePathSchemaRevisionError",
      "MalformedSlugCreatePathSchemaRevisionError",
      "RevisionExistsCreatePathSchemaRevisionError"
    ],
    "CreatePayableEntryError": [
      "CreatePayableEntryInvalidTimeError"
    ],
    "CreateProductCatalogPricingScheduleByQueryError": [
      "DefaultErrorMessage"
    ],
    "CreateProductComparisonVideoError": [
      "ExceedsMaximumLimitProductComparisonVideoError",
      "ExpertNotAvailableProductComparisonVideoError",
      "FirstSellableNotAvailableProductComparisonVideoError",
      "IncorrectVideoUrlProductComparisonVideoError",
      "MediaUrlEmptyProductComparisonVideoError",
      "NoImagesAvailableForFirstSellableProductComparisonVideoError",
      "NoImagesAvailableForSecondSellableProductComparisonVideoError",
      "SameSellableProductComparisonVideoError",
      "SaveMediaFailedProductComparisonVideoError",
      "SecondSellableNotAvailableProductComparisonVideoError",
      "VideoUrlDuplicatedProductComparisonVideoError",
      "VideoUrlIsNotSharedProductComparisonVideoError"
    ],
    "CreatePromotionSelectionRuleRevisionError": [
      "CreatePromotionSelectionRuleDraftExistsError",
      "CreatePromotionSelectionRuleInvalidPromotionError"
    ],
    "CreatePublisherRevisionError": [
      "CreatePublisherRevisionDraftExistsError"
    ],
    "CreatePurchaseJointBusinessPlanError": [
      "PurchaseJointBusinessPlanEditDuplicatedNameError",
      "PurchaseJointBusinessPlanEditInvalidTimeRangeError"
    ],
    "CreatePurchaseOrderError": [
      "CreatePurchaseOrderInvalidPurchaseRequestLineItemError",
      "CreatePurchaseOrderNotPublishedPurchaseRequestError",
      "CreatePurchaseOrderPurchaseRequestLineItemAlreadyCreatedError",
      "CreatePurchaseOrderRevisionTitleTooLong"
    ],
    "CreatePurchaseOrderInvoiceError": [
      "CreatePurchaseOrderInvoiceAlreadyExistedError",
      "CreatePurchaseOrderInvoiceEmptyPurchaseOrderReceiptLineItemError",
      "CreatePurchaseOrderInvoiceInvalidReceiptStateError",
      "PurchaseOrderInvoiceEditDuplicatedPurchaseOrderReceiptLineItemsError",
      "PurchaseOrderInvoiceEditInvalidActualFreightCostError",
      "PurchaseOrderInvoiceEditInvalidPurchaseOrderReceiptLineItemError"
    ],
    "CreatePurchaseOrderNetDownError": [
      "PurchaseOrderNetDownEditDuplicatedNameError",
      "PurchaseOrderNetDownEditInvalidRebateReceivedDateError",
      "PurchaseOrderNetDownNotSupportedVendorError"
    ],
    "CreatePurchaseOrderReceiptError": [
      "CreatePurchaseOrderReceiptAlreadyExistedError",
      "CreatePurchaseOrderReceiptEmptyPurchaseOrderShipmentLineItemError",
      "CreatePurchaseOrderReceiptGenericError",
      "CreatePurchaseOrderReceiptInvalidShipmentRevisionStateError",
      "CreatePurchaseOrderReceiptNoActualShipAtProvidedError",
      "PurchaseOrderReceiptEditDuplicatedPurchaseOrderShipmentLineItemsError",
      "PurchaseOrderReceiptEditInvalidPurchaseOrderShipmentLineItemError",
      "PurchaseOrderReceiptsEditInvalidActualShipmentArriveAtError"
    ],
    "CreatePurchaseOrderRevisionError": [
      "CreatePurchaseOrderRevisionCannotCopyAsNextVersionError",
      "CreatePurchaseOrderRevisionDraftExistsError",
      "CreatePurchaseOrderRevisionPartnerAccountStateNotActiveError",
      "CreatePurchaseOrderRevisionPublishedExistsError",
      "PurchaseOrderRevisionEditInvalidRequestShipAtError"
    ],
    "CreatePurchaseOrderShipmentRevisionError": [
      "CreatePurchaseOrderShipmentRevisionASNNumberAlreadyBeUsedError",
      "CreatePurchaseOrderShipmentRevisionAlreadyCancelledError",
      "CreatePurchaseOrderShipmentRevisionAlreadyInReviewError",
      "CreatePurchaseOrderShipmentRevisionAlreadyReceivedError",
      "CreatePurchaseOrderShipmentRevisionDraftExistsError",
      "CreatePurchaseOrderShipmentRevisionDuplicateProductCatalogIdError",
      "CreatePurchaseOrderShipmentRevisionDuplicateProductCatalogIdForPORevisionError",
      "CreatePurchaseOrderShipmentRevisionEmptyPurchaseOrderLineItemError",
      "CreatePurchaseOrderShipmentRevisionEmptyPurchaseOrderRevisionLineItemError",
      "CreatePurchaseOrderShipmentRevisionExistingDraftShipmentUnderPORevisionError",
      "CreatePurchaseOrderShipmentRevisionGenericError",
      "CreatePurchaseOrderShipmentRevisionInvalidActualShipAtError",
      "CreatePurchaseOrderShipmentRevisionInvalidPurchaseOrderLineItemError",
      "CreatePurchaseOrderShipmentRevisionInvalidPurchaseOrderRevisionLineItemError",
      "CreatePurchaseOrderShipmentRevisionNotConfirmedPurchaseOrderError",
      "CreatePurchaseOrderShipmentRevisionNotConfirmedPurchaseOrderRevisionError",
      "CreatePurchaseOrderShipmentRevisionNotSameDCError",
      "CreatePurchaseOrderShipmentRevisionPurchaseOrderLineItemOverQuantityError",
      "CreatePurchaseOrderShipmentRevisionPurchaseOrderRevisionLineItemOverQuantityError"
    ],
    "CreatePurchasePromotionCreditError": [
      "PurchasePromotionCreditEditDuplicatedNameError",
      "PurchasePromotionCreditEditInvalidPromoAmountError"
    ],
    "CreatePurchaseRequestRevisionError": [
      "CreatePurchaseRequestRevisionDraftExistsError",
      "CreatePurchaseRequestRevisionPartnerAccountStateNotActiveError",
      "CreatePurchaseRequestRevisionPublishedExistsError"
    ],
    "CreateSearchQueryRewriteRuleError": [
      "DefaultErrorMessage"
    ],
    "CreateSellableEditorialReviewRevisionError": [
      "CreateSellableEditorialReviewRevisionDraftExistsError",
      "CreateSellableEditorialReviewRevisionGenericError",
      "CreateSellableEditorialReviewRevisionSameSellableAlreadyExistError"
    ],
    "CreateSellableEditorialTopListGigError": [
      "DefaultErrorMessage"
    ],
    "CreateSellableEditorialTopListRevisionError": [
      "DefaultErrorMessage"
    ],
    "CreateSellableExpertiseRuleError": [
      "DefaultErrorMessage"
    ],
    "CreateSellableSearchPageRuleRevisionError": [
      "CreateSellableSearchPageRuleExistNoArchivedStateError",
      "CreateSellableSearchPageRuleFacetAllowAndBlockListedError",
      "CreateSellableSearchPageRuleRevisionDraftExistsError"
    ],
    "CreateSellableSearchPageSitemapRuleRevisionError": [
      "CreateSellableSearchPageSitemapRuleAlreadyExistsError",
      "CreateSellableSearchPageSitemapRuleDraftExistsError",
      "CreateSellableSearchPageSitemapRuleExistNoArchivedStateError"
    ],
    "CreateTemplateDataSourceRevisionError": [
      "DefaultErrorMessage"
    ],
    "CreateTenantRevisionError": [
      "CreateTenantRevisionDraftExistsError"
    ],
    "CreateTextLinkifyRuleError": [
      "CreateTextLinkifyRuleTargetExistsError",
      "EditTextLinkifyRuleNoKeywordError"
    ],
    "CreateTradeInRequestError": [
      "InvalidMerchantCreateTradeInRequestError",
      "InvalidValueCreateTradeInRequestError"
    ],
    "CreateTriggerError": [
      "DraftExistsCreateTriggerError"
    ],
    "CreateUpsRateCardRevisionError": [
      "CreateUpsRateCardRevisionDraftExistsError",
      "CreateUpsRateCardRevisionDuplicatedServiceTypeError",
      "UpsRateCardRevisionEditInvalidEffectiveTimeError",
      "UpsRateCardRevisionEditInvalidWeightError"
    ],
    "CreateUpsSurchargeRevisionError": [
      "CreateUpsSurchargeRevisionDraftExistsError",
      "CreateUpsSurchargeRevisionDuplicatedSurchargeTypeError",
      "UpsSurchargeRevisionEditInvalidEffectiveTimeError"
    ],
    "CreateUpsZoneLocatorRevisionError": [
      "CreateUpsZoneLocatorRevisionDraftExistsError",
      "UpsZoneLocatorRevisionEditInvalidEffectiveTimeError",
      "UpsZoneLocatorRevisionEditInvalidZipCodeNumberError",
      "UpsZoneLocatorRevisionEditZipCodeOverlapError"
    ],
    "CreateVendorAgreementRevisionError": [
      "AlreadyCreatedCreateVendorAgreementRevisionError"
    ],
    "CreateVirtualCategoryError": [
      "CreateVirtualCategoryAlreadyExistError",
      "CreateVirtualCategoryDuplicatePluralDisplayNameError",
      "CreateVirtualCategoryDuplicateSingularDisplayNameError",
      "CreateVirtualCategoryDuplicateSlugError",
      "CreateVirtualCategoryParentNodeNotFoundError"
    ],
    "CreateVirtualCategoryTreeRevisionError": [
      "CreateVirtualCategoryTreeRevisionDraftExistsError"
    ],
    "CreateWarrantyClaimRequestError": [
      "InvalidItemCreateWarrantyClaimRequestError",
      "MissingResolutionCreateWarrantyClaimRequestError"
    ],
    "CreateWishlistError": [
      "DefaultErrorMessage"
    ],
    "DealCreatedByUnion": [
      "Merchant",
      "Operator"
    ],
    "DealRequestSourceFlow": [
      "PathDealRequestSourceFlow"
    ],
    "DealTerms": [
      "EmptyDealTerms",
      "ProductDealTerms",
      "ServiceDealTerms"
    ],
    "DeferExpertApplicationError": [
      "NotApprovedDeferExpertApplicationError"
    ],
    "DeleteCategoryMarginOverrideError": [
      "CategoryTargetMarginExpiredError"
    ],
    "DemoProductRequestAlert": [
      "DemoProductRequestLateShipmentAlert"
    ],
    "DemoRequestContext": [
      "GigSubmissionRevision"
    ],
    "DepartmentNavigationLink": [
      "DepartmentNavigationCategoryLink",
      "DepartmentNavigationCreatePathLink",
      "DepartmentNavigationDealsLink",
      "DepartmentNavigationDepartmentLink",
      "DepartmentNavigationExpertCommunityLink",
      "DepartmentNavigationSearchLink"
    ],
    "DepartmentNavigationResponsiveMixin": [
      "DepartmentNavigationExpertSubSection",
      "DepartmentNavigationListSubSection",
      "DepartmentNavigationTopLevelItem"
    ],
    "DepartmentNavigationSubSection": [
      "DepartmentNavigationExpertSubSection",
      "DepartmentNavigationListSubSection"
    ],
    "DeriveArticleError": [
      "DefaultErrorMessage"
    ],
    "DerivedSellableShortSupplyAlertReason": [
      "DerivedSellableShortSupplyAlertReasonCart",
      "DerivedSellableShortSupplyAlertReasonCuration",
      "DerivedSellableShortSupplyAlertReasonSeen"
    ],
    "DiscountCashTransferError": [
      "LedgerTransactionInsufficientBalanceError",
      "LedgerTransactionTransferInvalidTransferAmountError",
      "LedgerTransactionTransferSelfTransferError"
    ],
    "DismissAllOrderUserMessagesError": [
      "DefaultErrorMessage"
    ],
    "DismissOrderUserMessagesError": [
      "DefaultErrorMessage"
    ],
    "DisplayFacet": [
      "BucketedDisplayFacet",
      "BucketedRangeDisplayFacet",
      "NumericRangeDisplayFacet"
    ],
    "DuplicateProductCustomizationError": [
      "DefaultErrorMessage"
    ],
    "EdiPurchaseOrderIntegrationActionDetail": [
      "EdiPurchaseOrderIntegrationActionDetailConfirmReceive",
      "EdiPurchaseOrderIntegrationActionDetailConfirmSend",
      "EdiPurchaseOrderIntegrationActionDetailInvoiceNoticeReceive",
      "EdiPurchaseOrderIntegrationActionDetailShipmentNoticeReceive"
    ],
    "EditorialContentSource": [
      "EditorialContentGigSubmissionSource",
      "EditorialContentManualSource"
    ],
    "EditorialReviewRating": [
      "EditorialReviewRatingFiveStar"
    ],
    "EmailBodyBlock": [
      "EmailContentBlock",
      "EmailModuleBlock"
    ],
    "EmailColumnContent": [
      "EmailButtonContent",
      "EmailDividerContent",
      "EmailHtmlContent",
      "EmailImageContent",
      "EmailSpacerContent",
      "EmailTextContent"
    ],
    "EmbedMediaDetail": [
      "YouTubeVideoEmbedMediaDetail"
    ],
    "EmburseCardCreationError": [
      "UnableToCreateFulfillmentEmburseCard"
    ],
    "EncodedAuthenticationTokensPayload": [
      "AssumeBusinessUserIdentityPayload",
      "AssumeConsumerIdentityPayload",
      "AssumeOperatorIdentityPayload",
      "AssumePartnerUserIdentityPayload",
      "AuthenticateByExternalOAuthTokenPayload",
      "AuthenticateByLoginTokenPayload",
      "AuthenticationResultPayload",
      "ChangePasswordPayload",
      "CreatePasswordPayload",
      "CreatePathGraphFlowPayload",
      "RefreshAccessTokenPayload",
      "ReleaseBusinessUserIdentityPayload",
      "ReleaseConsumerIdentityPayload",
      "ReleaseOperatorIdentityPayload",
      "ReleasePartnerUserIdentityPayload",
      "ResetPasswordPayload",
      "StartAiGuestConversationPayload",
      "StartExpertGuestConversationPayload",
      "SubmitPathNodeFlowInputCommandsPayload",
      "VisitPathGraphFlowPayload"
    ],
    "ErrorMessage": [
      "AccountExistsCreateAccountPathNodeFlowViewModelError",
      "AcknowledgePurchaseOrderRevisionAcknowledgeFailedError",
      "AcknowledgePurchaseOrderRevisionFeedbackExistsConflictError",
      "ActiveAuthorNotInWhitelistUpdateGigRequestError",
      "ActiveClassesExpertHiringCohortArchiveError",
      "ActiveGigSubmissionArchiveGigRequestError",
      "AddItemsToOrderNotAvailableError",
      "AddJiraTicketAttachmentError",
      "AddJiraTicketCommentError",
      "AddOwnItemAddGiftCardWishlistItemToOrderError",
      "AddOwnItemAddSellableWishlistItemToOrderError",
      "AddTakeImageSellableEditorialReviewRevisionNoTakeFoundError",
      "AddTooManyTrialSellablesToOrderError",
      "AfterStartDateExpertHiringClassAddApplicationError",
      "AlreadyActiveExpertHiringClassUpdateError",
      "AlreadyAddedExpertHiringClassAddApplicationError",
      "AlreadyAlertedCancelSellableStockAlertError",
      "AlreadyArchivedAddGiftCardWishlistItemToOrderError",
      "AlreadyArchivedAddSellableWishlistItemToOrderError",
      "AlreadyArchivedExpertHiringClassArchiveError",
      "AlreadyArchivedUserNeedArchiveError",
      "AlreadyArchivedUserProfileArchiveError",
      "AlreadyClaimedGigClaimError",
      "AlreadyClaimedRewardCreditClaimError",
      "AlreadyCompletedNavigatePathGraphFlowError",
      "AlreadyCreatedCreateVendorAgreementRevisionError",
      "AlreadyCreatedProductCatalogDraftFromSellableError",
      "AlreadyCreatedProductCatalogGroupDraftFromSellableError",
      "AlreadyExistsCopyTriggerIntoTenantError",
      "AlreadyExistsCreatePartnerUserError",
      "AlreadyExistsExternalAdCampaignCategoryRuleError",
      "AlreadyExistsInitiateLeadTransferRequestError",
      "AlreadyExistsSetSellableStockAlertError",
      "AlreadyHasGiftCardAddGiftCardToWishlistError",
      "AlreadyInProgressMerchantReturnError",
      "AlreadyInProgressPartnerOrderReturnError",
      "AlreadyInProgressUpdateOrderReturnError",
      "AlreadyInStockSetSellableStockAlertError",
      "AlreadyInWishlistAddExpertCuratedItemToWishlistError",
      "AlreadyInWishlistAddProductBundleToWishlistError",
      "AlreadyInWishlistAddSellableToWishlistError",
      "AlreadyInactiveExpertHiringCohortArchiveError",
      "AlreadyMemberAddMembershipToOrderError",
      "AlreadyPaidPayGigError",
      "AlreadyPaidPublishAndPayGigError",
      "AlreadyPublishedCreateInternalOrderError",
      "AlreadyRefundedPartnerOrderReturnError",
      "AlreadyRefundedRefundOrderReturnError",
      "AlreadyRegisteredRegisterCustomerError",
      "AlreadyReviewedCreateConsumerProductReviewError",
      "AlreadyStartedStartExerciseTrackingError",
      "AlreadySubmittedApprovalRequestCreationError",
      "AlreadySubmittedApprovalRequestSubmitDraftEditError",
      "AlreadyWithdrawnWithdrawExpertApplicationError",
      "ApplicationNotRequiredCreateGigApplicationError",
      "ApplicationRequiredGigClaimError",
      "ApprovableEntityNotReadyApprovalRequestCreationError",
      "ApprovableEntitySwitchToPublishFailedApprovalRequestPublishError",
      "ApprovableEntityValidationError",
      "ApprovalEntitiesChangedApprovalRequestCreationError",
      "ApprovalErrorArchiveGigSubmissionRevisionError",
      "ApproveApprovalRequestPublishAndPayGigError",
      "ApproveErrorUnclaimGigSubmissionRevisionError",
      "ApproveFailedApprovalRequestPublishError",
      "ApproveLeadRPALExclusionOverrideRequestAlreadyApprovedError",
      "ArchiveErrorRejectGigSubmissionRevisionError",
      "ArchiveErrorUnclaimGigSubmissionRevisionError",
      "ArchiveExpertLeadCampaignAlreadyArchivedError",
      "ArchiveExpertLeadCampaignArchiveEmailFailedError",
      "ArchiveFailedApprovalRequestCreationError",
      "ArchiveFedExBillRevisionInvalidStateError",
      "ArchiveFedExBillRevisionMustArchiveThroughApprovalError",
      "ArchiveFedExRateCardRevisionMustArchiveThroughApprovalError",
      "ArchiveFedExSurchargeRevisionMustArchiveThroughApprovalError",
      "ArchiveFedExZoneLocatorRevisionMustArchiveThroughApprovalError",
      "ArchiveGigContentErrorArchiveGigSubmissionRevisionError",
      "ArchivePageTemplateRevisionUsagesPresentError",
      "ArchivePurchaseRequestRevisionMustArchiveThroughApprovalError",
      "ArchivePurchaseRequestRevisionPublishedCannotBeArchivedError",
      "ArchiveSuggestedActionSystemActionNotAllowedError",
      "ArchiveUpsRateCardRevisionMustArchiveThroughApprovalError",
      "ArchiveUpsSurchargeRevisionMustArchiveThroughApprovalError",
      "ArchiveUpsZoneLocatorRevisionMustArchiveThroughApprovalError",
      "ArchiveVirtualCategoryChildNodesHaveActiveError",
      "ArchivedExpertHiringClassCloseError",
      "ArchivedExpertHiringClassOpenError",
      "ArchivedExpertHiringClassUpdateError",
      "ArchivedProductCatalogGroupPublishError",
      "ArchivedProductCatalogPublishError",
      "ArchivedUserNeedCopyError",
      "ArchivedUserNeedEditNoteError",
      "AttributeEnumEntryDoesNotExistExpertCuratedItemProsAndConsEditError",
      "AttributeValidationSubmitEditCategoryActionError",
      "AutoApprovalNotSupportedPublishAndPayGigError",
      "BacktrackingNotAllowedNavigatePathGraphFlowError",
      "BundleSelectionNotAvailableAddProductBundleToWishlistError",
      "CalculateFedExRateInvalidZipCodeNumberError",
      "CalculateFedExRateInvalidZoneError",
      "CalculateFedExRateNoZoneFoundError",
      "CalculateUpsRateInvalidZipCodeNumberError",
      "CalculateUpsRateNoZoneFoundError",
      "CancelHollingsworthOrderAlreadyCancelledError",
      "CancelHollingsworthOrderAlreadyShippedError",
      "CancelHollingsworthOrderInsufficientQuantityError",
      "CancelHollingsworthOrderInvalidLineItemError",
      "CancelHollingsworthOrderNotFoundError",
      "CancelLeadRPALExclusionOverrideRequestNotSubmittedError",
      "CancelPurchaseOrderRevisionConfirmedCannotBeArchivedByAdminError",
      "CancelPurchaseOrderRevisionConfirmedCannotBeArchivedError",
      "CancelPurchaseOrderRevisionMustArchiveThroughApprovalError",
      "CancelPurchaseOrderRevisionUnableToCancelError",
      "CancelScheduledExpertLeadCampaignCampaignNotExistError",
      "CancelScheduledExpertLeadCampaignCancelFailedError",
      "CancelWarehouseOrderAlreadyCancelledError",
      "CancelWarehouseOrderAlreadyShippedError",
      "CancelWarehouseOrderHttpError",
      "CancelWarehouseOrderNotExistWarehouseOrderError",
      "CancelWarehouseOrderNotWare2GoOrderError",
      "CatalogNotSupportedPublishGigContentError",
      "CategoryChangeNotInDraftStateSubmitEditCategoryActionError",
      "CategoryExpertPerformanceSettingDraftExistsError",
      "CategoryExpertPerformanceSettingPublishNotReadyError",
      "CategoryExpertPerformanceSettingUpdateNotInDraftError",
      "CategoryInUseSubmitDeactivateCategoryActionError",
      "CategoryMarginOverrideExistsError",
      "CategoryNotAllowedExpertHiringClassAddApplicationError",
      "CategoryTargetMarginExpiredError",
      "CategoryTargetMarginInvalidEffectiveTimeError",
      "ChangeIntoTestedProductAlreadyExistsError",
      "ChangeIntoTestedProductCreateFailedError",
      "ChangeIntoTestedProductInactiveStateError",
      "ChangingProductIdentifierProductCatalogPublishError",
      "ChargeFailureGiftTipStoreCreditError",
      "ClaimRequestApproveGigApplicationError",
      "ClaimRequestSubmitGigApplicationError",
      "ClaimRewardCreditFailureClaimConsumerReferralRewardCreditError",
      "ClaimedGigTypesOfSameProductError",
      "ClaimedSameProductQuickReviewBeforeError",
      "ClearanceItemCreateOrderReturnError",
      "CompletelyPurchasedAddSellableWishlistItemToOrderError",
      "ConfirmPurchaseOrderRevisionFailedError",
      "ConflictingProductIdentifierProductCatalogPublishError",
      "ConflictingSkuProductCatalogGroupPublishError",
      "ConnectPartnerTransactionCreationErrorAlreadyExists",
      "ConnectPartnerTransactionCreationErrorConsumerMismatch",
      "ConnectPartnerTransactionCreationErrorNoConsumer",
      "ConnectPartnerTransactionCreationErrorNoLead",
      "ConnectPartnerTransactionCreationErrorUnknownBrand",
      "ContentRejectedCreateFeedItemError",
      "ContentRejectedUpdateFeedContentBlocksError",
      "ContentSelectionRevisionValidationError",
      "ContentSelectionRuleInvalidPathError",
      "ContentSelectionRuleInvalidPromotionError",
      "ContentSelectionRuleInvalidRegExError",
      "CopyAsDraftPublisherRevisionDraftExistsError",
      "CopyAsDraftSellableEditorialReviewRevisionCreateFailedError",
      "CopyAsDraftSellableEditorialReviewRevisionNotAllowedToCopyFromDraftError",
      "CopyAsDraftTenantRevisionDraftExistsError",
      "CopyFromDraftNotAllowedProductCatalogCopyRevisionAsDraftError",
      "CopyFromDraftNotAllowedProductCatalogGroupCopyRevisionAsDraftError",
      "CopyProductCatalogExportConfigDraftExistsError",
      "CopyProductCatalogTemplateDraftExistsError",
      "CopySchedulingUserAvailabilityToNextPeriodInvalidTimeRangeError",
      "CopySchedulingUserAvailabilityToNextPeriodNoShiftDemandsError",
      "CreateAffiliateAdAdGroupNotPublishError",
      "CreateAffiliateAdGroupCampaignNotPublishError",
      "CreateAffiliateAdGroupLengthExceedsLimitError",
      "CreateAffiliateAdLengthExceedsLimitError",
      "CreateAffiliateCampaignRevisionDraftExistsError",
      "CreateAffiliateCampaignRevisionLengthExceedsLimitError",
      "CreateAffiliateCampaignRevisionSourceNotPublishError",
      "CreateAffiliateLengthExceedsLimitError",
      "CreateAffiliateSlugExistsError",
      "CreateAffiliateSourceRevisionAlreadyExistsError",
      "CreateAffiliateSourceRevisionDraftExistsError",
      "CreateAsPreMadePageTemplateRevisionFailedError",
      "CreateAsPreMadePageTemplateRevisionInvalidStateError",
      "CreateCategoryAttributeChangeRevisionDraftExistsError",
      "CreateCategoryAttributeChangeRevisionHasOtherUncompletedChangeError",
      "CreateCategoryChangeRevisionDraftExistsError",
      "CreateCategoryChangeRevisionHasOtherUncompletedChangeError",
      "CreateContentSelectionRevisionDraftExistError",
      "CreateContentSelectionRevisionInvalidPathError",
      "CreateContentSelectionToolRevisionComplexExperiment",
      "CreateContentSelectionToolRevisionCreateExperimentFailure",
      "CreateContentSelectionToolRevisionDraftExistError",
      "CreateContentSelectionToolRevisionUpdateExperimentFailure",
      "CreateConversationStarterSetRevisionDraftExistError",
      "CreateCuratedDistributionCenterDuplicatedCodeError",
      "CreateDemoProductRequestGenericError",
      "CreateEmailTemplateRevisionDraftExistsError",
      "CreateExpertLeadCampaignCreateEmailFailureError",
      "CreateExpertTestedProductCreateFailedError",
      "CreateExpertTestedProductSellableDuplicatedError",
      "CreateExpertWishListProductCreateFailedError",
      "CreateExpertWishListProductSellableDuplicatedError",
      "CreateFedExBillRevisionDraftExistsError",
      "CreateFedExBillRevisionDuplicatedDistributionCenterCodeError",
      "CreateFedExBillRevisionInvalidStateError",
      "CreateFedExRateCardRevisionDraftExistsError",
      "CreateFedExRateCardRevisionDuplicatedServiceTypeError",
      "CreateFedExSurchargeRevisionDraftExistsError",
      "CreateFedExSurchargeRevisionDuplicatedSurchargeTypeError",
      "CreateFedExZoneLocatorRevisionDraftExistsError",
      "CreateGigSubmissionRevisionNoAvailableGigRequestError",
      "CreateGroupSalesQuoteRequestEmailAddressInvalidError",
      "CreateInventoryAdjustmentDuplicatedError",
      "CreateInventoryAdjustmentHollingsworthNotSupportManualCreateError",
      "CreateInventoryAdjustmentInvalidAdjustedAtError",
      "CreateInventoryAdjustmentInvalidAdjustedQuantityError",
      "CreateInventoryAdjustmentInvalidDistributionCenterError",
      "CreateInventoryAdjustmentInvalidReturnQuantityError",
      "CreateInventoryAdjustmentInventoryItemNotExistsError",
      "CreateInventoryAdjustmentNotSupportedReasonTypeError",
      "CreateJiraTicketError",
      "CreateLeadRPALExclusionOverrideRequestAlreadyExistsError",
      "CreateLeadRPALExclusionOverrideRequestTooManyRejectionsError",
      "CreatePageTemplateRevisionAlreadyExistError",
      "CreatePageTemplateRevisionDraftExistsError",
      "CreatePageTemplateRevisionMissingRequiredParameterError",
      "CreatePageTemplateRevisionNotFoundPreMadeTemplateWithIdError",
      "CreatePageTemplateRevisionNotPreMadeTemplateIdError",
      "CreatePageTemplateRevisionShouldBePageTemplateTypeError",
      "CreatePartnerDistributionCenterConfigRevisionDraftExistsError",
      "CreatePartnerDistributionCenterConfigRevisionDuplicatedNameError",
      "CreatePartnerDistributionCenterConfigRevisionPartnerAccountStateNotActiveError",
      "CreatePartnerOrderInvoiceDuplicatedInvoiceNumberError",
      "CreatePartnerOrderInvoiceEmptyLineItemsError",
      "CreatePartnerOrderInvoiceIncorrectCostShipmentsError",
      "CreatePartnerOrderInvoiceIncorrectInvoicedQuantityLineItemsError",
      "CreatePartnerOrderInvoiceInvalidInvoiceDateError",
      "CreatePartnerOrderInvoiceInvalidShippingCostError",
      "CreatePartnerOrderInvoiceNoCommentForDifferentDropShipFeeError",
      "CreatePartnerOrderInvoiceNoReasonForDifferentUnitCostError",
      "CreatePartnerOrderInvoiceNotAuthorizedError",
      "CreatePayableEntryInvalidTimeError",
      "CreatePracticeLeadStartLessonError",
      "CreateProductCatalogExportConfigDraftExistsError",
      "CreateProductCatalogTemplateDraftExistsError",
      "CreatePromotionSelectionRuleDraftExistsError",
      "CreatePromotionSelectionRuleInvalidPromotionError",
      "CreatePublisherRevisionDraftExistsError",
      "CreatePurchaseOrderInvalidPurchaseRequestLineItemError",
      "CreatePurchaseOrderInvoiceAlreadyExistedError",
      "CreatePurchaseOrderInvoiceEmptyPurchaseOrderReceiptLineItemError",
      "CreatePurchaseOrderInvoiceInvalidReceiptStateError",
      "CreatePurchaseOrderNotPublishedPurchaseRequestError",
      "CreatePurchaseOrderPurchaseRequestLineItemAlreadyCreatedError",
      "CreatePurchaseOrderReceiptAlreadyExistedError",
      "CreatePurchaseOrderReceiptEmptyPurchaseOrderShipmentLineItemError",
      "CreatePurchaseOrderReceiptGenericError",
      "CreatePurchaseOrderReceiptInvalidShipmentRevisionStateError",
      "CreatePurchaseOrderReceiptNoActualShipAtProvidedError",
      "CreatePurchaseOrderRevisionCannotCopyAsNextVersionError",
      "CreatePurchaseOrderRevisionDraftExistsError",
      "CreatePurchaseOrderRevisionPartnerAccountStateNotActiveError",
      "CreatePurchaseOrderRevisionPublishedExistsError",
      "CreatePurchaseOrderRevisionTitleTooLong",
      "CreatePurchaseOrderShipmentRevisionASNNumberAlreadyBeUsedError",
      "CreatePurchaseOrderShipmentRevisionAlreadyCancelledError",
      "CreatePurchaseOrderShipmentRevisionAlreadyInReviewError",
      "CreatePurchaseOrderShipmentRevisionAlreadyReceivedError",
      "CreatePurchaseOrderShipmentRevisionDraftExistsError",
      "CreatePurchaseOrderShipmentRevisionDuplicateProductCatalogIdError",
      "CreatePurchaseOrderShipmentRevisionDuplicateProductCatalogIdForPORevisionError",
      "CreatePurchaseOrderShipmentRevisionEmptyPurchaseOrderLineItemError",
      "CreatePurchaseOrderShipmentRevisionEmptyPurchaseOrderRevisionLineItemError",
      "CreatePurchaseOrderShipmentRevisionExistingDraftShipmentUnderPORevisionError",
      "CreatePurchaseOrderShipmentRevisionGenericError",
      "CreatePurchaseOrderShipmentRevisionInvalidActualShipAtError",
      "CreatePurchaseOrderShipmentRevisionInvalidPurchaseOrderLineItemError",
      "CreatePurchaseOrderShipmentRevisionInvalidPurchaseOrderRevisionLineItemError",
      "CreatePurchaseOrderShipmentRevisionNotConfirmedPurchaseOrderError",
      "CreatePurchaseOrderShipmentRevisionNotConfirmedPurchaseOrderRevisionError",
      "CreatePurchaseOrderShipmentRevisionNotSameDCError",
      "CreatePurchaseOrderShipmentRevisionPurchaseOrderLineItemOverQuantityError",
      "CreatePurchaseOrderShipmentRevisionPurchaseOrderRevisionLineItemOverQuantityError",
      "CreatePurchaseRequestRevisionDraftExistsError",
      "CreatePurchaseRequestRevisionPartnerAccountStateNotActiveError",
      "CreatePurchaseRequestRevisionPublishedExistsError",
      "CreateSellableEditorialReviewRevisionDraftExistsError",
      "CreateSellableEditorialReviewRevisionGenericError",
      "CreateSellableEditorialReviewRevisionSameSellableAlreadyExistError",
      "CreateSellableSearchPageRuleAlreadyExistsError",
      "CreateSellableSearchPageRuleExistNoArchivedStateError",
      "CreateSellableSearchPageRuleFacetAllowAndBlockListedError",
      "CreateSellableSearchPageRuleRevisionDraftExistsError",
      "CreateSellableSearchPageSitemapRuleAlreadyExistsError",
      "CreateSellableSearchPageSitemapRuleDraftExistsError",
      "CreateSellableSearchPageSitemapRuleExistNoArchivedStateError",
      "CreateTenantRevisionDraftExistsError",
      "CreateTextLinkifyRuleTargetExistsError",
      "CreateUpsRateCardRevisionDraftExistsError",
      "CreateUpsRateCardRevisionDuplicatedServiceTypeError",
      "CreateUpsSurchargeRevisionDraftExistsError",
      "CreateUpsSurchargeRevisionDuplicatedSurchargeTypeError",
      "CreateUpsZoneLocatorRevisionDraftExistsError",
      "CreateVirtualCategoryAlreadyExistError",
      "CreateVirtualCategoryDuplicatePluralDisplayNameError",
      "CreateVirtualCategoryDuplicateSingularDisplayNameError",
      "CreateVirtualCategoryDuplicateSlugError",
      "CreateVirtualCategoryParentNodeNotFoundError",
      "CreateVirtualCategoryTreeRevisionDraftExistsError",
      "CustomDisplayNameTooLongExpertCuratedItemProsAndConsEditError",
      "CustomDisplayNameTooShortExpertCuratedItemProsAndConsEditError",
      "CustomErrorUpdateGigSubmissionRevisionError",
      "DeactivatedProductCatalogLaunchScheduleError",
      "DefaultErrorMessage",
      "DefaultQuestionPathNodeFlowViewModelValidationError",
      "DefaultUserRegistrationPathNodeFlowViewModelValidationError",
      "DependencyErrorWithdrawGigSubmissionRevisionError",
      "DependencyRequiredApprovalRequestSubmitDraftEditError",
      "DependencyResolutionFailedApprovalRequestCreationError",
      "DerivedCategoryProductCatalogGroupPublishError",
      "DerivedCategoryProductCatalogPublishError",
      "DerivedCategoryProductCatalogUpdateError",
      "DifferenceMismatchReorderCategoryNavigationSectionItemsError",
      "DifferenceMismatchReorderCategoryNavigationSectionsError",
      "DifferentDepartmentInitiateLeadTransferRequestError",
      "DiscountCashExchangeErrorInsufficientPayrollBalance",
      "DiscountCashExchangeErrorMaximumAllowedExceeded",
      "DiscountEligibleForFirstPartyCuratedItemEditError",
      "DiscountNotEligibleExpertCuratedItemEditError",
      "DistributionCenterOrderReturnNotFoundUpdateOrderReturnError",
      "DraftExistsCopyBrandProfileRevisionAsNextRevisionError",
      "DraftExistsCopyCategoryAssetRevisionAsNextRevisionError",
      "DraftExistsCopyRewardCreditGrantTemplateRevisionAsNextRevisionError",
      "DraftExistsCopyVendorAgreementRevisionError",
      "DraftExistsCreateCategoryAssetRevisionError",
      "DraftExistsCreateCategoryNavigationGroupRevisionError",
      "DraftExistsCreateCategoryNavigationRevisionError",
      "DraftExistsCreateCommTemplateError",
      "DraftExistsCreateCommTemplateFragmentError",
      "DraftExistsCreateNewDraftBrandProfileRevisionError",
      "DraftExistsCreateNewDraftRewardCreditGrantTemplateRevisionError",
      "DraftExistsCreatePathSchemaRevisionError",
      "DraftExistsCreateTriggerError",
      "DraftExistsExerciseRevisionCopyAsDraftError",
      "DraftExistsGigClaimError",
      "DraftExistsProductCatalogCreateDraftRevisionError",
      "DraftExistsProductCatalogGroupCreateDraftRevisionError",
      "DuplicateInvoiceFulfillmentRequestProcessingError",
      "DuplicateLeadAcceptLeadTransferRequestError",
      "DuplicateProOrConExpertCuratedItemProsAndConsEditError",
      "DuplicatedVariationAttributeValuesProductCatalogPublishError",
      "EditJiraTicketCommentError",
      "EditTextLinkifyRuleNoKeywordError",
      "EditorAlreadyAssignedUpdateGigSubmissionRevisionError",
      "EmptyAttributeSellableLMRatingGenerateError",
      "EmptyContentConsumerProductReviewLMSummaryGenerateError",
      "EmptyContentConsumerProductReviewLMThemeSentimentGenerateError",
      "EmptyContentCreateCategoryAssetRevisionError",
      "EmptyContentUpdateCategoryAssetRevisionError",
      "EmptyLineItemsPartnerOrderReturnError",
      "EmptyProductInfoSellableLMRatingGenerateError",
      "EmptyPublishGigContentError",
      "EmptyThemeConsumerProductReviewLMSummaryGenerateError",
      "EphemeralPathNotSupportedCreatePathGraphFlowError",
      "ErrorPathNodeFlowViewModel",
      "ExceedsMaximumLimitProductComparisonVideoError",
      "ExistInvalidPartnerOrderReturnsMerchantReturnError",
      "ExperimentArchiveInUseError",
      "ExperimentCopyAsDraftDraftExistsError",
      "ExperimentCreateExistsError",
      "ExperimentCreateInvalidNameError",
      "ExpertAccountChangeRequestNotInSubmittedStateError",
      "ExpertApplicationUpdateFailure",
      "ExpertBulkUpdateInvalidTaskTypeError",
      "ExpertBulkUpdateTaskInvalidStatusError",
      "ExpertCloseAccountError",
      "ExpertContributionUpdateError",
      "ExpertNotAvailableProductComparisonVideoError",
      "ExpertSavedLeadSearchNoFiltersError",
      "ExpertSavedLeadSearchSameNameError",
      "ExpertSavedLeadSearchUnknownError",
      "ExpiredProductCatalogPricingScheduleLineArchiveError",
      "FailedOAuthRegisterCustomerError",
      "FedExBillRevisionEditInvalidBillingPeriodError",
      "FedExRateCardRevisionEditInvalidEffectiveTimeError",
      "FedExRateCardRevisionEditInvalidWeightError",
      "FedExSurchargeRevisionEditInvalidEffectiveTimeError",
      "FedExZoneLocatorRevisionEditInvalidEffectiveTimeError",
      "FedExZoneLocatorRevisionEditInvalidZipCodeNumberError",
      "FedExZoneLocatorRevisionEditZipCodeOverlapError",
      "FirstSellableNotAvailableProductComparisonVideoError",
      "GenericPartnerOrderCreditMemoError",
      "GenericSyncPartnerOrderCreditMemoError",
      "GenericSyncPartnerOrderInvoiceError",
      "GenericUpdatePartnerOrderReturnError",
      "GetFedExTransitTimesFedExApiErrorError",
      "GetFedExTransitTimesInvalidZipCodeError",
      "GetFedExTransitTimesNoProductInventoryPropertiesFoundError",
      "GetFedExTransitTimesNotFoundError",
      "GetFedExTransitTimesNotSupportedPackageTypeError",
      "GetFedExTransitTimesNotSupportedShipperZipCodeError",
      "GetUpsRateAndTransitTimesBadRequestError",
      "GetUpsRateAndTransitTimesFailureError",
      "GetUpsRateAndTransitTimesNoInventoryPropertiesFoundError",
      "GetUpsRateAndTransitTimesNoPackageProvidedError",
      "GetUpsRateAndTransitTimesServiceUnavailableError",
      "GetUpsRateAndTransitTimesUnauthorizedError",
      "GetUpsTransitTimesInvalidZipCodeError",
      "GetUpsTransitTimesNoProductInventoryPropertiesFoundError",
      "GetUpsTransitTimesNotFoundError",
      "GetUpsTransitTimesNotSupportedPackageTypeError",
      "GetUpsTransitTimesNotSupportedShipperZipCodeError",
      "GetUpsTransitTimesUpsApiErrorError",
      "GigSubmissionReviewError",
      "GigTypeBlacklistedGigClaimError",
      "GigTypeNotQualifiedGigClaimError",
      "GiveFeedbackOnPurchaseOrderRevisionLineItemQuantityExceedLimitError",
      "GiveFeedbackOnPurchaseOrderRevisionNoteOrShipWindowMustBeSetError",
      "GrantIncentiveAlreadyGrantedErrorPathNodeFlowViewModel",
      "GrantIncentiveInternalErrorPathNodeFlowViewModel",
      "GrantIncentiveNotEligibleErrorPathNodeFlowViewModel",
      "HasActiveChildrenSubmitDeactivateCategoryActionError",
      "HasCategoryNavigationInGroupCategoryNavigationGroupRevisionError",
      "HasChildrenUpdateGigRequestError",
      "HasCustomizationAddExpertCuratedItemToWishlistError",
      "HasDiscountAddExpertCuratedItemToWishlistError",
      "ImageListHasEmptyFieldCreateCategoryAssetRevisionError",
      "ImageListHasEmptyFieldUpdateCategoryAssetRevisionError",
      "InArchivedStateAddExpertCuratedItemToWishlistError",
      "InArchivedStateAddGiftCardToWishlistError",
      "InArchivedStateAddProductBundleToWishlistError",
      "InArchivedStateAddSellableToWishlistError",
      "InArchivedStateArchiveWishlistItemError",
      "InArchivedStateSetWishlistVisibilityError",
      "InArchivedStateUpdateGiftCardWishlistItemError",
      "InArchivedStateUpdateSellableWishlistItemError",
      "InArchivedStateUpdateWishlistError",
      "InArchivedStateUpdateWishlistShippingAddressError",
      "InUseProductCatalogArchiveRevisionError",
      "InactiveExpertHiringCohortUpdateError",
      "InactiveGigRequiredCopyGigApplicationAsDraftError",
      "IncompatibleLayoutTypeCreatePathGraphFlowError",
      "IncompleteDependencyPublishAndPayGigError",
      "IncompleteSubmitGigApplicationError",
      "InconsistentVariationDimensionsProductCatalogPublishError",
      "IncorrectVideoUrlProductComparisonVideoError",
      "InsufficientQuantityPartnerOrderReturnError",
      "InvalidAccountStatePartnerAccountEditError",
      "InvalidAddressAddAddressWithValidationError",
      "InvalidAddressUpdateOrderShippingAddressError",
      "InvalidAmountAddGiftCardWishlistItemToOrderError",
      "InvalidAttributeExplanationsSellableLMRatingGenerateError",
      "InvalidAttributeForDepartmentExpertCuratedItemNoteEditError",
      "InvalidAttributeValueProductCatalogGroupUpdateError",
      "InvalidAttributeValueProductCatalogUpdateError",
      "InvalidCategoryProductCatalogGroupPublishError",
      "InvalidCategoryProductCatalogPublishError",
      "InvalidChannelUpdateExpertCategoryCommunicationError",
      "InvalidCurrencyCodeUpdatePartnerAccountError",
      "InvalidDistributionCenterInfoError",
      "InvalidDistributionCenterVendorUpdatePartnerAccountError",
      "InvalidEditorUpdateGigSubmissionRevisionError",
      "InvalidEmailAddressRegisterCustomerError",
      "InvalidEmailContactError",
      "InvalidEmailEditExpertApplicationError",
      "InvalidExpertApplicationState",
      "InvalidExpertCategorySeasonEndDate",
      "InvalidFieldUpdatePartnerAccountError",
      "InvalidGigApplicationStateArchiveGigRequestError",
      "InvalidHandleVerificationTokenRegisterCustomerError",
      "InvalidItemCreatePartReplacementRequestError",
      "InvalidItemCreateWarrantyClaimRequestError",
      "InvalidLLMResultConsumerProductReviewLMSummaryGenerateError",
      "InvalidMerchantCreateTradeInRequestError",
      "InvalidNoteStateExpertCuratedItemNoteEditError",
      "InvalidOrderLineItemTypeCreateConsumerProductReviewError",
      "InvalidParentEditCategoryError",
      "InvalidParentStateGigRequestError",
      "InvalidParentSubmitEditCategoryActionError",
      "InvalidPhoneNumberRegisterCustomerError",
      "InvalidPostTokenConsumerProductReviewError",
      "InvalidPromotionCodeBulkUpdateContentSelectionRuleError",
      "InvalidQuantityChangeOrderError",
      "InvalidRankRuleExpertAdvancementRuleError",
      "InvalidReferralRewardCreditClaimTokenRegisterCustomerError",
      "InvalidSavedExpertCuratedItemSavedExpertCuratedItemsEditExpertCuratedItemNoteError",
      "InvalidSearchQuerySellableSearchError",
      "InvalidSlackChannelBusinessUserTeamEditError",
      "InvalidSlugEditCategoryError",
      "InvalidSlugSubmitEditCategoryActionError",
      "InvalidSmsContactError",
      "InvalidSmsSignInRegisterCustomerError",
      "InvalidSourceNodePathCopyTokenError",
      "InvalidStateActivateGigRequestError",
      "InvalidStateApprovalRequestPublishError",
      "InvalidStateApproveGigApplicationError",
      "InvalidStateApprovePostPublishGigSubmissionRevisionError",
      "InvalidStateArchivePathSchemaRevisionError",
      "InvalidStateChangeOrderError",
      "InvalidStatePartnerOrderReturnError",
      "InvalidStateRejectGigApplicationError",
      "InvalidStateSubmitGigApplicationError",
      "InvalidStateUpdateGigRequestError",
      "InvalidStateVendorAgreementRevisionError",
      "InvalidTargetFeedReactionAddError",
      "InvalidTenantCopyTriggerIntoTenantError",
      "InvalidTrackingCreatePartReplacementRequestError",
      "InvalidTrackingCreateTradeInRequestError",
      "InvalidTrackingMerchantReturnError",
      "InvalidTrackingPartnerOrderReturnError",
      "InvalidTransactionStatusResendOrderPaymentVerificationError",
      "InvalidUserStateUpdatePartnerUserError",
      "InvalidValueCreateTradeInRequestError",
      "InvalidValueUpdateExpertCategoryLeadSettingError",
      "InvalidVariantProductCatalogPublishError",
      "ItemIneligibleForPurchaseUpdateTrialOrderError",
      "ItemProcessedOrShippedChangeOrderError",
      "LMApiErrorSellableLMRatingGenerateError",
      "LabelRequestFailedMerchantReturnError",
      "LeadBulkActionInvalidSnooze",
      "LeadBulkActionUnsupportedBulkSizeError",
      "LeadExistsUnderOtherExpertCreateExpertAffiliateOutreachError",
      "LeadExistsUnderRequestingExpertCreateExpertAffiliateOutreachError",
      "LedgerTransactionAlreadyPostedError",
      "LedgerTransactionAuthorizationExpiredError",
      "LedgerTransactionDuplicatedError",
      "LedgerTransactionInsufficientBalanceError",
      "LedgerTransactionInvalidAmountError",
      "LedgerTransactionInvalidExpirationError",
      "LedgerTransactionTransferInvalidTransferAmountError",
      "LedgerTransactionTransferMismatchAccountCurrencyCodeError",
      "LedgerTransactionTransferMismatchAccountTypeError",
      "LedgerTransactionTransferSelfTransferError",
      "LessonTrackingAlreadyCompletedStartLessonError",
      "LessonTrackingAlreadyExistsStartLessonError",
      "LineItemNotFoundPartnerOrderReturnError",
      "MalformedSlugCreatePathSchemaRevisionError",
      "MarkJiraTicketReadError",
      "MarkdownHasEmptyFieldCreateCategoryAssetRevisionError",
      "MarkdownHasEmptyFieldUpdateCategoryAssetRevisionError",
      "MaxClaimReachedGigClaimError",
      "MaxSizeExpertHiringClassAddApplicationError",
      "MaxSubmissionsReachedGigClaimError",
      "MaxSubmissionsUnarchiveGigRequestError",
      "MaximumLeadsWithTagExceededError",
      "MaximumTagExceededError",
      "MediaAssetMediaNotExistError",
      "MediaIdDoesNotExistPublishProductComparisonVideoError",
      "MediaUrlEmptyProductComparisonVideoError",
      "MembershipAlreadyInCartAddMembershipToOrderError",
      "MembershipRenewalNotSupportedError",
      "MerchantOrderNotFoundMerchantReturnError",
      "MilestonePayPlanDraftExistsError",
      "MilestonePayPlanNotInDraftError",
      "MinimumCategoryCountExpertHiringCohortUpdateError",
      "MisMatchedMerchantReturnsUpdateOrderReturnError",
      "MissingAttributeProductCatalogGroupPublishError",
      "MissingAttributeProductCatalogGroupUpdateError",
      "MissingAttributeProductCatalogPublishError",
      "MissingAttributeProductCatalogUpdateError",
      "MissingAttributeValueProductCatalogGroupPublishError",
      "MissingAttributeValueProductCatalogPublishError",
      "MissingBillDotComVendorIdUpdatePartnerAccountError",
      "MissingCategoryProductCatalogGroupPublishError",
      "MissingCategoryProductCatalogPublishError",
      "MissingContactUpdatePartnerAccountError",
      "MissingEntityFetcherCheckExerciseStatusError",
      "MissingExerciseEvaluatorCheckExerciseStatusError",
      "MissingFieldUpdatePartnerAccountError",
      "MissingLabelDataMerchantReturnError",
      "MissingNameEditExpertApplicationError",
      "MissingPaymentMethodUpdateMerchantRefundError",
      "MissingPublishedExerciseRevisionStartLessonError",
      "MissingRawAttributeProductCatalogGroupUpdateError",
      "MissingRawAttributeProductCatalogUpdateError",
      "MissingReferralEditExpertApplicationError",
      "MissingResolutionCreateWarrantyClaimRequestError",
      "MissingTemplateAttributeProductCatalogGroupPublishError",
      "MissingTemplateAttributeProductCatalogPublishError",
      "MissingTrainingScenarioStartLessonError",
      "MixingTrialAndPurchaseSellablesError",
      "MultipleUsersSpecifiedUserNeedArchiveError",
      "MultipleUsersSpecifiedUserNeedCreateError",
      "MultipleUsersSpecifiedUserNeedProfileError",
      "MustArchiveThroughApprovalArchiveCategoryAssetRevisionError",
      "MustArchiveThroughApprovalArchiveGigSubmissionRevisionError",
      "MustArchiveThroughApprovalCategoryNavigationGroupRevisionError",
      "MustArchiveThroughApprovalCategoryNavigationRevisionError",
      "MustArchiveThroughApprovalCommTemplateError",
      "MustArchiveThroughApprovalCommTemplateFragmentError",
      "MustLoginRequireAuthenticationPathNodeFlowViewModelError",
      "MustUseEphemeralPathCreatePathGraphFlowError",
      "NameConflictEditCategoryError",
      "NameConflictSubmitEditCategoryActionError",
      "NeedExistsInCategoryUserNeedCopyError",
      "NeedForCategoryExistsUserNeedCreateError",
      "NoAvailableShift",
      "NoCategoriesUserNeedCopyError",
      "NoExpertCuratedItemNoteToUpdateSavedExpertCuratedItemsEditExpertCuratedItemNoteError",
      "NoFinalEvalShiftForExpertApplication",
      "NoImagesAvailableForFirstSellableProductComparisonVideoError",
      "NoImagesAvailableForSecondSellableProductComparisonVideoError",
      "NoMembershipTypeAvailableAddMembershipToOrderError",
      "NoMerchantCreatePartReplacementRequestError",
      "NoProductIdentifierProductCatalogDraftFromSellableError",
      "NoPublishedVariantParentProductCatalogPublishError",
      "NoSellableFoundCreateInternalOrderError",
      "NoSuchAttributeError",
      "NoSuchDependencyApprovalRequestSubmitDraftEditError",
      "NoSuchFeedItemError",
      "NoSuchPathSchemaCreatePathGraphFlowError",
      "NoTipStoreCreditGiftTipStoreCreditError",
      "NodeNotTraversedNavigatePathGraphFlowError",
      "NotActiveAddConsumerProductReviewReactionError",
      "NotActualAddressBlockAddressError",
      "NotApprovedApprovalRequestPublishError",
      "NotApprovedDeferExpertApplicationError",
      "NotApprovedPublishAndPayGigError",
      "NotAssignedExpertInitiateLeadTransferRequestError",
      "NotAuthorizedApprovalRequestPublishError",
      "NotAvailableAddSellableWishlistItemToOrderError",
      "NotAvailableRejectLeadTransferRequestError",
      "NotCancellableUpdateOrderReturnError",
      "NotCatalogSourceMerchantReturnError",
      "NotDraftExpertHiringClassUpdateError",
      "NotDraftProductCatalogGroupUpdateError",
      "NotDraftProductCatalogUpdateError",
      "NotDraftUpdateGigApplicationError",
      "NotEligibleChangeOrderError",
      "NotEligibleClaimConsumerReferralRewardCreditError",
      "NotEligibleForStoreCreditRefundCreateOrderReturnError",
      "NotEligibleRefundOrderReturnError",
      "NotEligibleRefundPartnerOrderReturnError",
      "NotEligibleStateApprovableEntityDependencyResolutionError",
      "NotEmptyBusinessUserTeamUpdateError",
      "NotEnoughInfoUpdateMerchantRefundError",
      "NotEnoughInformationConsumerProductReviewLMSummaryGenerateError",
      "NotEnoughInformationConsumerProductReviewLMThemeSentimentGenerateError",
      "NotEnoughInformationSellableLMRatingGenerateError",
      "NotFoundApprovableEntityDependencyResolutionError",
      "NotFoundRewardCreditClaimError",
      "NotFullyAppliedSellableEditError",
      "NotInClaimedStatusWithdrawGigSubmissionRevisionError",
      "NotInDraftStateCreateCategoryNavigationSectionError",
      "NotInDraftStateCreateCategoryNavigationSectionItemError",
      "NotInDraftStateExerciseRevisionUpdateError",
      "NotInDraftStateProductComparisonVideoError",
      "NotInDraftStateRemoveCategoryNavigationSectionError",
      "NotInDraftStateRemoveCategoryNavigationSectionItemError",
      "NotInDraftStateReorderCategoryNavigationSectionItemsError",
      "NotInDraftStateReorderCategoryNavigationSectionsError",
      "NotInDraftStateUpdateBrandProfileRevisionError",
      "NotInDraftStateUpdateCategoryAssetRevisionError",
      "NotInDraftStateUpdateCategoryNavigationGroupRevisionError",
      "NotInDraftStateUpdateCategoryNavigationRevisionError",
      "NotInDraftStateUpdateCategoryNavigationSectionError",
      "NotInDraftStateUpdateCategoryNavigationSectionItemError",
      "NotInDraftStateUpdateCommTemplateError",
      "NotInDraftStateUpdateCommTemplateFragmentError",
      "NotInDraftStateUpdateGigSubmissionRevisionError",
      "NotInDraftStateUpdateRewardCreditGrantTemplateRevisionError",
      "NotInProgressCheckExerciseStatusError",
      "NotInProgressSetExerciseTrackingCompletedError",
      "NotInReviewAddGigSubmissionReviewError",
      "NotInReviewGigSubmissionRevisionError",
      "NotLatestRevisionProductCatalogArchiveRevisionError",
      "NotLatestRevisionProductCatalogGroupArchiveRevisionError",
      "NotOpenExpertHiringClassAddApplicationError",
      "NotParentGigRequestError",
      "NotParentGigSubmissionRevisionError",
      "NotParentSellableUpsertSellableLMRatingError",
      "NotPublicAddGiftCardWishlistItemToOrderError",
      "NotPublicAddSellableWishlistItemToOrderError",
      "NotPublishedCopyTriggerIntoTenantError",
      "NotPublishedExpertHiringClassCloseError",
      "NotPublishedExpertHiringClassOpenError",
      "NotPurchasedCreateConsumerProductReviewError",
      "NotQualifiedGigClaimError",
      "NotReadyExerciseRevisionPublishError",
      "NotReadyPublishBrandProfileRevisionError",
      "NotReadyPublishRewardCreditGrantTemplateRevisionError",
      "NotSubmittedWithdrawGigApplicationError",
      "NotSupportedPublishGigContentError",
      "NotValidAcceptLeadTransferRequestError",
      "NotWhitelistedGigClaimError",
      "NoteEditErrorExpertCuratedItemEditError",
      "NoteEditErrorSavedExpertCuratedItemsEditExpertCuratedItemNoteError",
      "NounHasEmptyFieldCreateCategoryAssetRevisionError",
      "NounHasEmptyFieldUpdateCategoryAssetRevisionError",
      "OnboardedWithdrawExpertApplicationError",
      "OperatorCloseAccountError",
      "OtherReasonEmptyNoteInitiateLeadTransferRequestError",
      "OtherReasonEmptyNoteRejectLeadTransferRequestError",
      "OutreachExistsCreateExpertAffiliateOutreachError",
      "ParcelsTooLargeMerchantReturnError",
      "ParentDeactivatedEditCategoryError",
      "ParentDeactivatedSubmitEditCategoryActionError",
      "ParentHasParentGigRequestError",
      "ParentNotExistProductCatalogDraftFromSellableError",
      "ParentOrderLineItemCreateConsumerProductReviewError",
      "ParseErrorConsumerProductReviewLMSummaryGenerateError",
      "ParseErrorConsumerProductReviewLMThemeSentimentGenerateError",
      "ParseErrorSellableLMRatingGenerateError",
      "PartialBundleCreateOrderCancellationError",
      "PaymentBlockedPayGigError",
      "PendingChangeExistsChangeOrderError",
      "PlainTextHasEmptyFieldCreateCategoryAssetRevisionError",
      "PlainTextHasEmptyFieldUpdateCategoryAssetRevisionError",
      "PostPublishNotEnabledApprovePostPublishGigSubmissionRevisionError",
      "PostRewardFailureRewardCreditClaimError",
      "PrepareExpertLeadCampaignForEditCopyNextVersionEmailFailedError",
      "ProcessGigSubmissionProductVideoMediaIdDoesNotExistError",
      "ProcessGigSubmissionProductVideoNotInDraftStateError",
      "ProcessGigSubmissionProductVideoNotVideoGigError",
      "ProcessGigSubmissionProductVideoOnProcessingAlreadyError",
      "ProcessGigSubmissionProductVideoProcessFailedError",
      "ProcessProductVideoTranscriptInArchivedStateError",
      "ProcessProductVideoTranscriptMediaTranscriptDoesNotExistError",
      "ProcessProductVideoTranscriptPrimarySellableDoesNotExistError",
      "ProcessProductVideoTranscriptProcessFailedError",
      "ProductBundleDraftExistsError",
      "ProductBundlePublishDerivedCategoryError",
      "ProductBundlePublishNoComponentError",
      "ProductBundlePublishNoSellableInComponentError",
      "ProductBundlePublishNotReadyError",
      "ProductBundleReorderMissedComponentError",
      "ProductBundleUpdateAttributeValidationError",
      "ProductBundleUpdateComponentNotFoundError",
      "ProductBundleUpdateDerivedCategoryError",
      "ProductBundleUpdateNotInDraftError",
      "ProductCustomizationDraftExistsError",
      "ProductCustomizationPublishNoFeatureError",
      "ProductCustomizationPublishNoFulfillmentChannelError",
      "ProductCustomizationPublishNoOptionInFeatureError",
      "ProductCustomizationPublishNotReadyError",
      "ProductCustomizationReorderMissedFeatureError",
      "ProductCustomizationReorderMissedFeatureGroupError",
      "ProductCustomizationUpdateFeatureGroupNotFoundError",
      "ProductCustomizationUpdateFeatureNotFoundError",
      "ProductCustomizationUpdateFeatureTypeChangedError",
      "ProductCustomizationUpdateIncompatibleAttributeOptionTypeError",
      "ProductCustomizationUpdateNotInDraftError",
      "ProsAndConsErrorExpertCuratedItemNoteEditError",
      "PublishApprovalRequestPublishAndPayGigError",
      "PublishExperimentMetricRevisionNotReadyError",
      "PublishExpertTestedProductAlreadyPublishedError",
      "PublishExpertTestedProductAttributeIsNotAvailableError",
      "PublishExpertTestedProductCreateGigSubmissionFailureError",
      "PublishExpertTestedProductExceedMaxGigClaimLimitForProductError",
      "PublishExpertTestedProductExistsGigRequestCreatedByHimselfBeforeError",
      "PublishExpertTestedProductExistsGigRequestCreatedByOperatorError",
      "PublishExpertTestedProductExistsGigSubmissionNotFinishedError",
      "PublishExpertTestedProductMustFillAllDetailsError",
      "PublishExpertTestedProductNotInEditableStateError",
      "PublishFailurePublishGigContentError",
      "PublishMilestonePayPlanNoMilestoneError",
      "PublishMilestonePayPlanNotReadyError",
      "PublishProductCatalogExportConfigNotReadyError",
      "PublishProductCatalogTemplateNotReadyError",
      "PublishPublisherRevisionNotReadyError",
      "PublishSuggestedActionMissingTagError",
      "PublishSuggestedActionNotReadyError",
      "PublishSuggestedMessageMissingTagError",
      "PublishSuggestedMessageNotReadyError",
      "PublishTenantRevisionNotReadyError",
      "PublishedVariantsProductCatalogGroupArchiveRevisionError",
      "PurchaseJointBusinessPlanEditDuplicatedNameError",
      "PurchaseJointBusinessPlanEditInvalidTimeRangeError",
      "PurchaseOrderInvoiceEditDuplicatedPurchaseOrderReceiptLineItemsError",
      "PurchaseOrderInvoiceEditInvalidActualFreightCostError",
      "PurchaseOrderInvoiceEditInvalidPurchaseOrderReceiptLineItemError",
      "PurchaseOrderNetDownEditDuplicatedNameError",
      "PurchaseOrderNetDownEditInvalidRebateReceivedDateError",
      "PurchaseOrderNetDownNotSupportedVendorError",
      "PurchaseOrderReceiptEditDuplicatedPurchaseOrderShipmentLineItemsError",
      "PurchaseOrderReceiptEditInvalidPurchaseOrderShipmentLineItemError",
      "PurchaseOrderReceiptsEditInvalidActualShipmentArriveAtError",
      "PurchaseOrderRevisionEditInvalidRequestShipAtError",
      "PurchaseOrderRevisionEditNotInAwaitingAcknowledgementStateError",
      "PurchasePromotionCreditEditDuplicatedNameError",
      "PurchasePromotionCreditEditInvalidPromoAmountError",
      "QualityScoreNeedsImprovementProductCatalogGroupPublishError",
      "QualityScoreNeedsImprovementProductCatalogPublishError",
      "ReassignToSameExpertInitiateLeadTransferRequestError",
      "RecipientEmailInvalidGiftTipStoreCreditError",
      "RecursiveParentUpdateGigRequestError",
      "RecursiveUsageApprovableEntityDependencyResolutionError",
      "ReferrerAlreadyPaidExpertEditError",
      "ReferrerAlreadySetExpertEditError",
      "RefundAmountExceededChangeOrderError",
      "RejectLeadRPALExclusionOverrideRequestAlreadyRejectedError",
      "RejectedGigClaimError",
      "RejectedWithdrawExpertApplicationError",
      "RequestExpiredGigClaimError",
      "RequestTimedOutChangeOrderError",
      "RequireLoginQuestionPathNodeFlowViewModelValidationError",
      "RequireLoginUserRegistrationPathNodeFlowViewModelValidationError",
      "RequiredWordCountNotMetUpdateGigSubmissionRevisionError",
      "ResolveJiraTicketError",
      "RetryProductComparisonVideoProcessingArticleCreatedError",
      "RetryProductComparisonVideoProcessingNotInActiveStateError",
      "RetryProductComparisonVideoProcessingOnProcessingError",
      "ReviewPayableEntryAlreadyPaidError",
      "RevisionExistsCopyVendorAgreementRevisionError",
      "RevisionExistsCreatePathSchemaRevisionError",
      "SameDCTransferCreateInternalOrderError",
      "SameSellableProductComparisonVideoError",
      "SameSourceTargetExpertInitiateLeadTransferRequestError",
      "SaveExpertTestedProductSaveFailedError",
      "SaveMediaFailedProductComparisonVideoError",
      "ScheduleExpertEmailCancelCampaignFailedError",
      "ScheduleExpertEmailConditionNotMetError",
      "ScheduleExpertEmailCreateCampaignError",
      "ScheduleExpertEmailFailedError",
      "SchedulingDepartmentChangeInvalidRunTimeError",
      "SchedulingDepartmentChangePendingChangeExistsError",
      "SecondSellableNotAvailableProductComparisonVideoError",
      "SelfReviewAddConsumerProductReviewReactionError",
      "SelfServeReturnInvalidOperationUpdateOrderReturnError",
      "SellableExpertiseRuleDraftExistsError",
      "SellableExpertiseRuleIncompleteMappingError",
      "SellableExpertiseRuleMappingNotFoundError",
      "SellableExpertiseRuleNoRequestAttributeValuesError",
      "SellableExpertiseRuleNoSellableAttributeValuesError",
      "SellableExpertiseRuleNotInDraftError",
      "SellableExpertiseRulePublishCategoryRuleExistsError",
      "SellableExpertiseRulePublishNotReadyError",
      "SellableExpertiseRuleSellableAttributeAddedAlreadyError",
      "SellableNotAllowedSetSellableStockAlertError",
      "SellableNotFromCatalogGroupProductCatalogGroupPublishFromSellablesError",
      "SellableNotFromCatalogProductCatalogPublishFromSellablesError",
      "SellableNotInCartAddWarrantyToOrderError",
      "ShiftChangeTooCloseFromShiftStartTime",
      "ShiftSlotConflictError",
      "ShiftSlotUnsupportedSchedulingUserRoleError",
      "ShipmentNotFoundPartnerOrderReturnError",
      "ShippingAddressNotSpecifiedSetWishlistVisibilityError",
      "ShippingUpdateForbiddenMerchantReturnError",
      "ShouldNotPayPublishAndPayGigError",
      "SlackChannelNotFoundBusinessUserTeamEditError",
      "SlugExistsCreateCategoryNavigationGroupRevisionError",
      "SlugInUseEditCategoryError",
      "SlugInUseSubmitEditCategoryActionError",
      "SourceExpertNotActiveInitiateLeadTransferRequestError",
      "SourceNotEligibleCreateInternalOrderError",
      "SubmitExpertAccountChangeAlreadyActivatedError",
      "SubmitExpertAccountChangeAlreadyDeactivatedError",
      "SubmitExpertAccountChangeRequestExistsError",
      "SubmitExpertLeadCampaignForReviewDeliveryTimeRequiredError",
      "SubmitExpertLeadCampaignForReviewDeliveryTimeTooEarlyError",
      "SubmitExpertLeadCampaignForReviewNotInDraftStateError",
      "SubmitExpertLeadCampaignForReviewSubmitFailedError",
      "SubmitGigSubmissionRevisionForReviewHasOptionalChildrenClaimedOrBlockedError",
      "SubmitGigSubmissionRevisionForReviewHasRequiredChildrenUnsubmittedError",
      "SubmitGigSubmissionRevisionForReviewSubmitFailedError",
      "SuggestedActionDraftExistsError",
      "SuggestedActionNotInDraftStateError",
      "SuggestedMessageDraftExistsError",
      "SuggestedMessageNotInDraftStateError",
      "TagAlreadyPresentError",
      "TagUnknownError",
      "TargetExpertNotActiveInitiateLeadTransferRequestError",
      "TrackingAlreadyExistsMerchantReturnError",
      "TrackingAlreadyExistsPartnerOrderReturnError",
      "TrialAlreadyCompletedUpdateTrialOrderError",
      "TrialNotEligibleExpertCuratedItemEditError",
      "TrialOrderNotFoundUpdateTrialOrderError",
      "UnableToCancelMerchantOrderCreateOrderCancellationError",
      "UnableToCreateAttachmentFulfillmentRequestProcessingError",
      "UnableToCreateFulfillmentEmburseCard",
      "UnableToGenerateConsumerProductReviewLMSummaryGenerateError",
      "UnableToGenerateConsumerProductReviewLMThemeSentimentGenerateError",
      "UnableToPlaceOrder",
      "UnableToSetCatalogIdWithSellable",
      "UndefinedAttributeUpsertSellableLMRatingError",
      "UndefinedCategoryAttributeSellableLMRatingGenerateError",
      "UnexpectedDetailTypeAddGiftCardWishlistItemToOrderError",
      "UnexpectedDetailTypeAddSellableWishlistItemToOrderError",
      "UnsubscribeEmailInvalidTokenError",
      "UnsupportedNoteStateExpertCuratedItemNoteEditError",
      "UpdateAffiliateAdGroupHasBeenUsedError",
      "UpdateAffiliateAdGroupInvalidStateError",
      "UpdateAffiliateAdGroupLengthExceedsLimitError",
      "UpdateAffiliateAdGroupNotInDraftStateError",
      "UpdateAffiliateAdInvalidStateError",
      "UpdateAffiliateAdLengthExceedsLimitError",
      "UpdateAffiliateAdNotInDraftStateError",
      "UpdateAffiliateCampaignHasBeenUsedError",
      "UpdateAffiliateCampaignLengthExceedsLimitError",
      "UpdateAffiliateCampaignNotInDraftStateError",
      "UpdateAffiliateCampaignSourceNotPublishError",
      "UpdateAffiliateLengthExceedsLimitError",
      "UpdateAffiliateSlugExistsError",
      "UpdateAffiliateSourceAlreadyExistError",
      "UpdateAffiliateSourceHasBeenUsedError",
      "UpdateAffiliateSourceNotInDraftStateError",
      "UpdateAlreadyExistsExpertCuratedItemEditError",
      "UpdateCategoryAttributeChangeRevisionHasSameAttributeSchemaAlreadyError",
      "UpdateCategoryAttributeChangeRevisionNotInDraftStateError",
      "UpdateCategoryAttributeChangeRevisionPublishError",
      "UpdateCategoryChangeRevisionNotInDraftStateError",
      "UpdateContentSelectionRevisionEngagementDuplicatedError",
      "UpdateContentSelectionRevisionInvalidPathError",
      "UpdateContentSelectionRuleNotInDraftStateError",
      "UpdateContentSelectionToolComplexExperiment",
      "UpdateContentSelectionToolCreateExperimentFailure",
      "UpdateContentSelectionToolErrorExperiment",
      "UpdateContentSelectionToolNotInDraftStateError",
      "UpdateContentSelectionToolUpdateExperimentFailure",
      "UpdateContentSelectionToolUsagesPresent",
      "UpdateConversationStarterSetRevisionNotAllowedStateError",
      "UpdateCuratedDistributionCenterNotCuratedError",
      "UpdateDemoProductRequestGenericError",
      "UpdateEmailTemplateContentVersionConflictError",
      "UpdateEmailTemplateInvalidAddBlockLocationError",
      "UpdateEmailTemplateInvalidAddContentLocationError",
      "UpdateEmailTemplateInvalidCopyBlockOperationError",
      "UpdateEmailTemplateInvalidDeleteBlockOperationError",
      "UpdateEmailTemplateInvalidMoveBlockOperationError",
      "UpdateEmailTemplateNotInDraftStateError",
      "UpdateEmailTemplateTemplateTypeAlreadySetError",
      "UpdateEmailTemplateUnsupportedOperationError",
      "UpdateExperimentMetricRevisionNotInDraftStateError",
      "UpdateExpertLeadCampaignConditionNotMetError",
      "UpdateExpertLeadCampaignNotInEditableStateError",
      "UpdateExpertTestedProductConditionNotMetError",
      "UpdateExpertTestedProductNotInEditableStateError",
      "UpdateExternalAdCategoryRuleStateNotAllowedStateError",
      "UpdateFedExBillRevisionExistDiffDataError",
      "UpdateFedExBillRevisionInvalidBilledBaseRate",
      "UpdateFedExBillRevisionInvalidBilledTotalRate",
      "UpdateFedExBillRevisionInvalidBilledWeight",
      "UpdateFedExBillRevisionInvalidDistributionCenter",
      "UpdateFedExBillRevisionNotInDraftStateError",
      "UpdateFedExBillRevisionNotInPublishedStateError",
      "UpdateFedExBillRevisionNotInTaskProcessingError",
      "UpdateFedExBillRevisionTaskOverlappedError",
      "UpdateFedExRateCardRevisionEnvelopeAlreadyExistsError",
      "UpdateFedExRateCardRevisionInvalidRoundingPrecisionError",
      "UpdateFedExRateCardRevisionInvalidZoneRateTypeError",
      "UpdateFedExRateCardRevisionLineItemWeightOverlapError",
      "UpdateFedExRateCardRevisionNoLineItemFoundError",
      "UpdateFedExRateCardRevisionNotInDraftStateError",
      "UpdateFedExRateCardRevisionOverlappedEffectiveDateRangeError",
      "UpdateFedExRateCardRevisionServiceTypeNotSupportedError",
      "UpdateFedExSurchargeRevisionInvalidRateValueError",
      "UpdateFedExSurchargeRevisionInvalidZipCodeNumberError",
      "UpdateFedExSurchargeRevisionNotInDraftStateError",
      "UpdateFedExSurchargeRevisionOverlappedEffectiveDateRangeError",
      "UpdateFedExZoneLocatorRevisionLineItemZipCodeOverlapError",
      "UpdateFedExZoneLocatorRevisionNoLineItemFoundError",
      "UpdateFedExZoneLocatorRevisionNotInDraftStateError",
      "UpdateFlxpointOrderInvoiceInvalidPartnerAccountError",
      "UpdateFlxpointOrderInvoiceInvalidStateError",
      "UpdateInventoryAdjustmentInvalidPurchaseOrderShipmentError",
      "UpdateLeadRPALExclusionOverrideRequestClosedError",
      "UpdatePageTemplateAlreadyExistError",
      "UpdatePageTemplateInvalidAddBlockLocationError",
      "UpdatePageTemplateInvalidAddContentOperationError",
      "UpdatePageTemplateInvalidComponentParameterError",
      "UpdatePageTemplateInvalidCopyBlockOperationError",
      "UpdatePageTemplateInvalidDeleteBlockOperationError",
      "UpdatePageTemplateInvalidDeleteContentOperationError",
      "UpdatePageTemplateInvalidMoveBlockOperationError",
      "UpdatePageTemplateInvalidPromotionError",
      "UpdatePageTemplateNotInDraftStateError",
      "UpdatePartnerDistributionCenterConfigRevisionDuplicatedNameError",
      "UpdatePartnerDistributionCenterConfigRevisionInvalidSLAValueError",
      "UpdatePartnerDistributionCenterConfigRevisionNotInDraftStateError",
      "UpdatePartnerDistributionCenterConfigRevisionNotReadyError",
      "UpdatePartnerDistributionCenterConfigRevisionPartnerAccountStateNotActiveError",
      "UpdatePartnerDistributionCenterGenericError",
      "UpdatePartnerOrderInvoiceIncorrectCostShipmentsError",
      "UpdatePartnerOrderInvoiceIncorrectInvoicedQuantityLineItemsError",
      "UpdatePartnerOrderInvoiceInvalidPartnerAccountError",
      "UpdatePartnerOrderInvoiceInvalidStateError",
      "UpdatePartnerOrderInvoiceNoCommentForDifferentDropShipFeeError",
      "UpdatePartnerOrderInvoiceNoReasonForDifferentUnitCostError",
      "UpdateProductCatalogExportConfigInvalidAttributeNamesError",
      "UpdateProductCatalogExportConfigInvalidMatrixAttributeNamesError",
      "UpdateProductCatalogExportConfigNotInDraftStateError",
      "UpdateProductCatalogTemplateNotInDraftStateError",
      "UpdatePromotionSelectionRuleInvalidPromotionError",
      "UpdatePromotionSelectionRuleNotInDraftStateError",
      "UpdatePublisherRevisionNotInDraftStateError",
      "UpdatePurchaseJointBusinessPlanCancelledLineItemError",
      "UpdatePurchaseJointBusinessPlanDuplicateLineItemError",
      "UpdatePurchaseJointBusinessPlanInvalidImportActionError",
      "UpdatePurchaseJointBusinessPlanInvalidModificationToAmountError",
      "UpdatePurchaseJointBusinessPlanInvalidProductCatalogError",
      "UpdatePurchaseJointBusinessPlanInvalidQuantityError",
      "UpdatePurchaseJointBusinessPlanInvalidStateError",
      "UpdatePurchaseJointBusinessPlanInvalidTotalAmountError",
      "UpdatePurchaseJointBusinessPlanInvalidUnitPriceDeductionError",
      "UpdatePurchaseJointBusinessPlanLineItemAlreadyCancelledError",
      "UpdatePurchaseJointBusinessPlanLineItemDuplicateProductCatalogError",
      "UpdatePurchaseJointBusinessPlanLineItemInvalidImportActionError",
      "UpdatePurchaseJointBusinessPlanLineItemInvalidPlanStateError",
      "UpdatePurchaseJointBusinessPlanLineItemInvalidProductCatalogError",
      "UpdatePurchaseJointBusinessPlanLineItemInvalidQuantityError",
      "UpdatePurchaseJointBusinessPlanLineItemInvalidUnitPriceDeductionError",
      "UpdatePurchaseJointBusinessPlanLineItemNotFoundError",
      "UpdatePurchaseJointBusinessPlanNoActiveLineItemsError",
      "UpdatePurchaseJointBusinessPlanNoLineItemFoundError",
      "UpdatePurchaseJointBusinessPlanTaskAlreadyProcessedError",
      "UpdatePurchaseJointBusinessPlanTaskStillProcessingError",
      "UpdatePurchaseOrderCancelledLineItemError",
      "UpdatePurchaseOrderCatalogNotPublishedError",
      "UpdatePurchaseOrderConfirmedQuantityGreaterThanRequestError",
      "UpdatePurchaseOrderDuplicatedFriendlyNameError",
      "UpdatePurchaseOrderEmptyAdditionalInvoiceSubReasonError",
      "UpdatePurchaseOrderInvalidAdditionalInvoiceIdError",
      "UpdatePurchaseOrderInvalidCancelByDateError",
      "UpdatePurchaseOrderInvalidConfirmedShipAtError",
      "UpdatePurchaseOrderInvalidDiscountError",
      "UpdatePurchaseOrderInvalidFriendlyNameError",
      "UpdatePurchaseOrderInvalidPurchaseRequestLineItemError",
      "UpdatePurchaseOrderInvalidUnitCostError",
      "UpdatePurchaseOrderInvoiceAlreadyConfirmedError",
      "UpdatePurchaseOrderInvoiceCancelledLineItemError",
      "UpdatePurchaseOrderInvoiceDuplicatedExternalInvoiceNumberError",
      "UpdatePurchaseOrderInvoiceEmptyAdditionalDiscountCommentsError",
      "UpdatePurchaseOrderInvoiceEmptyCommentsError",
      "UpdatePurchaseOrderInvoiceEmptyOtherCostCommentsError",
      "UpdatePurchaseOrderInvoiceInvalidAdditionalDiscountError",
      "UpdatePurchaseOrderInvoiceInvalidAdjustedQuantityError",
      "UpdatePurchaseOrderInvoiceInvalidDiscountError",
      "UpdatePurchaseOrderInvoiceInvalidInvoiceTypeError",
      "UpdatePurchaseOrderInvoiceInvalidInvoicedQuantityError",
      "UpdatePurchaseOrderInvoiceInvalidLineItemIdError",
      "UpdatePurchaseOrderInvoiceInvalidOtherCostError",
      "UpdatePurchaseOrderInvoiceInvalidStateError",
      "UpdatePurchaseOrderInvoiceInvalidUnitCostError",
      "UpdatePurchaseOrderInvoiceInvoiceLineItemAlreadyCreatedError",
      "UpdatePurchaseOrderInvoiceInvoicedAtNotSetError",
      "UpdatePurchaseOrderInvoiceInvoicedQuantityNotMatchError",
      "UpdatePurchaseOrderInvoiceMissingLineItemError",
      "UpdatePurchaseOrderInvoiceNoAdjustmentFoundError",
      "UpdatePurchaseOrderInvoiceNoExternalInvoiceItemFoundError",
      "UpdatePurchaseOrderInvoiceNoLineItemFoundError",
      "UpdatePurchaseOrderInvoiceReceiptLineItemAlreadyCreatedInTheSameInvoiceError",
      "UpdatePurchaseOrderLineItemMissingFieldsError",
      "UpdatePurchaseOrderManagementExceptionAlreadyAcknowledgedError",
      "UpdatePurchaseOrderManagementExceptionAlreadyResolvedError",
      "UpdatePurchaseOrderManagementExceptionNoCommentEntryFoundError",
      "UpdatePurchaseOrderNetDownAlreadyConfirmedError",
      "UpdatePurchaseOrderNetDownCancelledLineItemError",
      "UpdatePurchaseOrderNetDownDuplicateLineItemError",
      "UpdatePurchaseOrderNetDownInvalidImportActionError",
      "UpdatePurchaseOrderNetDownInvalidProductCatalogError",
      "UpdatePurchaseOrderNetDownInvalidQuantityError",
      "UpdatePurchaseOrderNetDownInvalidStateError",
      "UpdatePurchaseOrderNetDownInvalidUnitPriceDeductionError",
      "UpdatePurchaseOrderNetDownNoActiveLineItemsError",
      "UpdatePurchaseOrderNetDownNoInventoryItemFoundError",
      "UpdatePurchaseOrderNetDownNoLineItemFoundError",
      "UpdatePurchaseOrderNoActiveLineItemError",
      "UpdatePurchaseOrderNoLineItemFoundError",
      "UpdatePurchaseOrderNotInModifiableStateError",
      "UpdatePurchaseOrderNotInOpenStateError",
      "UpdatePurchaseOrderPurchaseOrderIdMissingError",
      "UpdatePurchaseOrderPurchaseRequestLineItemAlreadyCreatedError",
      "UpdatePurchaseOrderReceiptAlreadyCancelledError",
      "UpdatePurchaseOrderReceiptAlreadyInboundedError",
      "UpdatePurchaseOrderReceiptCancelledLineItemError",
      "UpdatePurchaseOrderReceiptInvalidDamagedQuantityError",
      "UpdatePurchaseOrderReceiptInvalidInboundedAtError",
      "UpdatePurchaseOrderReceiptInvalidReceivedQuantityError",
      "UpdatePurchaseOrderReceiptInvalidStateError",
      "UpdatePurchaseOrderReceiptMustBeInboundedError",
      "UpdatePurchaseOrderReceiptNoLineItemFoundError",
      "UpdatePurchaseOrderReceiptShipmentLineItemAlreadyCreatedInTheSameReceiptError",
      "UpdatePurchaseOrderRevisionCancelledLineItemError",
      "UpdatePurchaseOrderRevisionDuplicateLineItemError",
      "UpdatePurchaseOrderRevisionDuplicatedFriendlyNameError",
      "UpdatePurchaseOrderRevisionHasRemainUnresolvedFeedbackError",
      "UpdatePurchaseOrderRevisionInValidProductCatalogIdError",
      "UpdatePurchaseOrderRevisionInValidQuantityError",
      "UpdatePurchaseOrderRevisionInvalidActionError",
      "UpdatePurchaseOrderRevisionInvalidCancelByDateError",
      "UpdatePurchaseOrderRevisionInvalidDiscountError",
      "UpdatePurchaseOrderRevisionInvalidDistributionCenterError",
      "UpdatePurchaseOrderRevisionInvalidFeedbackActionError",
      "UpdatePurchaseOrderRevisionInvalidFriendlyNameError",
      "UpdatePurchaseOrderRevisionInvalidProductCatalogError",
      "UpdatePurchaseOrderRevisionInvalidUnitCostError",
      "UpdatePurchaseOrderRevisionNoAddActionInConfirmStateError",
      "UpdatePurchaseOrderRevisionNoCancelActionInConfirmStateError",
      "UpdatePurchaseOrderRevisionNoLineItemFoundError",
      "UpdatePurchaseOrderRevisionNotInAwaitingAckStateError",
      "UpdatePurchaseOrderRevisionNotInDraftStateError",
      "UpdatePurchaseOrderRevisionNotInModifiableStateError",
      "UpdatePurchaseOrderRevisionNotInModifiableStateForPartnerError",
      "UpdatePurchaseOrderRevisionNotInSubmitForReviewStateError",
      "UpdatePurchaseOrderRevisionNotPublishedError",
      "UpdatePurchaseOrderRevisionPublishError",
      "UpdatePurchaseOrderRevisionPurchaseOrderRevisionIdMissingError",
      "UpdatePurchaseOrderRevisionShipStartAtLaterThanEndAtError",
      "UpdatePurchaseOrderRevisionUnableToCancelError",
      "UpdatePurchaseOrderRevisionUnableToCloseError",
      "UpdatePurchaseOrderRevisionUnableToRevertCloseError",
      "UpdatePurchaseOrderRevisionUnknownError",
      "UpdatePurchaseOrderRevisionValidationError",
      "UpdatePurchaseOrderShipmentRevisionAlreadyDeliveredError",
      "UpdatePurchaseOrderShipmentRevisionAlreadyReceivedError",
      "UpdatePurchaseOrderShipmentRevisionCancelledLineItemError",
      "UpdatePurchaseOrderShipmentRevisionDuplicateProductCatalogIdError",
      "UpdatePurchaseOrderShipmentRevisionDuplicatedAdvanceShipNoticeNumberError",
      "UpdatePurchaseOrderShipmentRevisionGenericError",
      "UpdatePurchaseOrderShipmentRevisionInvalidActualShipAtError",
      "UpdatePurchaseOrderShipmentRevisionInvalidDeliveredAtError",
      "UpdatePurchaseOrderShipmentRevisionInvalidEstimatedFreightCostError",
      "UpdatePurchaseOrderShipmentRevisionInvalidPurchaseOrderLineItemError",
      "UpdatePurchaseOrderShipmentRevisionInvalidReceivedAtError",
      "UpdatePurchaseOrderShipmentRevisionInvalidShipmentStateError",
      "UpdatePurchaseOrderShipmentRevisionInvalidShippedQuantityError",
      "UpdatePurchaseOrderShipmentRevisionInvalidStateError",
      "UpdatePurchaseOrderShipmentRevisionLineItemOverQuantityError",
      "UpdatePurchaseOrderShipmentRevisionMustArchiveThroughApprovalError",
      "UpdatePurchaseOrderShipmentRevisionNoLineItemFoundError",
      "UpdatePurchaseOrderShipmentRevisionNotOtherCarrierError",
      "UpdatePurchaseOrderShipmentRevisionNotPublishedError",
      "UpdatePurchaseOrderShipmentRevisionNotSameDCError",
      "UpdatePurchaseOrderShipmentRevisionPOLineItemAlreadyCreatedInTheSameShipmentError",
      "UpdatePurchaseOrderShipmentRevisionPublishError",
      "UpdatePurchaseOrderUnableToCancelError",
      "UpdatePurchasePromotionCreditInvalidStateError",
      "UpdatePurchaseRequestCancelledLineItemError",
      "UpdatePurchaseRequestCuratedBillingEntityError",
      "UpdatePurchaseRequestDuplicateLineItemError",
      "UpdatePurchaseRequestInvalidDiscountError",
      "UpdatePurchaseRequestInvalidDistributionCenterError",
      "UpdatePurchaseRequestInvalidImportActionError",
      "UpdatePurchaseRequestInvalidProductCatalogError",
      "UpdatePurchaseRequestInvalidQuantityError",
      "UpdatePurchaseRequestInvalidRequestShipAtError",
      "UpdatePurchaseRequestInvalidUnitCostError",
      "UpdatePurchaseRequestNoLineItemFoundError",
      "UpdatePurchaseRequestNotInDraftStateError",
      "UpdatePurchaseRequestUnknownError",
      "UpdateSellableEditorialReviewInvalidMoveBlockOperationError",
      "UpdateSellableEditorialReviewInvalidMoveItemOperationError",
      "UpdateSellableEditorialReviewNotInDraftStateError",
      "UpdateSellableSearchPageRuleFacetAllowAndBlockListedError",
      "UpdateSellableSearchPageRuleNotAllowedStateError",
      "UpdateSellableSearchPageSitemapRuleNotAllowedStateError",
      "UpdateTenantRevisionNotInDraftStateError",
      "UpdateTextLinkifyRuleInvalidStateError",
      "UpdateUpsRateCardRevisionEnvelopeAlreadyExistsError",
      "UpdateUpsRateCardRevisionInvalidRoundingPrecisionError",
      "UpdateUpsRateCardRevisionLineItemWeightOverlapError",
      "UpdateUpsRateCardRevisionNoLineItemFoundError",
      "UpdateUpsRateCardRevisionNotInDraftStateError",
      "UpdateUpsRateCardRevisionOverlappedEffectiveDateRangeError",
      "UpdateUpsSurchargeRevisionInvalidRateValueError",
      "UpdateUpsSurchargeRevisionInvalidZipCodeNumberError",
      "UpdateUpsSurchargeRevisionNotInDraftStateError",
      "UpdateUpsSurchargeRevisionOverlappedEffectiveDateRangeError",
      "UpdateUpsZoneLocatorRevisionLineItemZipCodeOverlapError",
      "UpdateUpsZoneLocatorRevisionNoLineItemFoundError",
      "UpdateUpsZoneLocatorRevisionNotInDraftStateError",
      "UpdateVirtualCategoryAlreadyExistError",
      "UpdateVirtualCategoryDuplicatePluralDisplayNameError",
      "UpdateVirtualCategoryDuplicateSingularDisplayNameError",
      "UpdateVirtualCategoryDuplicateSlugError",
      "UpdateVirtualCategoryParentNodeNotFoundError",
      "UpdateVirtualCategoryTreeRevisionCannotMoveError",
      "UpdateVirtualCategoryTreeRevisionNotInDraftStateError",
      "UploadMediaNoFileError",
      "UpsRateCardRevisionEditInvalidEffectiveTimeError",
      "UpsRateCardRevisionEditInvalidWeightError",
      "UpsSearchLocationApiErrorError",
      "UpsSearchLocationBadRequestError",
      "UpsSearchLocationFailureError",
      "UpsSearchLocationServiceUnavailableError",
      "UpsSearchLocationUnauthorizedError",
      "UpsSurchargeRevisionEditInvalidEffectiveTimeError",
      "UpsZoneLocatorRevisionEditInvalidEffectiveTimeError",
      "UpsZoneLocatorRevisionEditInvalidZipCodeNumberError",
      "UpsZoneLocatorRevisionEditZipCodeOverlapError",
      "UpsertCategoryInventoryPropertiesMongoUpsertError",
      "UpsertProductInventoryPropertiesMongoUpsertError",
      "UsageLimitExceededRewardCreditClaimError",
      "UsagesPresentArchivePathSchemaRevisionError",
      "UserCreationFailedCreatePartnerUserError",
      "UserEmailNotFoundCreatePartnerUserError",
      "UserExistsCreateExpertAffiliateOutreachError",
      "ValidationErrorCreateCategoryAssetRevisionError",
      "ValidationErrorUpdateCategoryAssetRevisionError",
      "VariationDimensionSellableRemoveAttributeError",
      "VideoUrlDuplicatedProductComparisonVideoError",
      "VideoUrlIsNotSharedProductComparisonVideoError",
      "WarrantyAlreadyInCartAddWarrantyToOrderError",
      "WeeklyLimitExceededGigClaimError",
      "WrongEntityTypeCheckExerciseStatusError",
      "WrongRoleExpertHiringClassAddApplicationError",
      "WrongTypeUpdateGigApplicationError"
    ],
    "EscalationUser": [
      "BusinessUser",
      "Operator",
      "User"
    ],
    "ExchangePayrollForDiscountCashError": [
      "DiscountCashExchangeErrorInsufficientPayrollBalance",
      "DiscountCashExchangeErrorMaximumAllowedExceeded",
      "LedgerTransactionDuplicatedError"
    ],
    "ExerciseDetail": [
      "CurationExercise",
      "MessageExercise",
      "PhoneCallExercise",
      "QuickReplyExercise",
      "RichCardExercise",
      "SnoozeExercise"
    ],
    "ExerciseTrackingDetail": [
      "CurationExerciseTracking",
      "MessageExerciseTracking",
      "PhoneCallExerciseTracking",
      "QuickReplyExerciseTracking",
      "RichCardExerciseTracking",
      "SnoozeExerciseTracking"
    ],
    "ExperimentRevisionRule": [
      "ExperimentRevisionPartitionRule",
      "ExperimentRevisionTreatmentArmRule"
    ],
    "ExperimentRevisionRules": [
      "ExperimentRevisionPartitionRules",
      "ExperimentRevisionTreatmentArmRules"
    ],
    "ExperimentUsage": [
      "CodeExperimentUsage",
      "CommTemplateExperimentUsage",
      "ContentSelectionRuleExperimentUsage",
      "ContentSelectionRuleRevisionExperimentUsage",
      "PageTemplateExperimentUsage",
      "PathSchemaExperimentUsage",
      "PathSchemaFragmentExperimentUsage",
      "TriggerExperimentUsage"
    ],
    "ExpertAccountChangeSetting": [
      "ExpertAccountReactivationSetting"
    ],
    "ExpertApplicationActivity": [
      "ExpertApplicationAddedToClass",
      "ExpertApplicationApproved",
      "ExpertApplicationDeferralDateChanged",
      "ExpertApplicationDeferred",
      "ExpertApplicationOnboarded",
      "ExpertApplicationPendingDeferral",
      "ExpertApplicationRejected",
      "ExpertApplicationRestored",
      "ExpertApplicationStageChanged",
      "ExpertApplicationStateChanged",
      "ExpertApplicationTaskCompleted",
      "ExpertApplicationTaskOverdue",
      "ExpertApplicationWithdrawn"
    ],
    "ExpertApplicationReviewer": [
      "BusinessUser",
      "Operator"
    ],
    "ExpertApplicationUpdateDeferredUntilError": [
      "DefaultErrorMessage"
    ],
    "ExpertBulkUpdateTaskParameters": [
      "ExpertBulkUpdateTaskAttributeOverrideParameters",
      "ExpertBulkUpdateTaskAttributeRemoveParameters",
      "ExpertBulkUpdateTaskCoownerQualificationUpdateParameters",
      "ExpertBulkUpdateTaskSchedulingOverrideParameters",
      "ExpertBulkUpdateTaskSetPresenceUpdateParameters"
    ],
    "ExpertCuratedActivityFeedItem": [
      "ExpertCuratedActivityFeedOrderItem"
    ],
    "ExpertCuratedItemEditError": [
      "DiscountEligibleForFirstPartyCuratedItemEditError",
      "DiscountNotEligibleExpertCuratedItemEditError",
      "NoteEditErrorExpertCuratedItemEditError",
      "TrialNotEligibleExpertCuratedItemEditError",
      "UpdateAlreadyExistsExpertCuratedItemEditError"
    ],
    "ExpertCuratedItemNoteEditError": [
      "InvalidAttributeForDepartmentExpertCuratedItemNoteEditError",
      "InvalidNoteStateExpertCuratedItemNoteEditError",
      "ProsAndConsErrorExpertCuratedItemNoteEditError",
      "UnsupportedNoteStateExpertCuratedItemNoteEditError"
    ],
    "ExpertCuratedItemProsAndConsEditError": [
      "AttributeEnumEntryDoesNotExistExpertCuratedItemProsAndConsEditError",
      "CustomDisplayNameTooLongExpertCuratedItemProsAndConsEditError",
      "CustomDisplayNameTooShortExpertCuratedItemProsAndConsEditError"
    ],
    "ExpertCuratedItemRemovedBy": [
      "BusinessUser",
      "SystemUser",
      "User"
    ],
    "ExpertHiringClassAddApplicationError": [
      "AfterStartDateExpertHiringClassAddApplicationError",
      "AlreadyAddedExpertHiringClassAddApplicationError",
      "CategoryNotAllowedExpertHiringClassAddApplicationError",
      "MaxSizeExpertHiringClassAddApplicationError",
      "NotOpenExpertHiringClassAddApplicationError",
      "WrongRoleExpertHiringClassAddApplicationError"
    ],
    "ExpertHiringClassArchiveError": [
      "AlreadyActiveExpertHiringClassUpdateError",
      "AlreadyArchivedExpertHiringClassArchiveError"
    ],
    "ExpertHiringClassCloseError": [
      "ArchivedExpertHiringClassCloseError",
      "NotPublishedExpertHiringClassCloseError"
    ],
    "ExpertHiringClassOpenError": [
      "ArchivedExpertHiringClassOpenError",
      "NotPublishedExpertHiringClassOpenError"
    ],
    "ExpertHiringClassPublishError": [
      "NotDraftExpertHiringClassUpdateError"
    ],
    "ExpertHiringClassUpdateError": [
      "ArchivedExpertHiringClassUpdateError",
      "NotDraftExpertHiringClassUpdateError"
    ],
    "ExpertHiringCohortArchiveError": [
      "ActiveClassesExpertHiringCohortArchiveError",
      "AlreadyInactiveExpertHiringCohortArchiveError"
    ],
    "ExpertHiringCohortCreateError": [
      "DefaultErrorMessage"
    ],
    "ExpertHiringCohortUpdateError": [
      "AlreadyActiveExpertHiringClassUpdateError",
      "ArchivedExpertHiringClassUpdateError",
      "InactiveExpertHiringCohortUpdateError",
      "MinimumCategoryCountExpertHiringCohortUpdateError",
      "NotDraftExpertHiringClassUpdateError"
    ],
    "ExpertHiringFeedbackDetail": [
      "ExpertApplicationEvaluation",
      "ExpertApplicationSimpleRating",
      "ExpertInterviewFeedback"
    ],
    "ExpertHiringTaskConfigDetail": [
      "CompleteGigConfigDetail",
      "CompleteTrainingConfigDetail",
      "CompleteTrainingLessonConfigDetail",
      "ExpertKnowledgeTestConfigDetail"
    ],
    "ExpertHiringTaskStatusDetail": [
      "CompleteTrainingStatusDetail",
      "FinalEvalStatusDetail"
    ],
    "ExpertInboxSnapshotDetail": [
      "ExpertInboxSnapshotBroadcastPoolDetail",
      "ExpertInboxSnapshotFolderDetail"
    ],
    "ExpertKnowledgeTestInstruction": [
      "ExpertKnowledgeTestAppcuesInstruction",
      "ExpertKnowledgeTestGuruInstruction",
      "ExpertKnowledgeTestLoomEmbedInstruction"
    ],
    "ExpertNotification": [
      "ExpertNotificationLeadEscalation",
      "ExpertNotificationLeadSurge",
      "ExpertNotificationNextShiftStarted",
      "ExpertNotificationPresenceStatusChange",
      "ExpertNotificationShiftEnded"
    ],
    "ExpertPresence": [
      "ExpertPresenceAway",
      "ExpertPresenceExtendedAway",
      "ExpertPresenceOffShift",
      "ExpertPresenceOnCall",
      "ExpertPresenceOnShift",
      "ExpertPresenceOnStandby"
    ],
    "ExpertRecommendationRecord": [
      "ExpertRecommendationAssistantFirst",
      "ExpertRecommendationByLeastWorkloadRecord",
      "ExpertRecommendationByPerformanceWeightRecord"
    ],
    "ExpertSavedLeadSearchError": [
      "ExpertSavedLeadSearchNoFiltersError",
      "ExpertSavedLeadSearchSameNameError",
      "ExpertSavedLeadSearchUnknownError"
    ],
    "ExpertSystemTaskDetail": [
      "ExpertSystemTaskSurveyDetail"
    ],
    "ExpertTagError": [
      "MaximumLeadsWithTagExceededError",
      "MaximumTagExceededError",
      "TagAlreadyPresentError",
      "TagUnknownError"
    ],
    "ExpertUpdateReferrerError": [
      "ReferrerAlreadyPaidExpertEditError",
      "ReferrerAlreadySetExpertEditError"
    ],
    "ExternalInventoryItemSource": [
      "ShopifyExternalInventoryItemSource"
    ],
    "ExternalProfile": [
      "FacebookMessengerProfile",
      "PiplProfile"
    ],
    "ExternalVideoDetail": [
      "YouTubeVideoExternalVideoDetail"
    ],
    "FacetImageMetadata": [
      "BackgroundFacetImageMetadata",
      "IconFacetImageMetadata"
    ],
    "FedExRateCardLineItem": [
      "FedExExpressRateCardLineItem",
      "FedExGroundRateCardLineItem"
    ],
    "FeedAuthor": [
      "FeedAuthorConsumer",
      "FeedAuthorExpert"
    ],
    "FeedCommentOrError": [
      "FeedComment",
      "NoSuchFeedItemError"
    ],
    "FeedContentBlock": [
      "AttributesFeedContentBlock",
      "ImageFeedContentBlock",
      "OEmbedFeedContentBlock",
      "OpenGraphFeedContentBlock",
      "ProductFeedContentBlock",
      "TextFeedContentBlock",
      "VideoFeedContentBlock"
    ],
    "FeedItem": [
      "FeedComment",
      "FeedPost"
    ],
    "FeedItemOrError": [
      "FeedComment",
      "FeedPost",
      "NoSuchFeedItemError"
    ],
    "FeedItemRemoved": [
      "FeedItemRemovedByAdmin",
      "FeedItemRemovedBySystem",
      "FeedItemRemovedByUser"
    ],
    "FeedItemSource": [
      "ManualFeedItemSource",
      "PathFeedItemSource"
    ],
    "FeedPostOrError": [
      "FeedPost",
      "NoSuchFeedItemError"
    ],
    "FeedRankingAdjustment": [
      "FeedRankingCancellingAdjustment",
      "FeedRankingPinningAdjustment",
      "FeedRankingScoreAdjustment"
    ],
    "FeedReactionAddError": [
      "InvalidTargetFeedReactionAddError"
    ],
    "FeedReactionSummaryItemOrError": [
      "FeedReactionSummaryItem",
      "NoSuchFeedItemError"
    ],
    "FilterCondition": [
      "FilterFieldCondition",
      "FilterLogicalCondition"
    ],
    "FilterFieldDefinition": [
      "FilterAttributeFieldDefinition",
      "FilterBooleanFieldDefinition",
      "FilterDateRangeFieldDefinition",
      "FilterGeoPointFieldDefinition",
      "FilterIdFieldDefinition",
      "FilterNumberRangeFieldDefinition",
      "FilterStringFieldDefinition",
      "LeadTouchFilterFieldDefinition"
    ],
    "FilterFieldValue": [
      "FilterFieldAttributeValue",
      "FilterFieldBooleanValue",
      "FilterFieldDateRangeValue",
      "FilterFieldGeoSearchValue",
      "FilterFieldIdValue",
      "FilterFieldNumberRangeValue",
      "FilterFieldRelativeDateRangeValue",
      "FilterFieldStringValue",
      "LeadTouchFilterFieldValue"
    ],
    "ForYouFeedItem": [
      "ForYouFeedPost"
    ],
    "FulfillmentChannelWriter": [
      "Operator",
      "SystemUser"
    ],
    "FulfillmentOrderSource": [
      "PartnerFulfillmentOrderSource",
      "ShopifyFulfillmentOrderSource"
    ],
    "FulfillmentRequestProcessingError": [
      "DuplicateInvoiceFulfillmentRequestProcessingError",
      "UnableToCreateAttachmentFulfillmentRequestProcessingError",
      "UnableToPlaceOrder"
    ],
    "FulfillmentRequestUser": [
      "Operator",
      "PartnerUser",
      "SystemUser",
      "User"
    ],
    "FulfillmentSource": [
      "AdHocFulfillmentSource",
      "PartnerFulfillmentSource"
    ],
    "GeoSearchValue": [
      "GeoZipCodeRadius"
    ],
    "GetFedExTransitTimesError": [
      "GetFedExTransitTimesFedExApiErrorError",
      "GetFedExTransitTimesInvalidZipCodeError",
      "GetFedExTransitTimesNoProductInventoryPropertiesFoundError",
      "GetFedExTransitTimesNotFoundError",
      "GetFedExTransitTimesNotSupportedPackageTypeError",
      "GetFedExTransitTimesNotSupportedShipperZipCodeError"
    ],
    "GetUpsRateAndTransitTimesError": [
      "GetUpsRateAndTransitTimesBadRequestError",
      "GetUpsRateAndTransitTimesFailureError",
      "GetUpsRateAndTransitTimesNoInventoryPropertiesFoundError",
      "GetUpsRateAndTransitTimesNoPackageProvidedError",
      "GetUpsRateAndTransitTimesServiceUnavailableError",
      "GetUpsRateAndTransitTimesUnauthorizedError"
    ],
    "GetUpsTransitTimesError": [
      "GetUpsTransitTimesInvalidZipCodeError",
      "GetUpsTransitTimesNoProductInventoryPropertiesFoundError",
      "GetUpsTransitTimesNotFoundError",
      "GetUpsTransitTimesNotSupportedPackageTypeError",
      "GetUpsTransitTimesNotSupportedShipperZipCodeError",
      "GetUpsTransitTimesUpsApiErrorError"
    ],
    "GigApplicationDetail": [
      "ArticleApplicationDetail",
      "ProductReviewApplicationDetail"
    ],
    "GigDependency": [
      "DemoProductDeliveryGigDependency",
      "DemoProductReturnGigDependency",
      "ProductVideoProcessDependency"
    ],
    "GigPublishEntity": [
      "ArticleRevision",
      "Sellable"
    ],
    "GigRequestDetail": [
      "ArticleRequestDetail",
      "AuthorQualificationRequestDetail",
      "CurationQualityRequestDetail",
      "DeepDiveRequestDetail",
      "ProductAttributeReviewRequestDetail",
      "ProductDescriptionRequestDetail",
      "ProductQuickReviewRequestDetail",
      "ProductReviewGroupRequestDetail",
      "ProductReviewRequestDetail",
      "QuestionAndAnswerRequestDetail",
      "SellableEditorialTopListProductQuickReviewRequestDetail",
      "SellableEditorialTopListRequestDetail",
      "SellableEditorialTopListV2RequestDetail",
      "SellableRelevanceRequestDetail",
      "ShortProductVideoRequestDetail",
      "VersusRequestDetail"
    ],
    "GigSubmissionActivity": [
      "AuthorPaidActivity",
      "DerivedGigAssignedActivity",
      "EditorAssignedActivity",
      "EditorUnassignedActivity",
      "RatingChangedActivity",
      "StatusChangedActivity"
    ],
    "GigSubmissionDependencyTarget": [
      "DemoProductRequest"
    ],
    "GigSubmissionPublisher": [
      "BusinessUser",
      "Operator",
      "SystemUser"
    ],
    "GigSubmissionRevisionDetail": [
      "ArticleSubmissionRevisionDetail",
      "AuthorQualificationSubmissionRevisionDetail",
      "CurationQualitySubmissionRevisionDetail",
      "DeepDiveSubmissionRevisionDetail",
      "ProductAttributeReviewSubmissionRevisionDetail",
      "ProductDescriptionSubmissionRevisionDetail",
      "ProductQuickReviewSubmissionRevisionDetail",
      "ProductReviewGroupSubmissionRevisionDetail",
      "ProductReviewSubmissionRevisionDetail",
      "QuestionAndAnswerSubmissionRevisionDetail",
      "SellableEditorialTopListProductQuickReviewSubmissionRevisionDetail",
      "SellableEditorialTopListSubmissionRevisionDetail",
      "SellableEditorialTopListV2SubmissionRevisionDetail",
      "SellableRelevanceSubmissionRevisionDetail",
      "ShortProductVideoSubmissionRevisionDetail",
      "VersusSubmissionRevisionDetail"
    ],
    "GiveFeedbackOnPurchaseOrderRevisionError": [
      "GiveFeedbackOnPurchaseOrderRevisionNoteOrShipWindowMustBeSetError",
      "PurchaseOrderRevisionEditNotInAwaitingAcknowledgementStateError"
    ],
    "GiveFeedbackOnPurchaseOrderRevisionLineItemError": [
      "GiveFeedbackOnPurchaseOrderRevisionLineItemQuantityExceedLimitError",
      "PurchaseOrderRevisionEditNotInAwaitingAcknowledgementStateError"
    ],
    "GradeFinalEvalError": [
      "ExpertApplicationUpdateFailure",
      "InvalidExpertApplicationState",
      "NoFinalEvalShiftForExpertApplication"
    ],
    "GrantIncentiveActionPathNodeConfigIncentive": [
      "GrantIncentiveActionPathNodeConfigConsumerRewardCreditIncentive",
      "GrantIncentiveActionPathNodeConfigPromotionIncentive"
    ],
    "GrantIncentiveErrorPathNodeFlowViewModel": [
      "GrantIncentiveAlreadyGrantedErrorPathNodeFlowViewModel",
      "GrantIncentiveInternalErrorPathNodeFlowViewModel",
      "GrantIncentiveNotEligibleErrorPathNodeFlowViewModel"
    ],
    "GrantIncentiveSuccessPathNodeFlowViewModel": [
      "GrantIncentiveConsumerRewardCreditSuccessPathNodeFlowViewModel",
      "GrantIncentiveGrantPromotionSuccessPathNodeFlowViewModel"
    ],
    "GrantMembershipToConsumerError": [
      "MembershipRenewalNotSupportedError"
    ],
    "GrantOrderAppeasementCreditError": [
      "DefaultErrorMessage"
    ],
    "GroupedOrderLineItems": [
      "TrackingGroupedOrderLineItems"
    ],
    "Identity": [
      "BusinessUser",
      "Consumer",
      "Guest",
      "ImportedIdentity",
      "Operator",
      "PartnerUser",
      "SystemUser",
      "User"
    ],
    "ImportPurchaseRequestLineItemsError": [
      "ImportPurchaseRequestLineItemsGenericError",
      "ImportPurchaseRequestLineItemsNoCatalogError"
    ],
    "IndividualSellableProCon": [
      "IndividualSellableAttributeProCon",
      "IndividualSellableCustomProCon"
    ],
    "InitiateLeadTransferRequestError": [
      "AlreadyExistsInitiateLeadTransferRequestError",
      "DifferentDepartmentInitiateLeadTransferRequestError",
      "NotAssignedExpertInitiateLeadTransferRequestError",
      "OtherReasonEmptyNoteInitiateLeadTransferRequestError",
      "ReassignToSameExpertInitiateLeadTransferRequestError",
      "SameSourceTargetExpertInitiateLeadTransferRequestError",
      "SourceExpertNotActiveInitiateLeadTransferRequestError",
      "TargetExpertNotActiveInitiateLeadTransferRequestError"
    ],
    "InjectAttributesActionPathNodeConfigSource": [
      "InjectAttributesActionPathNodeConfigAttributeEntityReferenceSource",
      "InjectAttributesActionPathNodeConfigAttributeValuesSource"
    ],
    "InputTextPathQuestionSchemaValidationViewModel": [
      "InputTextPathQuestionSchemaLengthValidationViewModel",
      "InputTextPathQuestionSchemaRegexValidationViewModel"
    ],
    "InsertExperimentRevisionMetricError": [
      "DefaultErrorMessage"
    ],
    "InsertExperimentRevisionRuleError": [
      "DefaultErrorMessage"
    ],
    "InternalOrderLineItemSource": [
      "InternalOrderLineItemSellableSource"
    ],
    "InternalOrderMeta": [
      "ConsignmentOrderMeta",
      "InventoryTransferOrderMeta",
      "LiquidationOrderMeta",
      "MarketingContentOrderMeta"
    ],
    "InterstitialPathNodeFlowViewModelResult": [
      "ImageBlocksInterstitialPathNodeFlowViewModel"
    ],
    "InterstitialProgressionPathNodeFlowViewModel": [
      "ContinueButtonInterstitialProgressionPathNodeFlowViewModel",
      "TimedContinueInterstitialProgressionPathNodeFlowViewModel"
    ],
    "InventoryAdjustmentFlowContent": [
      "InventoryGenericInboundAdjustmentFlowContent",
      "InventoryGenericOutboundAdjustmentFlowContent"
    ],
    "InventoryFlowContent": [
      "InventoryFlexeInboundFlowContent",
      "InventoryFlexeReturnFlowContent",
      "InventoryGenericInboundAdjustmentFlowContent",
      "InventoryGenericInboundFlowContent",
      "InventoryGenericInvoiceFlowContent",
      "InventoryGenericOutboundAdjustmentFlowContent",
      "InventoryGenericReturnFlowContent",
      "InventoryHollingsworthOutboundFlowContent",
      "InventoryW2GOutboundFlowContent"
    ],
    "InventoryInboundFlowContent": [
      "InventoryFlexeReturnFlowContent",
      "InventoryGenericInboundAdjustmentFlowContent",
      "InventoryGenericReturnFlowContent"
    ],
    "InventoryOutboundFlowContent": [
      "InventoryGenericOutboundAdjustmentFlowContent",
      "InventoryHollingsworthOutboundFlowContent",
      "InventoryW2GOutboundFlowContent"
    ],
    "InventoryPurchaseInboundFlowContent": [
      "InventoryFlexeInboundFlowContent",
      "InventoryGenericInboundFlowContent"
    ],
    "InventoryPurchaseInvoiceFlowContent": [
      "InventoryGenericInvoiceFlowContent"
    ],
    "InviteNewPartnerError": [
      "DefaultErrorMessage"
    ],
    "InvoiceReconciliationSource": [
      "InvoiceReconciliationFlxpointSource",
      "InvoiceReconciliationManualSource",
      "InvoiceReconciliationPartnerHubSource"
    ],
    "LeadActivity": [
      "ConsumerCalledActivity",
      "ConsumerFirstMessageSentActivity",
      "ConsumerReviewAddedActivity",
      "DelegateExpertChangedActivity",
      "ExpertCalledActivity",
      "ExpertRequestedReferralActivity",
      "LeadArchivedActivity",
      "LeadConnectPartnerOrderActivity",
      "LeadCreatedActivity",
      "LeadEscalationChangedActivity",
      "LeadEscalationStartedActivity",
      "LeadGiftCardRedeemedActivity",
      "LeadOrderPaidActivity",
      "LeadPaymentFailedActivity",
      "LeadPaymentSucceededActivity",
      "LeadPhoneCallEndedActivity",
      "LeadReassignedActivity",
      "LeadRequestSubmittedActivity",
      "LeadStopRequestedActivity",
      "NoteAddedActivity",
      "PostPurchaseQualityConversationEstablishedActivity",
      "QualityConversationEstablishedActivity",
      "QualityConvoWithCurationEstablishedActivity",
      "ReactivationQualityConversationEstablishedActivity",
      "ReturningUserConnectionCreatedActivity",
      "SnoozeChangedActivity",
      "WokeUpFromSnoozeActivity"
    ],
    "LeadChecklistRequirement": [
      "AboutCuratedRequirement",
      "AskQuestionRequirement",
      "ConfirmBudgetRequirement",
      "OfferPhoneCallRequirement",
      "PhoneCallRequirement"
    ],
    "LeadConversationStarterContent": [
      "LeadConversationStarterFreeFormQuestionContent",
      "LeadConversationStarterPredefinedQuestionContent"
    ],
    "LeadDestinationPage": [
      "LeadDestinationCategoryPage",
      "LeadDestinationDepartmentPage",
      "LeadDestinationInboxPage",
      "LeadDestinationSellablePage",
      "LeadDestinationTopListPage"
    ],
    "LeadSnoozeBulkSetError": [
      "LeadBulkActionInvalidSnooze",
      "LeadBulkActionUnsupportedBulkSizeError"
    ],
    "LeadSourceFlow": [
      "LeadSourceAiGuestChatFlow",
      "LeadSourceConversationStarterFlow",
      "LeadSourceDirectExpertChatFlow",
      "LeadSourceExpertIntroFlow",
      "LeadSourceFeedProductRecommendationSourceFlow",
      "LeadSourceGuestChatFlow",
      "LeadSourceIncomingCallOrMessageFlow",
      "LeadSourcePathFlow",
      "LeadSourcePostOrderCheckinFlow",
      "LeadSourceRegistrationFlow",
      "LeadSourceSelfCheckoutExpertSelectionFlow"
    ],
    "LeadSourcePage": [
      "LeadSourceArticlePage",
      "LeadSourceDepartmentPage",
      "LeadSourceExpertProfilePage",
      "LeadSourceExpertisePage",
      "LeadSourceGiftCardRedeemPage",
      "LeadSourceInfluencerProfilePage",
      "LeadSourceOrderPage",
      "LeadSourceProductAnswerPage",
      "LeadSourceProductQuickReviewPage",
      "LeadSourcePublisherPage",
      "LeadSourceScoutPage",
      "LeadSourceSellableEditorialTopListPage",
      "LeadSourceSellablePage",
      "LeadSourceSellableSearchPage",
      "LeadSourceSellableTopListPage"
    ],
    "LedgerAccountOwner": [
      "BusinessUser",
      "User"
    ],
    "LedgerAuthorizeAndPostError": [
      "LedgerTransactionDuplicatedError",
      "LedgerTransactionInsufficientBalanceError",
      "LedgerTransactionInvalidAmountError"
    ],
    "LedgerTransactionDetails": [
      "LedgerTransactionAuthorizeAndPostDetails",
      "LedgerTransactionAuthorizeDetails",
      "LedgerTransactionCancelAuthorizationDetails",
      "LedgerTransactionExtendAuthorizationDetails",
      "LedgerTransactionPostDetails",
      "LedgerTransferDetails"
    ],
    "LinkedPathNodeEditorViewModelEntity": [
      "PathSchemaFragmentRevision",
      "PathSchemaRevision"
    ],
    "LookupReferralRewardCreditError": [
      "UsageLimitExceededRewardCreditClaimError"
    ],
    "MediaAssetError": [
      "MediaAssetMediaNotExistError"
    ],
    "MediaUploadFormatPathQuestionPathNodeFlowViewModel": [
      "VideoMediaUploadFormatPathQuestionPathNodeFlowViewModel"
    ],
    "MediaUploadPathQuestionSchemaFormat": [
      "MediaUploadPathQuestionSchemaVideoFormat"
    ],
    "MerchantOrder": [
      "FlexeOrder",
      "FlxpointOrder",
      "HollingsworthOrder",
      "ManualMerchantOrder",
      "PartnerOrder",
      "WarehouseOrder"
    ],
    "MerchantReturnError": [
      "AlreadyInProgressMerchantReturnError",
      "ExistInvalidPartnerOrderReturnsMerchantReturnError",
      "InvalidDistributionCenterInfoError",
      "InvalidTrackingMerchantReturnError",
      "LabelRequestFailedMerchantReturnError",
      "MerchantOrderNotFoundMerchantReturnError",
      "MissingLabelDataMerchantReturnError",
      "NotCatalogSourceMerchantReturnError",
      "ParcelsTooLargeMerchantReturnError",
      "ShippingUpdateForbiddenMerchantReturnError",
      "TrackingAlreadyExistsMerchantReturnError"
    ],
    "MessageRichCard": [
      "ConsumerFeedbackOnCurationRichCard",
      "ContactCollectionRichCard",
      "ConversationStarterRichCard",
      "CreateWishlistRichCard",
      "DelegateExpertJoinedRichCard",
      "DelegateExpertLeftRichCard",
      "ExpertAffiliatePromotionRichCard",
      "ExpertAwayAutoReplyRichCard",
      "ExpertCuratedSearchRichCard",
      "ExpertFeedbackRequestRichCard",
      "ExpertJoinedRichCard",
      "ExpertReferralRequestRichCard",
      "FittingInvitationForConsumerRichCard",
      "GuestChatCreateRequestRichCard",
      "HowCuratedWorksRichCard",
      "InterestedInCuratedOrderRichCard",
      "MembershipOfferRichCard",
      "NewBookableCuratedOrderRichCard",
      "NewCuratedItemsRichCard",
      "NewCuratedOrderListRichCard",
      "NewCustomCuratedOrderRichCard",
      "NewUserMilestoneRichCard",
      "PathRequestedByExpertRichCard",
      "PaymentVerificationRequestRichCard",
      "PriceMatchRequestRichCard",
      "ProductRecommendationsWithExplanationRichCard",
      "RequestConfirmationRichCard",
      "RequestExpertFeedbackOnSellableRichCard",
      "ReturningUserConnectionOfferedRichCard",
      "SellableRequestRichCard",
      "SimpleCuratedOrderRichCard",
      "StartRequestPathRichCard",
      "SystemCuratedItemSurveyRichCard",
      "SystemCuratedItemsRichCard",
      "UpdatedCuratedItemsRichCard"
    ],
    "MessageTemplate": [
      "EmailTemplateRevision",
      "MultiLocaleTemplateSet"
    ],
    "MessagingUserProfile": [
      "BusinessUser",
      "InternalMessagingUser",
      "Merchant",
      "OnboardingSpecialistUser",
      "Operator",
      "SupportMessagingUser",
      "User"
    ],
    "MilestonePayAction": [
      "MilestoneFixedAmountPayAction",
      "MilestoneTotalRevenuePercentagePayAction"
    ],
    "MilestonePayTrackingSource": [
      "BusinessUser",
      "Lead"
    ],
    "MoveExperimentRevisionMetricError": [
      "DefaultErrorMessage"
    ],
    "MoveExperimentRevisionRuleError": [
      "DefaultErrorMessage"
    ],
    "NavigatePathGraphFlowError": [
      "AlreadyCompletedNavigatePathGraphFlowError",
      "BacktrackingNotAllowedNavigatePathGraphFlowError",
      "NodeNotTraversedNavigatePathGraphFlowError"
    ],
    "NotAvailableItemAddSellableWishlistItemToOrder": [
      "Sellable",
      "WishlistProductBundleSellableSelection"
    ],
    "NoteApprovalChangeByUnion": [
      "BusinessUser",
      "Operator",
      "SystemUser"
    ],
    "NumericPathQuestionSchemaRenderingStyle": [
      "NumericPathQuestionSchemaGenericRenderingStyle",
      "NumericPathQuestionSchemaLengthInInchesRenderingStyle",
      "NumericPathQuestionSchemaStarRatingRenderingStyle"
    ],
    "NumericRenderingStylePathQuestionPathNodeFlowViewModel": [
      "GenericNumericRenderingStylePathQuestionPathNodeFlowViewModel",
      "LengthInInchesNumericRenderingStylePathQuestionPathNodeFlowViewModel",
      "StarRatingNumericRenderingStylePathQuestionPathNodeFlowViewModel"
    ],
    "NumericValidationQuestionPathNodeFlowViewModel": [
      "InRangeNumericValidationQuestionPathNodeFlowViewModel"
    ],
    "OEmbedContent": [
      "OEmbedLink",
      "OEmbedPhoto",
      "OEmbedRich",
      "OEmbedVideo"
    ],
    "OrderActivityDetail": [
      "ConfirmedReturnShippingLabelSentActivity",
      "DealInventoryClaimedActivity",
      "DealInventoryFulfillmentOrderCanceledActivity",
      "DealInventoryRequestCanceledActivity",
      "DealInventoryRequestFailedActivity",
      "DealInventoryRequestedActivity",
      "EscalationClosedActivity",
      "FulfillmentProcessUpdatedActivity",
      "ItemReturnReceivedActivity",
      "ItemReturnRequestedActivity",
      "ItemReturnShippedActivity",
      "MerchantClaimedActivity",
      "MerchantRefundUpdatedActivity",
      "OperatorAssignedActivity",
      "OrderAddressChangedActivity",
      "OrderEscalatedActivity",
      "OrderExpertContributionChangedActivity",
      "OrderItemReceivedActivity",
      "OrderItemShippedActivity",
      "OrderShipmentStatusUpdateActivity",
      "OrderVerificationActivity",
      "RefundAmountAdjustedActivity",
      "RefundRequestedActivity",
      "RefundStatusUpdateActivity",
      "ReturnDestinationChangedActivity",
      "ReturnShipmentTrackingRemovedActivity",
      "ReturnShipmentTrackingUpdatedActivity",
      "ShipmentTrackingRemovedActivity",
      "ShipmentTrackingUpdatedActivity",
      "ShippingDelayedNotificationActivity"
    ],
    "OrderChangeRequest": [
      "OrderCancellationRequest",
      "OrderPriceChangeRequest",
      "OrderReturnRequest"
    ],
    "OrderLineItemNotEligibleForPromotionReason": [
      "CustomSourceItemNotEligibleForPromotionReason",
      "ItemCountNotEligibleForPromotionReason",
      "ParentSourceTypeNotEligibleForPromotionReason",
      "SellableAttributeFilterNotEligibleForPromotionReason",
      "SellableBadgeNotEligibleForPromotionReason",
      "SellableBrandFilterNotEligibleForPromotionReason",
      "SellableCategoryFilterNotEligibleForPromotionReason",
      "SellableFilterNotEligibleForPromotionReason",
      "SellableFulfillmentChannelNotEligibleForPromotionReason",
      "SellableFullPriceNotEligibleForPromotionReason",
      "SourceTypeNotEligibleForPromotionReason"
    ],
    "OrderLineItemSource": [
      "OrderLineItemAdHocSource",
      "OrderLineItemBasePriceSource",
      "OrderLineItemCourtesyCreditSource",
      "OrderLineItemCustomizationAddOnSource",
      "OrderLineItemCustomizationAttributeSource",
      "OrderLineItemExpertServiceSource",
      "OrderLineItemExpertTipSource",
      "OrderLineItemGiftCardSource",
      "OrderLineItemGratuitySource",
      "OrderLineItemMembershipSource",
      "OrderLineItemPrepaidIncidentalSource",
      "OrderLineItemProductBundleSource",
      "OrderLineItemRefundSource",
      "OrderLineItemReturnFeeSource",
      "OrderLineItemRewardCreditClawbackSource",
      "OrderLineItemSellableSource",
      "OrderLineItemShipmentSource",
      "OrderLineItemTaxAdjustmentCreditSource",
      "OrderLineItemTrialFeeSource",
      "OrderLineItemWarrantySource"
    ],
    "OrderNotEligibleForPromotionReason": [
      "InternalUserOnlyNotEligibleForPromotionReason",
      "OrderAmountNotEligibleForPromotionReason",
      "PurchasedOrderCountNotEligibleForPromotionReason",
      "UserUsageLimitNotEligibleForPromotionReason"
    ],
    "OrderUserMessage": [
      "AdHocSellableNotAvailableOrderUserMessage",
      "AddonSellableQuantityOrderUserMessage",
      "DefaultOrderUserMessage",
      "SellableCustomizationNotAvailableOrderUserMessage",
      "SellableLimitedQuantityOrderUserMessage",
      "SellableNotAvailableOrderUserMessage",
      "WishlistArchivedGiftCardOrderUserMessage",
      "WishlistArchivedSellableOrderUserMessage",
      "WishlistItemArchivedGiftCardOrderUserMessage",
      "WishlistItemArchivedSellableOrderUserMessage",
      "WishlistItemDeletedDuePurchasedSellableOrderUserMessage",
      "WishlistItemQuantityChangedDuePurchasedSellableOrderUserMessage",
      "WishlistPrivateGiftCardOrderUserMessage",
      "WishlistPrivateSellableOrderUserMessage"
    ],
    "PageTemplateBlock": [
      "PageTemplateComponentBlock",
      "PageTemplateModuleBlock"
    ],
    "PageTemplateComponentFieldValue": [
      "PageTemplateComponentFieldValueAnchorList",
      "PageTemplateComponentFieldValueBoolean",
      "PageTemplateComponentFieldValueButton",
      "PageTemplateComponentFieldValueCompositeList",
      "PageTemplateComponentFieldValueEmpty",
      "PageTemplateComponentFieldValueEntryList",
      "PageTemplateComponentFieldValueEnum",
      "PageTemplateComponentFieldValueHref",
      "PageTemplateComponentFieldValueImage",
      "PageTemplateComponentFieldValueImageList",
      "PageTemplateComponentFieldValueMarkdown",
      "PageTemplateComponentFieldValueNumber",
      "PageTemplateComponentFieldValueText",
      "PageTemplateComponentFieldValueVideo"
    ],
    "PageTemplateUsage": [
      "ContentSelectionRuleRevisionPageTemplateUsage",
      "ContentSelectionToolRevisionPageTemplateUsage"
    ],
    "PartnerInventoryDataSource": [
      "PartnerInventoryFlxpointDataSource"
    ],
    "PartnerOrderAlertContent": [
      "PartnerOrderAlertLateAcknowledgeContent",
      "PartnerOrderAlertLateShipmentContent",
      "PartnerOrderAlertTrackingNotUpdatedContent"
    ],
    "PartnerOrderCreditMemoDetail": [
      "PartnerOrderReturnCreditMemoDetail"
    ],
    "PartnerOrderCreditMemoError": [
      "GenericPartnerOrderCreditMemoError"
    ],
    "PartnerOrderError": [
      "GenericPartnerOrderError"
    ],
    "PartnerOrderReturnError": [
      "AlreadyInProgressPartnerOrderReturnError",
      "EmptyLineItemsPartnerOrderReturnError",
      "InsufficientQuantityPartnerOrderReturnError",
      "InvalidStatePartnerOrderReturnError",
      "InvalidTrackingPartnerOrderReturnError",
      "LineItemNotFoundPartnerOrderReturnError",
      "ShipmentNotFoundPartnerOrderReturnError",
      "TrackingAlreadyExistsPartnerOrderReturnError"
    ],
    "PartnerShipment": [
      "PartnerManualShipment",
      "PartnerManualTrackShipment",
      "PartnerUpsShipment"
    ],
    "PathAdmonitionPathNodeFlowViewModel": [
      "TextPathAdmonitionPathNodeFlowViewModel"
    ],
    "PathAdmonitionSchema": [
      "TextPathAdmonitionSchema"
    ],
    "PathAttributeValue": [
      "PathStaticAttributeValue"
    ],
    "PathCondition": [
      "PathConditionComparison",
      "PathConditionLogical"
    ],
    "PathConditionEnumDeclaration": [
      "PathConditionAttributeEnumDeclaration",
      "PathConditionStaticEnumDeclaration"
    ],
    "PathConditionOperand": [
      "PathConditionAttributeOperand",
      "PathConditionExperimentOperand",
      "PathConditionLiteralBigDecimalOperand",
      "PathConditionLiteralBigDecimalRangeOperand",
      "PathConditionLiteralEnumOperand",
      "PathConditionLiteralLongOperand",
      "PathConditionLiteralLongRangeOperand",
      "PathConditionLiteralStringOperand",
      "PathConditionOperandRepositoryEntry"
    ],
    "PathCopyTokenResultOrError": [
      "InvalidSourceNodePathCopyTokenError",
      "PathCopyTokenResult"
    ],
    "PathEditorRichTextContentPart": [
      "NewLinePathEditorRichTextContentPart",
      "TextPathEditorRichTextContentPart"
    ],
    "PathEditorValidationErrorContainer": [
      "RichPathGraph",
      "RichPathNode"
    ],
    "PathEditorValidationErrorTarget": [
      "BranchRulePathEditorValidationErrorTarget",
      "ListQuestionChoicePathEditorValidationErrorTarget",
      "PortPathEditorValidationErrorTarget",
      "QuestionPathEditorValidationErrorTarget"
    ],
    "PathGraphAuthor": [
      "ImportedIdentity",
      "Operator",
      "SystemUser"
    ],
    "PathGraphContainer": [
      "PathSchemaFragmentRevision",
      "PathSchemaRevision"
    ],
    "PathGraphFlowRequestEvent": [
      "EntityCreatedPathGraphFlowRequestEvent",
      "PathCompletedPathGraphFlowRequestEvent"
    ],
    "PathInterstitialSchema": [
      "ImageTextBlocksPathInterstitialSchema"
    ],
    "PathLayoutSchema": [
      "PathDefaultLayoutSchema",
      "PathInlineLayoutSchema"
    ],
    "PathNodeConfig": [
      "BranchContainerPathNodeConfig",
      "BranchFallbackRulePathNodeConfig",
      "BranchRulePathNodeConfig",
      "CalculateActionPathNodeConfig",
      "CalculationResultPathNodeConfig",
      "CreateAccountActionPathNodeConfig",
      "CreateConsumerExpertReviewActionPathNodeConfig",
      "CreateExpertApplicationActionPathNodeConfig",
      "CreateExpertApplicationDraftActionPathNodeConfig",
      "CreateExpertKnowledgeTestActionPathNodeConfig",
      "CreateFeedPostActionPathNodeConfig",
      "CreateRequestLeadActionPathNodeConfig",
      "CreateSellableExpertiseRequestActionPathNodeConfig",
      "CreateSurveyResponseActionPathNodeConfig",
      "CreateUserMilestoneActionPathNodeConfig",
      "EndPathNodeConfig",
      "GrantIncentiveActionPathNodeConfig",
      "InjectAttributesActionPathNodeConfig",
      "InterstitialPathNodeConfig",
      "PathSchemaFragmentEndPathNodeConfig",
      "PathSchemaFragmentPathNodeConfig",
      "PathSchemaFragmentReferencePathNodeConfig",
      "PathSchemaPathNodeConfig",
      "PathSchemaReferencePathNodeConfig",
      "PersistentAdmonitionPathNodeConfig",
      "ProductRecommendationPathCompletedActionPathNodeConfig",
      "QuestionsPathNodeConfig",
      "RedirectPathNodeConfig",
      "RequestExpertAccountChangeActionPathNodeConfig",
      "RequireAuthenticationActionPathNodeConfig",
      "SetMarketingSmsPreferenceActionPathNodeConfig",
      "StartPathNodeConfig",
      "SwitchLayoutPathNodeConfig",
      "TriggerEventActionPathNodeConfig",
      "UserRegistrationPathNodeConfig"
    ],
    "PathNodeDynamicExpanderState": [
      "PathSchemaFragmentReferencePathNodeDynamicExpanderState",
      "PathSchemaReferencePathNodeDynamicExpanderState"
    ],
    "PathNodeEditorViewModel": [
      "ConditionPathNodeEditorViewModel",
      "DefaultPathNodeEditorViewModel",
      "LinkedPathNodeEditorViewModel"
    ],
    "PathNodeFlowViewModel": [
      "CalculationResultPathNodeFlowViewModel",
      "CreateAccountPathNodeFlowViewModel",
      "DryRunInfoPathNodeFlowViewModel",
      "ErrorPathNodeFlowViewModel",
      "GrantIncentivePathNodeFlowViewModel",
      "InterstitialPathNodeFlowViewModel",
      "QuestionsPathNodeFlowViewModel",
      "RedirectPathNodeFlowViewModel",
      "RequireAuthenticationPathNodeFlowViewModel",
      "UserRegistrationPathNodeFlowViewModel"
    ],
    "PathQuestionSchema": [
      "DatePathQuestionSchema",
      "InputTextPathQuestionSchema",
      "ListPathQuestionSchema",
      "MediaUploadPathQuestionSchema",
      "NumericPathQuestionSchema"
    ],
    "PathQuestionsDisplayComponentSchema": [
      "TcpaCompliancePathQuestionsDisplayComponentSchema",
      "TermsAndConditionsPathQuestionsDisplayComponentSchema"
    ],
    "PathSelection": [
      "ExperimentPathSelection",
      "SinglePathSelection"
    ],
    "PayComponentRule": [
      "PayComponentCategoryCommissionRule",
      "PayComponentCommissionRule",
      "PayComponentExpertReferralAccumulativeSalesRule",
      "PayComponentExpertReferralFirstSaleRule",
      "PayComponentExpertServiceRule",
      "PayComponentFeedProductCommissionRule",
      "PayComponentFirstShiftsGuaranteedRule",
      "PayComponentHourlyOnCallRule",
      "PayComponentMarginCommissionRule",
      "PayComponentQualityConversationRule",
      "PayComponentReferralFirstOrderRule",
      "PayComponentTeamLeadCommissionRule",
      "PayComponentTipRule"
    ],
    "PayComponentSummary": [
      "PayComponentCategoryCommissionSummary",
      "PayComponentCommissionSummary",
      "PayComponentCurationRecordingSummary",
      "PayComponentDebitSummary",
      "PayComponentEngagementPaySummary",
      "PayComponentExpertReferralAccumulativeSalesSummary",
      "PayComponentExpertReferralFirstSaleSummary",
      "PayComponentExpertServiceSummary",
      "PayComponentFeedProductCommissionSummary",
      "PayComponentFinalEvalSummary",
      "PayComponentFirstShiftsGuaranteedSummary",
      "PayComponentGigSummary",
      "PayComponentHourlyOnCallSummary",
      "PayComponentManualAdjustmentSummary",
      "PayComponentMarginCommissionSummary",
      "PayComponentMilestonePaymentSummary",
      "PayComponentPreviousBalanceSummary",
      "PayComponentQualityConversationSummary",
      "PayComponentReferralFirstOrderSummary",
      "PayComponentRevenueBonusSummary",
      "PayComponentTeamLeadCommissionSummary",
      "PayComponentTipSummary"
    ],
    "PayGigError": [
      "AlreadyPaidPayGigError",
      "PaymentBlockedPayGigError"
    ],
    "PayLineItemCommissionSourceOrder": [
      "ConnectPartnerTransaction",
      "Order"
    ],
    "PayLineItemSource": [
      "PayLineItemCategoryCommissionSource",
      "PayLineItemCommissionSource",
      "PayLineItemCurationVideoRecordingSource",
      "PayLineItemDebitSource",
      "PayLineItemEngagementPaySource",
      "PayLineItemExpertReferralAccumulativeSalesSource",
      "PayLineItemExpertReferralFirstSaleSource",
      "PayLineItemExpertServiceSource",
      "PayLineItemFeedProductCommissionSource",
      "PayLineItemFinalEvalSource",
      "PayLineItemFirstShiftsGuaranteedSource",
      "PayLineItemGigSource",
      "PayLineItemHourlyOnCallSource",
      "PayLineItemManualAdjustmentSource",
      "PayLineItemMarginCommissionSource",
      "PayLineItemMilestonePaymentSource",
      "PayLineItemPreviousBalanceSource",
      "PayLineItemQualityConversationSource",
      "PayLineItemReferralFirstOrderSource",
      "PayLineItemRevenueBonusSource",
      "PayLineItemTeamLeadCommissionSource",
      "PayLineItemTipSource"
    ],
    "PayableMilestoneEvent": [
      "PayableMilestoneConsumerMessageQualityConversationEvent",
      "PayableMilestoneContactLeadEvent",
      "PayableMilestoneCurationEvent",
      "PayableMilestoneCurationVideoEvent",
      "PayableMilestoneLeadEngagementPayEvent",
      "PayableMilestoneOrderEvent",
      "PayableMilestonePayoutEvent",
      "PayableMilestoneQCOrPhoneCallEvent",
      "PayableMilestoneQualityConversationEvent",
      "PayableMilestoneRankEvent",
      "PayableMilestoneShiftEvent"
    ],
    "PayableMilestoneGoal": [
      "PayableMilestoneConsumerMessageQualityConversationGoal",
      "PayableMilestoneContactLeadGoal",
      "PayableMilestoneCurationGoal",
      "PayableMilestoneCurationVideoGoal",
      "PayableMilestoneLeadEngagementPayGoal",
      "PayableMilestoneOrderGoal",
      "PayableMilestonePayoutGoal",
      "PayableMilestoneQCOrPhoneCallGoal",
      "PayableMilestoneQualityConversationGoal",
      "PayableMilestoneRankGoal",
      "PayableMilestoneShiftGoal"
    ],
    "PaymentAction": [
      "StripeRequiredPaymentAction",
      "StripeRequiredSetupAction"
    ],
    "PaymentEntity": [
      "BankCheckPaymentEntity",
      "InstallmentPaymentEntity",
      "Payment3DSecure",
      "PaymentCard",
      "PaypalPaymentEntity",
      "WireTransferPaymentEntity"
    ],
    "PaymentProcessorAccount": [
      "AffirmAccount",
      "AfterPayAccount",
      "DealPaymentAccount",
      "PaypalAccount",
      "StripeAccount"
    ],
    "PaymentSource": [
      "AffirmPaymentSource",
      "AfterpayPaymentSource",
      "DealPaymentSource",
      "PaypalPaymentSource",
      "StripePaymentSource"
    ],
    "PayrollPayee": [
      "BusinessUser"
    ],
    "PostPublishUpdateError": [
      "GigSubmissionReviewError"
    ],
    "PrepareExpertLeadCampaignForEditError": [
      "PrepareExpertLeadCampaignForEditCopyNextVersionEmailFailedError"
    ],
    "ProcessGigSubmissionProductVideoError": [
      "ProcessGigSubmissionProductVideoMediaIdDoesNotExistError",
      "ProcessGigSubmissionProductVideoNotInDraftStateError",
      "ProcessGigSubmissionProductVideoNotVideoGigError",
      "ProcessGigSubmissionProductVideoOnProcessingAlreadyError",
      "ProcessGigSubmissionProductVideoProcessFailedError"
    ],
    "ProcessProductVideoTranscriptError": [
      "ProcessProductVideoTranscriptInArchivedStateError",
      "ProcessProductVideoTranscriptMediaTranscriptDoesNotExistError",
      "ProcessProductVideoTranscriptPrimarySellableDoesNotExistError",
      "ProcessProductVideoTranscriptProcessFailedError"
    ],
    "ProductBundlePriceAdjustmentRule": [
      "ProductBundleAmountPriceAdjustmentRule",
      "ProductBundleRatePriceAdjustmentRule"
    ],
    "ProductCatalogArchiveRevisionError": [
      "InUseProductCatalogArchiveRevisionError",
      "NotLatestRevisionProductCatalogArchiveRevisionError"
    ],
    "ProductCatalogCopyRevisionAsDraftError": [
      "CopyFromDraftNotAllowedProductCatalogCopyRevisionAsDraftError"
    ],
    "ProductCatalogCreateDraftFromSellableError": [
      "AlreadyCreatedProductCatalogDraftFromSellableError",
      "NoProductIdentifierProductCatalogDraftFromSellableError",
      "ParentNotExistProductCatalogDraftFromSellableError"
    ],
    "ProductCatalogCreateDraftRevisionError": [
      "DraftExistsProductCatalogCreateDraftRevisionError"
    ],
    "ProductCatalogExportConfigCopyAsDraftError": [
      "CopyProductCatalogExportConfigDraftExistsError"
    ],
    "ProductCatalogExportConfigCreateRevisionError": [
      "CreateProductCatalogExportConfigDraftExistsError"
    ],
    "ProductCatalogExportConfigPublishError": [
      "PublishProductCatalogExportConfigNotReadyError"
    ],
    "ProductCatalogExportConfigUpdateError": [
      "UpdateProductCatalogExportConfigInvalidAttributeNamesError",
      "UpdateProductCatalogExportConfigInvalidMatrixAttributeNamesError",
      "UpdateProductCatalogExportConfigNotInDraftStateError"
    ],
    "ProductCatalogGroupArchiveRevisionError": [
      "NotLatestRevisionProductCatalogGroupArchiveRevisionError",
      "PublishedVariantsProductCatalogGroupArchiveRevisionError"
    ],
    "ProductCatalogGroupCopyRevisionAsDraftError": [
      "CopyFromDraftNotAllowedProductCatalogGroupCopyRevisionAsDraftError"
    ],
    "ProductCatalogGroupCreateDraftFromSellableError": [
      "AlreadyCreatedProductCatalogGroupDraftFromSellableError"
    ],
    "ProductCatalogGroupCreateDraftRevisionError": [
      "DraftExistsProductCatalogGroupCreateDraftRevisionError"
    ],
    "ProductCatalogGroupPublishError": [
      "ArchivedProductCatalogGroupPublishError",
      "ConflictingSkuProductCatalogGroupPublishError",
      "DerivedCategoryProductCatalogGroupPublishError",
      "InvalidCategoryProductCatalogGroupPublishError",
      "MissingAttributeProductCatalogGroupPublishError",
      "MissingAttributeValueProductCatalogGroupPublishError",
      "MissingCategoryProductCatalogGroupPublishError",
      "MissingTemplateAttributeProductCatalogGroupPublishError",
      "QualityScoreNeedsImprovementProductCatalogGroupPublishError"
    ],
    "ProductCatalogGroupPublishFromSellablesError": [
      "SellableNotFromCatalogGroupProductCatalogGroupPublishFromSellablesError"
    ],
    "ProductCatalogGroupRemoveAttributeError": [
      "MissingAttributeProductCatalogGroupUpdateError",
      "NotDraftProductCatalogGroupUpdateError"
    ],
    "ProductCatalogGroupRemoveRawAttributesError": [
      "MissingRawAttributeProductCatalogGroupUpdateError",
      "NotDraftProductCatalogGroupUpdateError"
    ],
    "ProductCatalogGroupReorderRawAttributesError": [
      "MissingRawAttributeProductCatalogGroupUpdateError",
      "NotDraftProductCatalogGroupUpdateError"
    ],
    "ProductCatalogGroupSetAttributeError": [
      "InvalidAttributeValueProductCatalogGroupUpdateError",
      "NotDraftProductCatalogGroupUpdateError"
    ],
    "ProductCatalogGroupSetRawAttributeError": [
      "NotDraftProductCatalogGroupUpdateError"
    ],
    "ProductCatalogGroupUpdateDraftError": [
      "NotDraftProductCatalogGroupUpdateError"
    ],
    "ProductCatalogLaunchScheduleDeactivateError": [
      "DefaultErrorMessage"
    ],
    "ProductCatalogLaunchScheduleUpdateError": [
      "DeactivatedProductCatalogLaunchScheduleError"
    ],
    "ProductCatalogPricingScheduleLineArchiveError": [
      "ExpiredProductCatalogPricingScheduleLineArchiveError"
    ],
    "ProductCatalogPricingScheduleLineBulkArchiveError": [
      "DefaultErrorMessage"
    ],
    "ProductCatalogPublishError": [
      "ArchivedProductCatalogPublishError",
      "ChangingProductIdentifierProductCatalogPublishError",
      "ConflictingProductIdentifierProductCatalogPublishError",
      "DerivedCategoryProductCatalogPublishError",
      "DuplicatedVariationAttributeValuesProductCatalogPublishError",
      "InconsistentVariationDimensionsProductCatalogPublishError",
      "InvalidCategoryProductCatalogPublishError",
      "InvalidVariantProductCatalogPublishError",
      "MissingAttributeProductCatalogPublishError",
      "MissingAttributeValueProductCatalogPublishError",
      "MissingCategoryProductCatalogPublishError",
      "MissingTemplateAttributeProductCatalogPublishError",
      "NoPublishedVariantParentProductCatalogPublishError",
      "QualityScoreNeedsImprovementProductCatalogPublishError"
    ],
    "ProductCatalogPublishFromSellablesError": [
      "SellableNotFromCatalogProductCatalogPublishFromSellablesError"
    ],
    "ProductCatalogRemoveAttributeError": [
      "MissingAttributeProductCatalogUpdateError",
      "NotDraftProductCatalogUpdateError"
    ],
    "ProductCatalogRemoveRawAttributesError": [
      "MissingRawAttributeProductCatalogUpdateError",
      "NotDraftProductCatalogUpdateError"
    ],
    "ProductCatalogReorderRawAttributesError": [
      "MissingRawAttributeProductCatalogUpdateError",
      "NotDraftProductCatalogUpdateError"
    ],
    "ProductCatalogSetAttributeError": [
      "InvalidAttributeValueProductCatalogUpdateError",
      "NotDraftProductCatalogUpdateError"
    ],
    "ProductCatalogSetRawAttributeError": [
      "NotDraftProductCatalogUpdateError"
    ],
    "ProductCatalogTemplateCopyError": [
      "CopyProductCatalogTemplateDraftExistsError"
    ],
    "ProductCatalogTemplateCreateError": [
      "CreateProductCatalogTemplateDraftExistsError"
    ],
    "ProductCatalogTemplatePublishError": [
      "PublishProductCatalogTemplateNotReadyError"
    ],
    "ProductCatalogTemplateUpdateError": [
      "UpdateProductCatalogTemplateNotInDraftStateError"
    ],
    "ProductCatalogUpdateDraftError": [
      "DerivedCategoryProductCatalogUpdateError",
      "NotDraftProductCatalogUpdateError"
    ],
    "ProductCustomizationAttributeOption": [
      "ProductCustomizationAttributeEnumOption",
      "ProductCustomizationAttributeIntegerRangeOption"
    ],
    "ProductCustomizationFeature": [
      "ProductCustomizationAddOnFeature",
      "ProductCustomizationAttributeFeature",
      "ProductCustomizationProductFeature"
    ],
    "ProductCustomizationFeatureSelection": [
      "ProductCustomizationAddOnFeatureSelection",
      "ProductCustomizationAttributeFeatureSelection",
      "ProductCustomizationProductFeatureSelection"
    ],
    "ProductCustomizationProductFeatureStockOption": [
      "ProductCatalogGroupRevision",
      "ProductCatalogRevision"
    ],
    "ProductFeedContentBlockItem": [
      "CuratedProductFeedContentBlockItem",
      "ExternalProductFeedContentBlockItem"
    ],
    "ProductQuestionSource": [
      "ProductQuestionGigSource",
      "ProductQuestionLLMLeadSource"
    ],
    "ProgressionPathInterstitialSchema": [
      "ContinueButtonPathInterstitialProgressionSchema",
      "TimedContinuePathInterstitialProgressionSchema"
    ],
    "PromotionActionReference": [
      "AmountOffOrderPromotionActionReference",
      "PercentOffLineItemsPromotionActionReference",
      "RewardCreditPercentPromotionActionReference",
      "RewardCreditPromotionActionReference"
    ],
    "PromotionRuleReference": [
      "CustomSourceItemPromotionRuleReference",
      "InternalUserOnlyPromotionRuleReference",
      "ItemCountPromotionRuleReference",
      "OrderAmountPromotionRuleReference",
      "PurchasedOrderCountPromotionRuleReference",
      "SellableAttributeFilterPromotionRuleReference",
      "SellableBadgeFilterPromotionRuleReference",
      "SellableBrandFilterPromotionRuleReference",
      "SellableCategoryFilterPromotionRuleReference",
      "SellableFilterPromotionRuleReference",
      "SellableFulfillmentChannelFilterPromotionRuleReference",
      "SellableFullPricePromotionRuleReference",
      "UserUsageLimitPromotionRuleReference"
    ],
    "PromotionSource": [
      "PromotionSourceClaim",
      "PromotionSourceExternalIntegration",
      "PromotionSourceFirstPurchase",
      "PromotionSourceMembership",
      "PromotionSourceReferee",
      "PromotionSourceReferrer",
      "PromotionSourceRegistration",
      "PromotionSourceSocialSharing"
    ],
    "PromptCompletionRequest": [
      "ChatCompletionRequest",
      "TextCompletionRequest"
    ],
    "PromptCompletionTaskParameters": [
      "LeadPromptTemplateParameters",
      "SellablePromptTemplateParameters"
    ],
    "PublishAffiliateCampaignRevisionError": [
      "DefaultErrorMessage"
    ],
    "PublishAffiliateSourceRevisionError": [
      "DefaultErrorMessage"
    ],
    "PublishAndPayGigError": [
      "AlreadyPaidPublishAndPayGigError",
      "ApproveApprovalRequestPublishAndPayGigError",
      "AutoApprovalNotSupportedPublishAndPayGigError",
      "IncompleteDependencyPublishAndPayGigError",
      "NotApprovedPublishAndPayGigError",
      "NotParentGigSubmissionRevisionError",
      "PublishApprovalRequestPublishAndPayGigError",
      "ShouldNotPayPublishAndPayGigError"
    ],
    "PublishBrandProfileRevisionError": [
      "NotReadyPublishBrandProfileRevisionError"
    ],
    "PublishCategoryExpertPerformanceSettingRevisionError": [
      "CategoryExpertPerformanceSettingPublishNotReadyError"
    ],
    "PublishExperimentMetricRevisionError": [
      "PublishExperimentMetricRevisionNotReadyError"
    ],
    "PublishExperimentRevisionError": [
      "DefaultErrorMessage"
    ],
    "PublishExpertTestedProductError": [
      "PublishExpertTestedProductAlreadyPublishedError",
      "PublishExpertTestedProductAttributeIsNotAvailableError",
      "PublishExpertTestedProductCreateGigSubmissionFailureError",
      "PublishExpertTestedProductExceedMaxGigClaimLimitForProductError",
      "PublishExpertTestedProductExistsGigRequestCreatedByHimselfBeforeError",
      "PublishExpertTestedProductExistsGigRequestCreatedByOperatorError",
      "PublishExpertTestedProductExistsGigSubmissionNotFinishedError",
      "PublishExpertTestedProductMustFillAllDetailsError",
      "PublishExpertTestedProductNotInEditableStateError"
    ],
    "PublishGigContentError": [
      "CatalogNotSupportedPublishGigContentError",
      "EmptyPublishGigContentError",
      "NotParentGigSubmissionRevisionError",
      "NotSupportedPublishGigContentError",
      "PublishFailurePublishGigContentError"
    ],
    "PublishInfluencerCuratedListError": [
      "DefaultErrorMessage"
    ],
    "PublishMilestonePayPlanRevisionError": [
      "PublishMilestonePayPlanNoMilestoneError",
      "PublishMilestonePayPlanNotReadyError"
    ],
    "PublishProductBundleRevisionError": [
      "ProductBundlePublishDerivedCategoryError",
      "ProductBundlePublishNoComponentError",
      "ProductBundlePublishNoSellableInComponentError",
      "ProductBundlePublishNotReadyError"
    ],
    "PublishProductCatalogsAndGroupsError": [
      "ArchivedProductCatalogGroupPublishError",
      "ArchivedProductCatalogPublishError",
      "ChangingProductIdentifierProductCatalogPublishError",
      "ConflictingProductIdentifierProductCatalogPublishError",
      "ConflictingSkuProductCatalogGroupPublishError",
      "DerivedCategoryProductCatalogGroupPublishError",
      "DerivedCategoryProductCatalogPublishError",
      "DuplicatedVariationAttributeValuesProductCatalogPublishError",
      "InconsistentVariationDimensionsProductCatalogPublishError",
      "InvalidCategoryProductCatalogGroupPublishError",
      "InvalidCategoryProductCatalogPublishError",
      "InvalidVariantProductCatalogPublishError",
      "MissingAttributeProductCatalogGroupPublishError",
      "MissingAttributeProductCatalogPublishError",
      "MissingAttributeValueProductCatalogGroupPublishError",
      "MissingAttributeValueProductCatalogPublishError",
      "MissingCategoryProductCatalogGroupPublishError",
      "MissingCategoryProductCatalogPublishError",
      "MissingTemplateAttributeProductCatalogGroupPublishError",
      "MissingTemplateAttributeProductCatalogPublishError",
      "NoPublishedVariantParentProductCatalogPublishError",
      "QualityScoreNeedsImprovementProductCatalogGroupPublishError",
      "QualityScoreNeedsImprovementProductCatalogPublishError"
    ],
    "PublishProductComparisonVideoError": [
      "MediaIdDoesNotExistPublishProductComparisonVideoError",
      "NotInDraftStateProductComparisonVideoError"
    ],
    "PublishProductCustomizationRevisionError": [
      "ProductCustomizationPublishNoFeatureError",
      "ProductCustomizationPublishNoFulfillmentChannelError",
      "ProductCustomizationPublishNoOptionInFeatureError",
      "ProductCustomizationPublishNotReadyError"
    ],
    "PublishPromptTemplateRevisionError": [
      "DefaultErrorMessage"
    ],
    "PublishPublisherRevisionError": [
      "PublishPublisherRevisionNotReadyError"
    ],
    "PublishRewardCreditGrantTemplateRevisionError": [
      "NotReadyPublishRewardCreditGrantTemplateRevisionError"
    ],
    "PublishSearchQueryRewriteRuleError": [
      "DefaultErrorMessage"
    ],
    "PublishSellableEditorialReviewRevisionError": [
      "DefaultErrorMessage"
    ],
    "PublishSellableEditorialTopListRevisionError": [
      "DefaultErrorMessage"
    ],
    "PublishSellableExpertOwnerReviewRevisionError": [
      "DefaultErrorMessage"
    ],
    "PublishSellableExpertiseRuleError": [
      "SellableExpertiseRuleIncompleteMappingError",
      "SellableExpertiseRulePublishCategoryRuleExistsError",
      "SellableExpertiseRulePublishNotReadyError"
    ],
    "PublishSuggestedActionRevisionError": [
      "PublishSuggestedActionMissingTagError",
      "PublishSuggestedActionNotReadyError"
    ],
    "PublishSuggestedMessageRevisionError": [
      "PublishSuggestedMessageMissingTagError",
      "PublishSuggestedMessageNotReadyError"
    ],
    "PublishTemplateDataSourceRevisionError": [
      "DefaultErrorMessage"
    ],
    "PublishTenantRevisionError": [
      "PublishTenantRevisionNotReadyError"
    ],
    "PublisherPayLineItemSource": [
      "PublisherOrderReferralPayLineItemSource"
    ],
    "PurchaseOrderActivityUser": [
      "Operator",
      "PartnerUser",
      "SystemUser"
    ],
    "PurchaseOrderManagementExceptionContent": [
      "PurchaseOrderManagementExceptionInventoryCOGSContent",
      "PurchaseOrderManagementExceptionInventoryFlexeMismatchContent",
      "PurchaseOrderManagementExceptionMissingCatalogContent",
      "PurchaseOrderManagementExceptionNoInvoiceContent",
      "PurchaseOrderManagementExceptionShipmentOverdueContent"
    ],
    "PurchaseOrderManagementExceptionError": [
      "UnableToSetCatalogIdWithSellable"
    ],
    "QuestionPathNodeFlowViewModel": [
      "DateQuestionPathNodeFlowViewModel",
      "InputTextQuestionPathNodeFlowViewModel",
      "ListQuestionPathNodeFlowViewModel",
      "MediaUploadQuestionPathNodeFlowViewModel",
      "NumericQuestionPathNodeFlowViewModel"
    ],
    "QuestionPathNodeFlowViewModelValidationError": [
      "DefaultQuestionPathNodeFlowViewModelValidationError",
      "RequireLoginQuestionPathNodeFlowViewModelValidationError"
    ],
    "QuestionsDisplayComponentPathNodeFlowViewModel": [
      "TcpaComplianceQuestionsDisplayComponentPathNodeFlowViewModel",
      "TermsAndConditionsQuestionsDisplayComponentPathNodeFlowViewModel"
    ],
    "RawSellableFeedSource": [
      "RawSellableGoogleShoppingFeedSource",
      "RawSellableLevelNineFeedSource"
    ],
    "RawSellableSource": [
      "DealInventoryRawSellableSource",
      "RawSellableFlxpointSource",
      "RawSellableFromuthSource",
      "RawSellableGoogleShoppingFeedSource",
      "RawSellableGoogleShoppingSearchSource",
      "RawSellableGoogleSpreadsheetSource",
      "RawSellableImpactSource",
      "RawSellableLevelNineFeedSource",
      "RawSellableScraperSource",
      "RawSellableShopifySource",
      "RawSellableWalmartSource"
    ],
    "RawSellableSourceSpider": [
      "RawSellableSourceScrapinghubSpider"
    ],
    "RawSellableStandardizationAction": [
      "RawSellableStandardizationCopyAttributeValueAction",
      "RawSellableStandardizationDeactivateAction",
      "RawSellableStandardizationHideFromConsumerSearchAction",
      "RawSellableStandardizationMarkIgnoreSpecAction",
      "RawSellableStandardizationMarkNoneVariantAction",
      "RawSellableStandardizationMarkOutOfStockAction",
      "RawSellableStandardizationRemoveAttributeAction",
      "RawSellableStandardizationRenameAttributeAction",
      "RawSellableStandardizationReplaceAttributeValueAction",
      "RawSellableStandardizationSetAttributeValueAction",
      "RawSellableStandardizationSetAttributeVariationDimensionAction",
      "RawSellableStandardizationSetBrandAction",
      "RawSellableStandardizationSetBrandIdAction",
      "RawSellableStandardizationSetCategoryAction",
      "RawSellableStandardizationSetDescriptionAction",
      "RawSellableStandardizationSetGtinAction",
      "RawSellableStandardizationSetMpnAction",
      "RawSellableStandardizationSetTitleAction",
      "RawSellableStandardizationSetVariationGroupReferenceAction",
      "RawSellableStandardizationSetVariationGroupTitleAction"
    ],
    "RawSellableStandardizationFieldMeta": [
      "RawSellableStandardizationAttributeValueFieldMeta",
      "RawSellableStandardizationAttributeVariationDimensionFieldMeta",
      "RawSellableStandardizationPredefinedFieldMeta"
    ],
    "RawSellableStandardizationRuleCondition": [
      "RawSellableStandardizationRuleBinaryCondition",
      "RawSellableStandardizationRuleConstantCondition",
      "RawSellableStandardizationRuleLogicalCondition"
    ],
    "RawSellableStandardizationRuleParameter": [
      "RawSellableStandardizationRuleEntityFieldReference",
      "RawSellableStandardizationRuleLiteralBooleanValue",
      "RawSellableStandardizationRuleLiteralDecimalValue",
      "RawSellableStandardizationRuleLiteralIdListValue",
      "RawSellableStandardizationRuleLiteralIdValue",
      "RawSellableStandardizationRuleLiteralIntegerValue",
      "RawSellableStandardizationRuleLiteralStringListValue",
      "RawSellableStandardizationRuleLiteralStringValue"
    ],
    "RedirectPathNodeConfigDestination": [
      "RedirectPathNodeConfigAbsoluteUrlDestination",
      "RedirectPathNodeConfigConsumerInboxDestination",
      "RedirectPathNodeConfigConsumerUrlDestination",
      "RedirectPathNodeConfigExpertApplyFinishDestination",
      "RedirectPathNodeConfigExpertKnowledgeTestFinishDestination",
      "RedirectPathNodeConfigFeedPostDestination",
      "RedirectPathNodeConfigRecommendedProductsDestination",
      "RedirectPathNodeConfigReturnToPreviousContextDestination",
      "RedirectPathNodeConfigSurveyFinishDestination",
      "RedirectPathNodeConfigUserMilestoneFinishDestination"
    ],
    "RedirectPathNodeFlowViewModelResult": [
      "AbsoluteUrlRedirectPathNodeFlowViewModelSuccess",
      "ApplyFinishRedirectPathNodeFlowViewModelSuccess",
      "ConsumerUrlRedirectPathNodeFlowViewModelSuccess",
      "ExpertKnowledgeTestFinishRedirectPathNodeFlowViewModelSuccess",
      "FeedPostRedirectPathNodeFlowViewModelSuccess",
      "InboxRedirectPathNodeFlowViewModelSuccess",
      "ProductRecommendationSuccessRedirectPathNodeFlowViewModelSuccess",
      "ReturnToPreviousContextRedirectPathNodeFlowViewModelSuccess",
      "SurveyResponseFinishRedirectPathNodeFlowViewModelSuccess",
      "UserMilestoneFinishRedirectPathNodeFlowViewModelSuccess"
    ],
    "ReferralSource": [
      "ReferralSourceConsumerGeneric",
      "ReferralSourceConsumerToExpert",
      "ReferralSourceExpertDirect"
    ],
    "RefundOrderReturnError": [
      "AlreadyRefundedPartnerOrderReturnError",
      "AlreadyRefundedRefundOrderReturnError",
      "NotEligibleRefundOrderReturnError",
      "NotEligibleRefundPartnerOrderReturnError"
    ],
    "RefundOrderStoreCreditError": [
      "InvalidStoreCreditRefundOrderStoreCreditError",
      "PostFailedRefundOrderStoreCreditError"
    ],
    "RegisterCustomerError": [
      "AlreadyRegisteredRegisterCustomerError",
      "FailedOAuthRegisterCustomerError",
      "InvalidEmailAddressRegisterCustomerError",
      "InvalidHandleVerificationTokenRegisterCustomerError",
      "InvalidPhoneNumberRegisterCustomerError",
      "InvalidReferralRewardCreditClaimTokenRegisterCustomerError",
      "InvalidSmsSignInRegisterCustomerError"
    ],
    "RejectApprovalRequestError": [
      "DefaultErrorMessage"
    ],
    "RejectExpertAccountChangeRequestError": [
      "ExpertAccountChangeRequestNotInSubmittedStateError"
    ],
    "RejectGigApplicationError": [
      "InvalidStateRejectGigApplicationError"
    ],
    "RejectGigSubmissionRevisionError": [
      "ArchiveErrorRejectGigSubmissionRevisionError"
    ],
    "RejectLeadRPALExclusionOverrideRequestError": [
      "RejectLeadRPALExclusionOverrideRequestAlreadyRejectedError"
    ],
    "RejectLeadTransferRequestError": [
      "NotAvailableRejectLeadTransferRequestError",
      "OtherReasonEmptyNoteRejectLeadTransferRequestError"
    ],
    "RemoveCategoryActionError": [
      "CategoryChangeNotInDraftStateSubmitEditCategoryActionError"
    ],
    "RemoveCategoryNavigationSectionError": [
      "NotInDraftStateRemoveCategoryNavigationSectionError"
    ],
    "RemoveCategoryNavigationSectionItemError": [
      "NotInDraftStateRemoveCategoryNavigationSectionItemError"
    ],
    "RemoveCommTemplateChannelError": [
      "NotInDraftStateRemoveCommTemplateChannelError"
    ],
    "RemoveConsumerProductReviewReactionError": [
      "DefaultErrorMessage"
    ],
    "RemoveEmailTemplateDataSourceError": [
      "DefaultErrorMessage"
    ],
    "RemoveExperimentRevisionMetricError": [
      "DefaultErrorMessage"
    ],
    "RemoveExperimentRevisionRuleError": [
      "DefaultErrorMessage"
    ],
    "RemoveExperimentRevisionTreatmentError": [
      "DefaultErrorMessage"
    ],
    "RemoveExpertSellableCoRecommendationReactionError": [
      "DefaultErrorMessage"
    ],
    "RemoveFeedItemError": [
      "DefaultErrorMessage"
    ],
    "RemoveGigUserClaimLimitOverrideError": [
      "DefaultErrorMessage"
    ],
    "RemoveProductBundleComponentError": [
      "ProductBundleUpdateComponentNotFoundError",
      "ProductBundleUpdateNotInDraftError"
    ],
    "RemoveProductCustomizationFeatureError": [
      "ProductCustomizationUpdateFeatureNotFoundError",
      "ProductCustomizationUpdateNotInDraftError"
    ],
    "RemoveProductCustomizationFeatureGroupError": [
      "ProductCustomizationUpdateFeatureGroupNotFoundError",
      "ProductCustomizationUpdateNotInDraftError"
    ],
    "RemovePromotionFromOrderError": [
      "DefaultErrorMessage"
    ],
    "RemoveSellableExpertiseRuleMappingError": [
      "SellableExpertiseRuleMappingNotFoundError",
      "SellableExpertiseRuleNotInDraftError"
    ],
    "RemoveTemplateDataSourceReferenceError": [
      "DefaultErrorMessage"
    ],
    "RenderedChannelContent": [
      "RenderedEmailContent",
      "RenderedFacebookContent",
      "RenderedInAppContent",
      "RenderedPushNotificationContent",
      "RenderedSlackContent",
      "RenderedSmsContent"
    ],
    "RenderedContent": [
      "MediaRenderedContent",
      "TextRenderedContent"
    ],
    "ReorderCategoryNavigationSectionItemsError": [
      "DifferenceMismatchReorderCategoryNavigationSectionItemsError",
      "NotInDraftStateReorderCategoryNavigationSectionItemsError"
    ],
    "ReorderCategoryNavigationSectionsError": [
      "DifferenceMismatchReorderCategoryNavigationSectionsError",
      "NotInDraftStateReorderCategoryNavigationSectionsError"
    ],
    "ReorderInfluencerCuratedListsError": [
      "DefaultErrorMessage"
    ],
    "ReorderProductBundleComponentsError": [
      "ProductBundleReorderMissedComponentError",
      "ProductBundleUpdateComponentNotFoundError",
      "ProductBundleUpdateNotInDraftError"
    ],
    "ReorderProductCustomizationFeatureGroupsError": [
      "ProductCustomizationReorderMissedFeatureGroupError",
      "ProductCustomizationUpdateFeatureGroupNotFoundError",
      "ProductCustomizationUpdateNotInDraftError"
    ],
    "ReorderProductCustomizationFeaturesError": [
      "ProductCustomizationReorderMissedFeatureError",
      "ProductCustomizationUpdateFeatureNotFoundError",
      "ProductCustomizationUpdateNotInDraftError"
    ],
    "RequestUpdatesOnPurchaseOrderRevisionError": [
      "PurchaseOrderRevisionEditNotInAwaitingAcknowledgementStateError"
    ],
    "RequireAuthenticationPathNodeFlowViewModelError": [
      "MustLoginRequireAuthenticationPathNodeFlowViewModelError"
    ],
    "ResendOrderPaymentVerificationError": [
      "InvalidTransactionStatusResendOrderPaymentVerificationError"
    ],
    "RetryProductComparisonVideoProcessingError": [
      "RetryProductComparisonVideoProcessingArticleCreatedError",
      "RetryProductComparisonVideoProcessingNotInActiveStateError",
      "RetryProductComparisonVideoProcessingOnProcessingError"
    ],
    "ReturnPolicy": [
      "NoReturnsAcceptedReturnPolicy",
      "StandardReturnPolicy"
    ],
    "ReviewPayableEntryError": [
      "ReviewPayableEntryAlreadyPaidError"
    ],
    "SaveExpertTestedProductError": [
      "SaveExpertTestedProductSaveFailedError"
    ],
    "SaveSellableForReviewError": [
      "DefaultErrorMessage"
    ],
    "SavedExpertCuratedItemsEditExpertCuratedItemNoteError": [
      "InvalidSavedExpertCuratedItemSavedExpertCuratedItemsEditExpertCuratedItemNoteError",
      "NoExpertCuratedItemNoteToUpdateSavedExpertCuratedItemsEditExpertCuratedItemNoteError",
      "NoteEditErrorSavedExpertCuratedItemsEditExpertCuratedItemNoteError"
    ],
    "SavedSearchResultConnection": [
      "LeadSearchConnection"
    ],
    "ScheduleSendEmailError": [
      "ScheduleExpertEmailCancelCampaignFailedError",
      "ScheduleExpertEmailConditionNotMetError",
      "ScheduleExpertEmailCreateCampaignError",
      "ScheduleExpertEmailFailedError"
    ],
    "SchedulingDemandForecastStrategy": [
      "LeadForecastBasedSchedulingDemandForecastStrategy",
      "ReplicatePreviousWeekSchedulingDemandForecastStrategy"
    ],
    "SchedulingDepartmentChangeError": [
      "SchedulingDepartmentChangeInvalidRunTimeError",
      "SchedulingDepartmentChangePendingChangeExistsError"
    ],
    "SchedulingDepartmentUpdateError": [
      "DefaultErrorMessage"
    ],
    "SchedulingUserIdentity": [
      "BusinessUser",
      "Operator"
    ],
    "SearchQueryMappingRule": [
      "ArticleQueryMappingRule",
      "CategoryQueryMappingRule",
      "RedirectQueryMappingRule",
      "SellableQueryMappingRule"
    ],
    "SellableEditorialModule": [
      "SellableEditorialCuratedTakeModule",
      "SellableEditorialExpertNoteModule",
      "SellableEditorialExpertQualificationModule",
      "SellableEditorialQAReviewModule",
      "SellableEditorialRatingReviewModule"
    ],
    "SellableEditorialReviewBlock": [
      "SellableEditorialExpertNoteBlock",
      "SellableEditorialExpertQualificationReviewBlock",
      "SellableEditorialQAReviewBlock",
      "SellableEditorialRatingReviewBlock"
    ],
    "SellableEditorialReviewItem": [
      "SellableEditorialReviewAttributeItem",
      "SellableEditorialReviewCommonItem"
    ],
    "SellableEditorialTopListAuthor": [
      "BusinessUser"
    ],
    "SellableExpertiseRuleMappingSellableAttributeValue": [
      "SellableExpertiseRuleMappingSellableAttributeDecimalRangeValue",
      "SellableExpertiseRuleMappingSellableAttributeEnumerationValue",
      "SellableExpertiseRuleMappingSellableAttributeIntegerRangeValue"
    ],
    "SellableLMRatingGenerateError": [
      "EmptyProductInfoSellableLMRatingGenerateError",
      "InvalidAttributeExplanationsSellableLMRatingGenerateError",
      "LMApiErrorSellableLMRatingGenerateError",
      "NotEnoughInformationSellableLMRatingGenerateError",
      "ParseErrorSellableLMRatingGenerateError",
      "UndefinedCategoryAttributeSellableLMRatingGenerateError"
    ],
    "SellablePriceSource": [
      "AmazonSellablePriceSource",
      "DealInventorySellablePriceSource",
      "DealSellablePriceSource",
      "DistributionCenterInventorySellablePriceSource",
      "EBaySellablePriceSource",
      "FlexeInventorySellablePriceSource",
      "GoogleShoppingSellablePriceSource",
      "RawSellableFeedSellablePriceSource",
      "RawSellableFlxpointSellablePriceSource",
      "RawSellableFromuthSellablePriceSource",
      "RawSellableGoogleProductSellablePriceSource",
      "RawSellableImpactSellablePriceSource",
      "RawSellableScraperSellablePriceSource",
      "RawSellableShopifySellablePriceSource",
      "RawSellableSpreadsheetSellablePriceSource",
      "RawSellableWalmartSellablePriceSource",
      "ScrapingSellablePriceSource",
      "YadavSellablePriceSource"
    ],
    "SellableProductCatalog": [
      "ProductCatalogGroupRevision",
      "ProductCatalogRevision"
    ],
    "SellableSearchPageModule": [
      "SellableSearchPageArticleModule",
      "SellableSearchPageConsumerExpertReviewModule",
      "SellableSearchPageConversationStarterModule",
      "SellableSearchPageTopListModule"
    ],
    "SellableSearchPageRelatedSearchItem": [
      "ArticleRevision",
      "SellableEditorialTopListRevision",
      "SellableSearchPageRelatedSearch",
      "SellableTopList"
    ],
    "SellableSearchPageTitleTemplate": [
      "SellableSearchPageTitleCustomTemplate",
      "SellableSearchPageTitleStandardTemplate"
    ],
    "SellableSource": [
      "SellableSourceExternalId",
      "SellableSourceProductBundle",
      "SellableSourceProductCatalog",
      "SellableSourceProductCatalogGroup",
      "SellableSourceStandardizedSellableGroup",
      "SellableSourceUrl"
    ],
    "SellableStockAlertUser": [
      "BusinessUser",
      "Operator",
      "User"
    ],
    "SellableTerms": [
      "ServiceSellableTerms"
    ],
    "SendExpertCuratedSearchError": [
      "DefaultErrorMessage"
    ],
    "SetExerciseTrackingCompletedError": [
      "NotInProgressSetExerciseTrackingCompletedError"
    ],
    "SetExpertCategorySeasonError": [
      "InvalidExpertCategorySeasonEndDate"
    ],
    "SetSellableLMRatingStateError": [
      "DefaultErrorMessage"
    ],
    "SetSellableStockAlertError": [
      "AlreadyExistsSetSellableStockAlertError",
      "AlreadyInStockSetSellableStockAlertError",
      "SellableNotAllowedSetSellableStockAlertError"
    ],
    "SetWishlistVisibilityError": [
      "InArchivedStateSetWishlistVisibilityError",
      "ShippingAddressNotSpecifiedSetWishlistVisibilityError"
    ],
    "SetupInfluencerPaymentMethodError": [
      "DefaultErrorMessage"
    ],
    "SetupPaymentSourcePayloadError": [
      "DefaultErrorMessage"
    ],
    "ShiftChangeRequest": [
      "ShiftGiveUpRequest",
      "ShiftSwapRequest",
      "ShiftTransferRequest"
    ],
    "ShiftProbation": [
      "ExpertShiftProbation"
    ],
    "ShiftSlotCreateError": [
      "ShiftSlotConflictError",
      "ShiftSlotUnsupportedSchedulingUserRoleError"
    ],
    "ShipmentUser": [
      "Operator",
      "SystemUser"
    ],
    "SideEffectPathEditorPayload": [
      "ExecutePathEditorMoveNodePayload",
      "ExecutePathEditorNodeConfigCommandPayload",
      "ExecutePathEditorPasteNodePayload",
      "ExecutePathEditorRemoveEdgePayload",
      "ExecutePathEditorRemoveNodePayload",
      "ExecutePathEditorSetEdgePayload"
    ],
    "SignupFinalEvalShiftError": [
      "ExpertApplicationUpdateFailure",
      "InvalidExpertApplicationState",
      "NoAvailableShift",
      "ShiftChangeTooCloseFromShiftStartTime"
    ],
    "SlugAwarePathNodeFlowViewModel": [
      "InterstitialPathNodeFlowViewModel",
      "QuestionsPathNodeFlowViewModel"
    ],
    "SocialProof": [
      "ActiveConsumerCountSocialProof",
      "CuratedFactSocialProof",
      "CurationExampleSocialProof",
      "RecentCurationCountSocialProof"
    ],
    "StartExerciseTrackingError": [
      "AlreadyStartedStartExerciseTrackingError"
    ],
    "StartLessonError": [
      "LessonTrackingAlreadyCompletedStartLessonError",
      "LessonTrackingAlreadyExistsStartLessonError",
      "MissingPublishedExerciseRevisionStartLessonError",
      "MissingTrainingScenarioStartLessonError"
    ],
    "StoreCreditTransferError": [
      "LedgerTransactionInsufficientBalanceError",
      "LedgerTransactionTransferInvalidTransferAmountError",
      "LedgerTransactionTransferSelfTransferError"
    ],
    "SubmitCreateCategoryActionError": [
      "AttributeValidationSubmitEditCategoryActionError",
      "CategoryChangeNotInDraftStateSubmitEditCategoryActionError",
      "InvalidParentSubmitEditCategoryActionError",
      "InvalidSlugSubmitEditCategoryActionError",
      "NameConflictSubmitEditCategoryActionError",
      "ParentDeactivatedSubmitEditCategoryActionError",
      "SlugInUseSubmitEditCategoryActionError"
    ],
    "SubmitDeactivateCategoryActionError": [
      "CategoryChangeNotInDraftStateSubmitEditCategoryActionError",
      "CategoryInUseSubmitDeactivateCategoryActionError",
      "HasActiveChildrenSubmitDeactivateCategoryActionError"
    ],
    "SubmitExpertAccountChangeRequestError": [
      "SubmitExpertAccountChangeAlreadyActivatedError",
      "SubmitExpertAccountChangeAlreadyDeactivatedError",
      "SubmitExpertAccountChangeRequestExistsError"
    ],
    "SubmitExpertBulkUpdateTaskError": [
      "ExpertBulkUpdateTaskInvalidStatusError"
    ],
    "SubmitExpertLeadCampaignForReviewError": [
      "SubmitExpertLeadCampaignForReviewDeliveryTimeRequiredError",
      "SubmitExpertLeadCampaignForReviewDeliveryTimeTooEarlyError",
      "SubmitExpertLeadCampaignForReviewNotInDraftStateError",
      "SubmitExpertLeadCampaignForReviewSubmitFailedError"
    ],
    "SubmitGigApplicationError": [
      "ClaimRequestSubmitGigApplicationError",
      "IncompleteSubmitGigApplicationError",
      "InvalidStateSubmitGigApplicationError",
      "RequestExpiredGigClaimError"
    ],
    "SubmitGigSubmissionRevisionForReviewError": [
      "SubmitGigSubmissionRevisionForReviewHasOptionalChildrenClaimedOrBlockedError",
      "SubmitGigSubmissionRevisionForReviewHasRequiredChildrenUnsubmittedError",
      "SubmitGigSubmissionRevisionForReviewSubmitFailedError"
    ],
    "SubmitMoveCategoryActionError": [
      "CategoryChangeNotInDraftStateSubmitEditCategoryActionError",
      "InvalidParentSubmitEditCategoryActionError",
      "NameConflictSubmitEditCategoryActionError",
      "ParentDeactivatedSubmitEditCategoryActionError"
    ],
    "SubmitUpdateCategoryActionError": [
      "AttributeValidationSubmitEditCategoryActionError",
      "CategoryChangeNotInDraftStateSubmitEditCategoryActionError",
      "InvalidSlugSubmitEditCategoryActionError",
      "NameConflictSubmitEditCategoryActionError",
      "SlugInUseSubmitEditCategoryActionError"
    ],
    "SuggestedActionDetail": [
      "ExpertHiringSuggestedActionDetail",
      "LeadSuggestedActionDetail"
    ],
    "SummarizedUserActivity": [
      "SummarizedAddSellableUserActivity",
      "SummarizedCheckoutStartedUserActivity",
      "SummarizedOrderCompletedUserActivity",
      "SummarizedPaymentInfoEnteredUserActivity",
      "SummarizedRemoveSellableUserActivity",
      "SummarizedSavedSellableUserActivity",
      "SummarizedUnSavedSellableUserActivity",
      "SummarizedViewCartUserActivity",
      "SummarizedViewCuratedListUserActivity",
      "SummarizedViewPublisherPageUserActivity",
      "SummarizedViewSellableUserActivity"
    ],
    "SupportTaskActivity": [
      "AssignSupportTaskActivity",
      "CreateSupportTaskActivity",
      "DelegateLeadSupportTaskActivity",
      "DismissSupportTaskActivity",
      "EscalateLeadForDelegationSupportTaskActivity",
      "EscalateLeadForReassignmentSupportTaskActivity",
      "EscalateSupportTaskActivity",
      "LeadSpamRiskSupportTaskActivity",
      "ReassignLeadSupportTaskActivity",
      "RequestOrderSupportForLeadSupportTaskActivity",
      "SnoozeSupportTaskActivity",
      "SuggestLeadActionsSupportTaskActivity",
      "UnassignSupportTaskActivity",
      "UnsnoozeSupportTaskActivity"
    ],
    "SupportTaskActor": [
      "Operator",
      "SystemUser"
    ],
    "SyncFlxpointOrderInvoiceError": [
      "GenericSyncFlxpointOrderInvoiceError"
    ],
    "SyncPartnerOrderCreditMemoError": [
      "GenericSyncPartnerOrderCreditMemoError"
    ],
    "SyncPartnerOrderInvoiceError": [
      "GenericSyncPartnerOrderInvoiceError"
    ],
    "SystemCuratedItemSource": [
      "PopularExpertCuratedItemFromSimilarUserSystemCuratedItemSource",
      "RecentExpertCuratedItemFromSimilarUserSystemCuratedItemSource",
      "TopRecommendationAndSimilarUserSystemCuratedItemSource",
      "TopRecommendedListSystemCuratedItemSource"
    ],
    "TaskPayload": [
      "BrandUploadTaskPayload",
      "BulkGradeSampleFreeFormResponseTaskPayload",
      "CalculateFedExBillLineItemsTaskPayload",
      "CampaignAudienceUploadTaskPayload",
      "ConnectPartnerTransactionRefundUploadTaskPayload",
      "ConnectPartnerTransactionUploadTaskPayload",
      "DealUploadTaskPayload",
      "ExpertDiscountCashUploadTaskPayload",
      "ExpertRankOverrideTaskPayload",
      "FeaturedSellableContentUploadTaskPayload",
      "FulfillmentCostVerificationUploadTaskPayload",
      "GenerateAndSaveConsumerProductReviewLMSummaryTaskPayload",
      "GenerateAndSaveConsumerProductReviewLMThemeSentimentTaskPayload",
      "GenerateAndSaveSellableLMRatingTaskPayload",
      "GigRequestUploadTaskPayload",
      "ImportCategoryInventoryPropertiesTaskPayload",
      "ImportFedExBillRevisionLineItemsTaskPayload",
      "ImportFedExRateCardRevisionLineItemsTaskPayload",
      "ImportFedExSurchargeRevisionSupportedZipcodesTaskPayload",
      "ImportFedExZoneLocatorRevisionLineItemsTaskPayload",
      "ImportProductCatalogLaunchScheduleTaskPayload",
      "ImportProductCatalogPricingScheduleLineTaskPayload",
      "ImportProductCatalogTaskPayload",
      "ImportProductComparisonVideoTaskPayload",
      "ImportProductInventoryPropertiesTaskPayload",
      "ImportPurchaseJointBusinessPlanLineItemsTaskPayload",
      "ImportPurchaseOrderLineItemsTaskPayload",
      "ImportPurchaseOrderNetDownLineItemsTaskPayload",
      "ImportPurchaseOrderRevisionLineItemsForPartnerTaskPayload",
      "ImportPurchaseOrderRevisionLineItemsTaskPayload",
      "ImportPurchaseRequestLineItemsTaskPayload",
      "ImportUpsRateCardRevisionLineItemsTaskPayload",
      "ImportUpsSurchargeRevisionSupportedZipcodesTaskPayload",
      "ImportUpsZoneLocatorRevisionLineItemsTaskPayload",
      "ImportVideoContentRevisionTaskPayload",
      "InvoicedShippingCostUploadTaskPayload",
      "ManualGLAdjustmentUploadTaskPayload",
      "MerchantUploadTaskPayload",
      "PayableActivityEntryUploadTaskPayload",
      "ProductAssortmentImportTaskPayload",
      "ProductCatalogPricingScheduleLineBulkEditTaskPayload",
      "ProductCostCorrectionUploadTaskPayload",
      "ProductQuestionGenerationTaskPayload",
      "PromptCompletionTaskPayload",
      "PurchaseOrderInvoiceImportTaskPayload",
      "PurchaseOrderReceiptImportTaskPayload",
      "PurchaseOrderShipmentRevisionImportTaskPayload",
      "SearchQueryUploadTaskPayload",
      "SellableDeactivationTaskPayload",
      "SellableExpertPerformanceSettingImportTaskPayload",
      "SellableInventoryUploadTaskPayload",
      "SellablePriceUploadTaskPayload",
      "SellableUpdateTaskPayload",
      "SellableUploadTaskPayload",
      "SellableVersusContentGenerationTaskPayload"
    ],
    "TemplateDataModel": [
      "TemplateCustomQueryDataModel",
      "TemplateDataSourceModel",
      "TemplateNamedQueryDataModel",
      "TemplateObjectTemplateDataModel"
    ],
    "TemplateMessageDeliveryRecipient": [
      "BusinessUser",
      "PartnerUser",
      "User"
    ],
    "TextLinkifyRuleLink": [
      "TextLinkifyRuleProductListingPageLink"
    ],
    "TextLinkifyRuleTarget": [
      "TextLinkifyRuleArticleTarget"
    ],
    "TicketActivityDetail": [
      "DueDateChangedActivity",
      "FieldUpdatedActivity",
      "TicketAssignedActivity",
      "TicketClosedActivity",
      "TicketCommentAddedActivity",
      "TicketCreatedActivity",
      "TicketDependencyUpdatedActivity",
      "TicketPickedUpActivity",
      "TicketQueueTransferActivity",
      "TicketReopenedActivity",
      "TicketSnoozeChangedActivity",
      "TicketTeamTransferActivity",
      "TicketUnassignedActivity",
      "TicketsMergedActivity"
    ],
    "TicketAuthor": [
      "Operator",
      "SystemUser"
    ],
    "TicketDetail": [
      "CancellationTicket",
      "ConsumerRefundTicket",
      "ExchangeTicket",
      "FollowupActionRequestTicket",
      "GeneralSupportTicket",
      "ItemClaimsGroupingTicket",
      "ItemUnavailableTicket",
      "LineItemClaimTicket",
      "LineItemTrackingUpdateTicket",
      "MerchantRefundTicket",
      "MissingItemTicket",
      "MissingSpecsTicket",
      "OrderAdjustmentTicket",
      "OrderInformationTicket",
      "OrderVerificationTicket",
      "ProductReturnTicket",
      "ReturnTicket",
      "TrackingInquiryTicket"
    ],
    "TicketEntity": [
      "CustomerCallResponseRequest",
      "FulfillmentRequest",
      "MerchantRefund",
      "OrderReturnRequest",
      "PartReplacementRequest",
      "PaymentManualRefund",
      "TradeInRequest",
      "WarrantyClaimRequest"
    ],
    "TicketRequester": [
      "BusinessUser",
      "Operator",
      "SystemUser",
      "User"
    ],
    "TicketTimelineItem": [
      "TicketActivityTimelineItem",
      "TicketCommentTimelineItem",
      "TicketOrderActivityTimelineItem",
      "TicketWorklogTimelineItem"
    ],
    "TicketUser": [
      "Operator"
    ],
    "TipMethod": [
      "TipMethodFixedAmount",
      "TipMethodPercentOfAmount"
    ],
    "TransferCoOwnerPointsError": [
      "DefaultErrorMessage"
    ],
    "TransitTimes": [
      "FedExTransitTimes",
      "UpsTransitTimes"
    ],
    "TriggerCondition": [
      "TriggerBinaryCondition",
      "TriggerExperimentCondition",
      "TriggerLogicalCondition"
    ],
    "TriggerDelayInterval": [
      "TriggerDelayDynamicInterval",
      "TriggerDelayFixedInterval",
      "TriggerDelayWithExperiment"
    ],
    "TriggerLiteralIdReference": [
      "TriggerSuggestedActionReference"
    ],
    "TriggerLiteralValue": [
      "TriggerLiteralBooleanValue",
      "TriggerLiteralDateValue",
      "TriggerLiteralDecimalValue",
      "TriggerLiteralDurationValue",
      "TriggerLiteralIdListValue",
      "TriggerLiteralIdValue",
      "TriggerLiteralIntegerValue",
      "TriggerLiteralStringListValue",
      "TriggerLiteralStringValue"
    ],
    "TriggerNode": [
      "TriggerAction",
      "TriggerBranch",
      "TriggerDelay",
      "TriggerFilter",
      "TriggerJump",
      "TriggerWait"
    ],
    "TriggerParameter": [
      "TriggerEntityAttributeValue",
      "TriggerEntityFieldReference",
      "TriggerEventParameterReference",
      "TriggerLiteralAttributes",
      "TriggerLiteralBooleanValue",
      "TriggerLiteralDateValue",
      "TriggerLiteralDecimalValue",
      "TriggerLiteralDurationValue",
      "TriggerLiteralIdListValue",
      "TriggerLiteralIdValue",
      "TriggerLiteralIntegerValue",
      "TriggerLiteralStringListValue",
      "TriggerLiteralStringValue",
      "TriggerTemplateTypeReference"
    ],
    "TriggerValidationEntity": [
      "CommTemplateType",
      "Trigger"
    ],
    "UnarchiveGigRequestError": [
      "MaxSubmissionsUnarchiveGigRequestError",
      "NotParentGigRequestError"
    ],
    "UnclaimGigSubmissionRevisionError": [
      "ApproveErrorUnclaimGigSubmissionRevisionError",
      "ArchiveErrorUnclaimGigSubmissionRevisionError"
    ],
    "UnsaveSellableForReviewError": [
      "DefaultErrorMessage"
    ],
    "UnsubscribeEmailError": [
      "UnsubscribeEmailInvalidTokenError"
    ],
    "UpdateAffiliateAdError": [
      "UpdateAffiliateAdInvalidStateError",
      "UpdateAffiliateAdLengthExceedsLimitError",
      "UpdateAffiliateAdNotInDraftStateError"
    ],
    "UpdateAffiliateAdGroupError": [
      "UpdateAffiliateAdGroupHasBeenUsedError",
      "UpdateAffiliateAdGroupInvalidStateError",
      "UpdateAffiliateAdGroupLengthExceedsLimitError",
      "UpdateAffiliateAdGroupNotInDraftStateError"
    ],
    "UpdateAffiliateCampaignRevisionError": [
      "UpdateAffiliateCampaignHasBeenUsedError",
      "UpdateAffiliateCampaignLengthExceedsLimitError",
      "UpdateAffiliateCampaignSourceNotPublishError",
      "UpdateAffiliateSlugExistsError"
    ],
    "UpdateAffiliateError": [
      "UpdateAffiliateLengthExceedsLimitError",
      "UpdateAffiliateSlugExistsError"
    ],
    "UpdateAffiliateSourceRevisionError": [
      "UpdateAffiliateSourceAlreadyExistError",
      "UpdateAffiliateSourceHasBeenUsedError",
      "UpdateAffiliateSourceNotInDraftStateError"
    ],
    "UpdateBrandError": [
      "DefaultErrorMessage"
    ],
    "UpdateBrandProfileRevisionError": [
      "NotInDraftStateUpdateBrandProfileRevisionError"
    ],
    "UpdateBusinessUserTeamError": [
      "InvalidSlackChannelBusinessUserTeamEditError",
      "NotEmptyBusinessUserTeamUpdateError",
      "SlackChannelNotFoundBusinessUserTeamEditError"
    ],
    "UpdateCategoryAssetRevisionError": [
      "EmptyContentUpdateCategoryAssetRevisionError",
      "ImageListHasEmptyFieldUpdateCategoryAssetRevisionError",
      "MarkdownHasEmptyFieldUpdateCategoryAssetRevisionError",
      "NotInDraftStateUpdateCategoryAssetRevisionError",
      "NounHasEmptyFieldUpdateCategoryAssetRevisionError",
      "PlainTextHasEmptyFieldUpdateCategoryAssetRevisionError",
      "ValidationErrorUpdateCategoryAssetRevisionError"
    ],
    "UpdateCategoryAttributeChangeRevisionError": [
      "UpdateCategoryAttributeChangeRevisionHasSameAttributeSchemaAlreadyError",
      "UpdateCategoryAttributeChangeRevisionNotInDraftStateError",
      "UpdateCategoryAttributeChangeRevisionPublishError"
    ],
    "UpdateCategoryChangeRevisionError": [
      "UpdateCategoryChangeRevisionNotInDraftStateError"
    ],
    "UpdateCategoryExpertPerformanceSettingRevisionError": [
      "CategoryExpertPerformanceSettingUpdateNotInDraftError"
    ],
    "UpdateCategoryMarginOverrideError": [
      "CategoryTargetMarginExpiredError",
      "CategoryTargetMarginInvalidEffectiveTimeError"
    ],
    "UpdateCategoryNavigationGroupRevisionError": [
      "NotInDraftStateUpdateCategoryNavigationGroupRevisionError"
    ],
    "UpdateCategoryNavigationRevisionError": [
      "NotInDraftStateUpdateCategoryNavigationRevisionError"
    ],
    "UpdateCategoryNavigationSectionError": [
      "NotInDraftStateUpdateCategoryNavigationSectionError"
    ],
    "UpdateCategoryNavigationSectionItemError": [
      "NotInDraftStateUpdateCategoryNavigationSectionItemError"
    ],
    "UpdateCommTemplateChannelError": [
      "EmptyChannelUpdateCommTemplateChannelError",
      "NotInDraftStateUpdateCommTemplateChannelError"
    ],
    "UpdateCommTemplateError": [
      "NotInDraftStateUpdateCommTemplateError"
    ],
    "UpdateCommTemplateFragmentError": [
      "NotInDraftStateUpdateCommTemplateFragmentError"
    ],
    "UpdateConsumerProductReviewError": [
      "DefaultErrorMessage",
      "InvalidPostTokenConsumerProductReviewError"
    ],
    "UpdateContentSelectionRuleError": [
      "ContentSelectionRuleInvalidPathError",
      "ContentSelectionRuleInvalidPromotionError",
      "ContentSelectionRuleInvalidRegExError"
    ],
    "UpdateContentSelectionRuleRevisionError": [
      "ContentSelectionRevisionValidationError",
      "UpdateContentSelectionRevisionEngagementDuplicatedError",
      "UpdateContentSelectionRevisionInvalidPathError",
      "UpdateContentSelectionRuleNotInDraftStateError"
    ],
    "UpdateContentSelectionToolRevisionError": [
      "UpdateContentSelectionToolComplexExperiment",
      "UpdateContentSelectionToolCreateExperimentFailure",
      "UpdateContentSelectionToolErrorExperiment",
      "UpdateContentSelectionToolNotInDraftStateError",
      "UpdateContentSelectionToolUpdateExperimentFailure",
      "UpdateContentSelectionToolUsagesPresent"
    ],
    "UpdateConversationStarterSetRevisionError": [
      "UpdateConversationStarterSetRevisionNotAllowedStateError"
    ],
    "UpdateCuratedDistributionCenterError": [
      "UpdateCuratedDistributionCenterNotCuratedError"
    ],
    "UpdateDemoProductRequestError": [
      "UpdateDemoProductRequestGenericError"
    ],
    "UpdateDraftSellableEditorialTopListRevisionError": [
      "DefaultErrorMessage"
    ],
    "UpdateEmailTemplateRevisionError": [
      "UpdateEmailTemplateContentVersionConflictError",
      "UpdateEmailTemplateInvalidAddBlockLocationError",
      "UpdateEmailTemplateInvalidAddContentLocationError",
      "UpdateEmailTemplateInvalidCopyBlockOperationError",
      "UpdateEmailTemplateInvalidDeleteBlockOperationError",
      "UpdateEmailTemplateInvalidMoveBlockOperationError",
      "UpdateEmailTemplateNotInDraftStateError",
      "UpdateEmailTemplateTemplateTypeAlreadySetError",
      "UpdateEmailTemplateUnsupportedOperationError"
    ],
    "UpdateExperimentMetricRevisionError": [
      "UpdateExperimentMetricRevisionNotInDraftStateError"
    ],
    "UpdateExperimentRevisionError": [
      "DefaultErrorMessage"
    ],
    "UpdateExperimentRevisionRuleError": [
      "DefaultErrorMessage"
    ],
    "UpdateExperimentRevisionTreatmentError": [
      "DefaultErrorMessage"
    ],
    "UpdateExpertAdvancementRuleError": [
      "InvalidRankRuleExpertAdvancementRuleError"
    ],
    "UpdateExpertBulkUpdateTaskError": [
      "ExpertBulkUpdateInvalidTaskTypeError",
      "ExpertBulkUpdateTaskInvalidStatusError"
    ],
    "UpdateExpertCategoryCommunicationError": [
      "InvalidChannelUpdateExpertCategoryCommunicationError"
    ],
    "UpdateExpertCategoryLeadSettingError": [
      "InvalidValueUpdateExpertCategoryLeadSettingError"
    ],
    "UpdateExpertLeadCampaignError": [
      "UpdateExpertLeadCampaignConditionNotMetError",
      "UpdateExpertLeadCampaignNotInEditableStateError"
    ],
    "UpdateExpertPersonnelNoteError": [
      "DefaultErrorMessage"
    ],
    "UpdateExpertSellableCoRecommendationReactionError": [
      "DefaultErrorMessage"
    ],
    "UpdateExpertTestedProductError": [
      "UpdateExpertTestedProductConditionNotMetError",
      "UpdateExpertTestedProductNotInEditableStateError"
    ],
    "UpdateExternalAdCampaignCategoryRuleError": [
      "UpdateExternalAdCategoryRuleStateNotAllowedStateError"
    ],
    "UpdateFedExBillRevisionError": [
      "FedExBillRevisionEditInvalidBillingPeriodError",
      "UpdateFedExBillRevisionExistDiffDataError",
      "UpdateFedExBillRevisionInvalidBilledBaseRate",
      "UpdateFedExBillRevisionInvalidBilledTotalRate",
      "UpdateFedExBillRevisionInvalidBilledWeight",
      "UpdateFedExBillRevisionInvalidDistributionCenter",
      "UpdateFedExBillRevisionNotInDraftStateError",
      "UpdateFedExBillRevisionNotInPublishedStateError",
      "UpdateFedExBillRevisionNotInTaskProcessingError",
      "UpdateFedExBillRevisionTaskOverlappedError"
    ],
    "UpdateFedExRateCardRevisionError": [
      "FedExRateCardRevisionEditInvalidEffectiveTimeError",
      "FedExRateCardRevisionEditInvalidWeightError",
      "UpdateFedExRateCardRevisionEnvelopeAlreadyExistsError",
      "UpdateFedExRateCardRevisionInvalidRoundingPrecisionError",
      "UpdateFedExRateCardRevisionInvalidZoneRateTypeError",
      "UpdateFedExRateCardRevisionLineItemWeightOverlapError",
      "UpdateFedExRateCardRevisionNoLineItemFoundError",
      "UpdateFedExRateCardRevisionNotInDraftStateError",
      "UpdateFedExRateCardRevisionOverlappedEffectiveDateRangeError",
      "UpdateFedExRateCardRevisionServiceTypeNotSupportedError"
    ],
    "UpdateFedExSurchargeRevisionError": [
      "FedExSurchargeRevisionEditInvalidEffectiveTimeError",
      "UpdateFedExSurchargeRevisionInvalidRateValueError",
      "UpdateFedExSurchargeRevisionInvalidZipCodeNumberError",
      "UpdateFedExSurchargeRevisionNotInDraftStateError",
      "UpdateFedExSurchargeRevisionOverlappedEffectiveDateRangeError"
    ],
    "UpdateFedExZoneLocatorRevisionError": [
      "FedExZoneLocatorRevisionEditInvalidEffectiveTimeError",
      "FedExZoneLocatorRevisionEditInvalidZipCodeNumberError",
      "FedExZoneLocatorRevisionEditZipCodeOverlapError",
      "UpdateFedExZoneLocatorRevisionLineItemZipCodeOverlapError",
      "UpdateFedExZoneLocatorRevisionNoLineItemFoundError",
      "UpdateFedExZoneLocatorRevisionNotInDraftStateError"
    ],
    "UpdateFlxpointOrderInvoiceError": [
      "UpdateFlxpointOrderInvoiceInvalidPartnerAccountError",
      "UpdateFlxpointOrderInvoiceInvalidStateError"
    ],
    "UpdateGiftCardWishlistItemError": [
      "InArchivedStateUpdateGiftCardWishlistItemError"
    ],
    "UpdateGigApplicationError": [
      "NotDraftUpdateGigApplicationError",
      "WrongTypeUpdateGigApplicationError"
    ],
    "UpdateGigRequestError": [
      "ActiveAuthorNotInWhitelistUpdateGigRequestError",
      "HasChildrenUpdateGigRequestError",
      "InvalidParentStateGigRequestError",
      "InvalidStateUpdateGigRequestError",
      "ParentHasParentGigRequestError",
      "RecursiveParentUpdateGigRequestError"
    ],
    "UpdateGigSubmissionRevisionError": [
      "CustomErrorUpdateGigSubmissionRevisionError",
      "EditorAlreadyAssignedUpdateGigSubmissionRevisionError",
      "InvalidEditorUpdateGigSubmissionRevisionError",
      "NotInDraftStateUpdateGigSubmissionRevisionError",
      "NotInReviewGigSubmissionRevisionError",
      "RequiredWordCountNotMetUpdateGigSubmissionRevisionError"
    ],
    "UpdateInfluencerCuratedListError": [
      "DefaultErrorMessage"
    ],
    "UpdateInfluencerProfileError": [
      "DefaultErrorMessage"
    ],
    "UpdateInfluencerVanityIdError": [
      "DefaultErrorMessage"
    ],
    "UpdateInventoryAdjustmentError": [
      "UpdateInventoryAdjustmentInvalidPurchaseOrderShipmentError"
    ],
    "UpdateLeadRPALExclusionOverrideRequestError": [
      "UpdateLeadRPALExclusionOverrideRequestClosedError"
    ],
    "UpdateMerchantRefundError": [
      "MissingPaymentMethodUpdateMerchantRefundError",
      "NotEnoughInfoUpdateMerchantRefundError"
    ],
    "UpdateMilestonePayPlanRevisionError": [
      "MilestonePayPlanNotInDraftError"
    ],
    "UpdateOrderExpertContributionsError": [
      "ExpertContributionUpdateError"
    ],
    "UpdateOrderReturnError": [
      "AlreadyInProgressUpdateOrderReturnError",
      "DistributionCenterOrderReturnNotFoundUpdateOrderReturnError",
      "MisMatchedMerchantReturnsUpdateOrderReturnError",
      "NotCancellableUpdateOrderReturnError",
      "NotEligibleChangeOrderError",
      "SelfServeReturnInvalidOperationUpdateOrderReturnError"
    ],
    "UpdateOrderShippingAddressError": [
      "InvalidAddressUpdateOrderShippingAddressError"
    ],
    "UpdatePageTemplateRevisionError": [
      "UpdatePageTemplateAlreadyExistError",
      "UpdatePageTemplateInvalidAddBlockLocationError",
      "UpdatePageTemplateInvalidAddContentOperationError",
      "UpdatePageTemplateInvalidComponentParameterError",
      "UpdatePageTemplateInvalidCopyBlockOperationError",
      "UpdatePageTemplateInvalidDeleteBlockOperationError",
      "UpdatePageTemplateInvalidDeleteContentOperationError",
      "UpdatePageTemplateInvalidMoveBlockOperationError",
      "UpdatePageTemplateInvalidPromotionError",
      "UpdatePageTemplateNotInDraftStateError"
    ],
    "UpdatePartnerAccountError": [
      "InvalidAccountStatePartnerAccountEditError",
      "InvalidCurrencyCodeUpdatePartnerAccountError",
      "InvalidDistributionCenterVendorUpdatePartnerAccountError",
      "InvalidFieldUpdatePartnerAccountError",
      "MissingBillDotComVendorIdUpdatePartnerAccountError",
      "MissingContactUpdatePartnerAccountError",
      "MissingFieldUpdatePartnerAccountError"
    ],
    "UpdatePartnerDistributionCenterConfigRevisionError": [
      "UpdatePartnerDistributionCenterConfigRevisionDuplicatedNameError",
      "UpdatePartnerDistributionCenterConfigRevisionInvalidSLAValueError",
      "UpdatePartnerDistributionCenterConfigRevisionNotInDraftStateError",
      "UpdatePartnerDistributionCenterConfigRevisionNotReadyError",
      "UpdatePartnerDistributionCenterConfigRevisionPartnerAccountStateNotActiveError"
    ],
    "UpdatePartnerDistributionCenterError": [
      "UpdatePartnerDistributionCenterGenericError"
    ],
    "UpdatePartnerOrderInvoiceError": [
      "UpdatePartnerOrderInvoiceIncorrectCostShipmentsError",
      "UpdatePartnerOrderInvoiceIncorrectInvoicedQuantityLineItemsError",
      "UpdatePartnerOrderInvoiceInvalidPartnerAccountError",
      "UpdatePartnerOrderInvoiceInvalidStateError",
      "UpdatePartnerOrderInvoiceNoCommentForDifferentDropShipFeeError",
      "UpdatePartnerOrderInvoiceNoReasonForDifferentUnitCostError"
    ],
    "UpdatePartnerOrderReturnError": [
      "GenericUpdatePartnerOrderReturnError"
    ],
    "UpdatePartnerUserError": [
      "InvalidAccountStatePartnerAccountEditError",
      "InvalidEmailContactError",
      "InvalidSmsContactError",
      "InvalidUserStateUpdatePartnerUserError"
    ],
    "UpdateProductBundleComponentError": [
      "ProductBundleUpdateComponentNotFoundError",
      "ProductBundleUpdateNotInDraftError"
    ],
    "UpdateProductBundleRevisionError": [
      "ProductBundleUpdateAttributeValidationError",
      "ProductBundleUpdateDerivedCategoryError",
      "ProductBundleUpdateNotInDraftError"
    ],
    "UpdateProductComparisonVideoError": [
      "ExceedsMaximumLimitProductComparisonVideoError",
      "ExpertNotAvailableProductComparisonVideoError",
      "FirstSellableNotAvailableProductComparisonVideoError",
      "IncorrectVideoUrlProductComparisonVideoError",
      "NoImagesAvailableForFirstSellableProductComparisonVideoError",
      "NoImagesAvailableForSecondSellableProductComparisonVideoError",
      "NotInDraftStateProductComparisonVideoError",
      "SameSellableProductComparisonVideoError",
      "SaveMediaFailedProductComparisonVideoError",
      "SecondSellableNotAvailableProductComparisonVideoError",
      "VideoUrlDuplicatedProductComparisonVideoError",
      "VideoUrlIsNotSharedProductComparisonVideoError"
    ],
    "UpdateProductCustomizationFeatureError": [
      "ProductCustomizationUpdateFeatureGroupNotFoundError",
      "ProductCustomizationUpdateFeatureNotFoundError",
      "ProductCustomizationUpdateFeatureTypeChangedError",
      "ProductCustomizationUpdateIncompatibleAttributeOptionTypeError",
      "ProductCustomizationUpdateNotInDraftError"
    ],
    "UpdateProductCustomizationFeatureGroupError": [
      "ProductCustomizationUpdateFeatureGroupNotFoundError",
      "ProductCustomizationUpdateFeatureNotFoundError",
      "ProductCustomizationUpdateNotInDraftError"
    ],
    "UpdateProductCustomizationRevisionError": [
      "ProductCustomizationUpdateNotInDraftError"
    ],
    "UpdatePromotionSelectionRuleRevisionError": [
      "UpdatePromotionSelectionRuleInvalidPromotionError",
      "UpdatePromotionSelectionRuleNotInDraftStateError"
    ],
    "UpdatePromptTemplateRevisionError": [
      "DefaultErrorMessage"
    ],
    "UpdatePublisherRevisionError": [
      "UpdatePublisherRevisionNotInDraftStateError"
    ],
    "UpdatePurchaseJointBusinessPlanError": [
      "PurchaseJointBusinessPlanEditDuplicatedNameError",
      "PurchaseJointBusinessPlanEditInvalidTimeRangeError",
      "UpdatePurchaseJointBusinessPlanCancelledLineItemError",
      "UpdatePurchaseJointBusinessPlanDuplicateLineItemError",
      "UpdatePurchaseJointBusinessPlanInvalidImportActionError",
      "UpdatePurchaseJointBusinessPlanInvalidModificationToAmountError",
      "UpdatePurchaseJointBusinessPlanInvalidProductCatalogError",
      "UpdatePurchaseJointBusinessPlanInvalidQuantityError",
      "UpdatePurchaseJointBusinessPlanInvalidStateError",
      "UpdatePurchaseJointBusinessPlanInvalidTotalAmountError",
      "UpdatePurchaseJointBusinessPlanInvalidUnitPriceDeductionError",
      "UpdatePurchaseJointBusinessPlanNoActiveLineItemsError",
      "UpdatePurchaseJointBusinessPlanNoLineItemFoundError",
      "UpdatePurchaseJointBusinessPlanTaskAlreadyProcessedError",
      "UpdatePurchaseJointBusinessPlanTaskStillProcessingError"
    ],
    "UpdatePurchaseJointBusinessPlanLineItemError": [
      "UpdatePurchaseJointBusinessPlanLineItemAlreadyCancelledError",
      "UpdatePurchaseJointBusinessPlanLineItemDuplicateProductCatalogError",
      "UpdatePurchaseJointBusinessPlanLineItemInvalidImportActionError",
      "UpdatePurchaseJointBusinessPlanLineItemInvalidPlanStateError",
      "UpdatePurchaseJointBusinessPlanLineItemInvalidProductCatalogError",
      "UpdatePurchaseJointBusinessPlanLineItemInvalidQuantityError",
      "UpdatePurchaseJointBusinessPlanLineItemInvalidUnitPriceDeductionError",
      "UpdatePurchaseJointBusinessPlanLineItemNotFoundError"
    ],
    "UpdatePurchaseOrderError": [
      "UpdatePurchaseOrderCancelledLineItemError",
      "UpdatePurchaseOrderCatalogNotPublishedError",
      "UpdatePurchaseOrderConfirmedQuantityGreaterThanRequestError",
      "UpdatePurchaseOrderDuplicatedFriendlyNameError",
      "UpdatePurchaseOrderInvalidCancelByDateError",
      "UpdatePurchaseOrderInvalidConfirmedShipAtError",
      "UpdatePurchaseOrderInvalidDiscountError",
      "UpdatePurchaseOrderInvalidFriendlyNameError",
      "UpdatePurchaseOrderInvalidPurchaseRequestLineItemError",
      "UpdatePurchaseOrderInvalidUnitCostError",
      "UpdatePurchaseOrderLineItemMissingFieldsError",
      "UpdatePurchaseOrderNoActiveLineItemError",
      "UpdatePurchaseOrderNoLineItemFoundError",
      "UpdatePurchaseOrderNotInModifiableStateError",
      "UpdatePurchaseOrderNotInOpenStateError",
      "UpdatePurchaseOrderPurchaseOrderIdMissingError",
      "UpdatePurchaseOrderPurchaseRequestLineItemAlreadyCreatedError",
      "UpdatePurchaseOrderUnableToCancelError"
    ],
    "UpdatePurchaseOrderInvoiceError": [
      "PurchaseOrderInvoiceEditDuplicatedPurchaseOrderReceiptLineItemsError",
      "PurchaseOrderInvoiceEditInvalidActualFreightCostError",
      "PurchaseOrderInvoiceEditInvalidPurchaseOrderReceiptLineItemError",
      "UpdatePurchaseOrderEmptyAdditionalInvoiceSubReasonError",
      "UpdatePurchaseOrderInvalidAdditionalInvoiceIdError",
      "UpdatePurchaseOrderInvoiceAlreadyConfirmedError",
      "UpdatePurchaseOrderInvoiceCancelledLineItemError",
      "UpdatePurchaseOrderInvoiceDuplicatedExternalInvoiceNumberError",
      "UpdatePurchaseOrderInvoiceEmptyAdditionalDiscountCommentsError",
      "UpdatePurchaseOrderInvoiceEmptyCommentsError",
      "UpdatePurchaseOrderInvoiceEmptyOtherCostCommentsError",
      "UpdatePurchaseOrderInvoiceInvalidAdditionalDiscountError",
      "UpdatePurchaseOrderInvoiceInvalidAdjustedQuantityError",
      "UpdatePurchaseOrderInvoiceInvalidDiscountError",
      "UpdatePurchaseOrderInvoiceInvalidInvoiceTypeError",
      "UpdatePurchaseOrderInvoiceInvalidInvoicedQuantityError",
      "UpdatePurchaseOrderInvoiceInvalidLineItemIdError",
      "UpdatePurchaseOrderInvoiceInvalidOtherCostError",
      "UpdatePurchaseOrderInvoiceInvalidStateError",
      "UpdatePurchaseOrderInvoiceInvalidUnitCostError",
      "UpdatePurchaseOrderInvoiceInvoiceLineItemAlreadyCreatedError",
      "UpdatePurchaseOrderInvoiceInvoicedAtNotSetError",
      "UpdatePurchaseOrderInvoiceInvoicedQuantityNotMatchError",
      "UpdatePurchaseOrderInvoiceMissingLineItemError",
      "UpdatePurchaseOrderInvoiceNoAdjustmentFoundError",
      "UpdatePurchaseOrderInvoiceNoExternalInvoiceItemFoundError",
      "UpdatePurchaseOrderInvoiceNoLineItemFoundError",
      "UpdatePurchaseOrderInvoiceReceiptLineItemAlreadyCreatedInTheSameInvoiceError"
    ],
    "UpdatePurchaseOrderManagementExceptionError": [
      "UpdatePurchaseOrderManagementExceptionAlreadyAcknowledgedError",
      "UpdatePurchaseOrderManagementExceptionAlreadyResolvedError",
      "UpdatePurchaseOrderManagementExceptionNoCommentEntryFoundError"
    ],
    "UpdatePurchaseOrderNetDownError": [
      "PurchaseOrderNetDownEditDuplicatedNameError",
      "PurchaseOrderNetDownEditInvalidRebateReceivedDateError",
      "PurchaseOrderNetDownNotSupportedVendorError",
      "UpdatePurchaseOrderNetDownAlreadyConfirmedError",
      "UpdatePurchaseOrderNetDownCancelledLineItemError",
      "UpdatePurchaseOrderNetDownDuplicateLineItemError",
      "UpdatePurchaseOrderNetDownInvalidImportActionError",
      "UpdatePurchaseOrderNetDownInvalidProductCatalogError",
      "UpdatePurchaseOrderNetDownInvalidQuantityError",
      "UpdatePurchaseOrderNetDownInvalidStateError",
      "UpdatePurchaseOrderNetDownInvalidUnitPriceDeductionError",
      "UpdatePurchaseOrderNetDownNoActiveLineItemsError",
      "UpdatePurchaseOrderNetDownNoInventoryItemFoundError",
      "UpdatePurchaseOrderNetDownNoLineItemFoundError"
    ],
    "UpdatePurchaseOrderReceiptError": [
      "PurchaseOrderReceiptEditDuplicatedPurchaseOrderShipmentLineItemsError",
      "PurchaseOrderReceiptEditInvalidPurchaseOrderShipmentLineItemError",
      "PurchaseOrderReceiptsEditInvalidActualShipmentArriveAtError",
      "UpdatePurchaseOrderReceiptAlreadyCancelledError",
      "UpdatePurchaseOrderReceiptAlreadyInboundedError",
      "UpdatePurchaseOrderReceiptCancelledLineItemError",
      "UpdatePurchaseOrderReceiptInvalidDamagedQuantityError",
      "UpdatePurchaseOrderReceiptInvalidInboundedAtError",
      "UpdatePurchaseOrderReceiptInvalidReceivedQuantityError",
      "UpdatePurchaseOrderReceiptInvalidStateError",
      "UpdatePurchaseOrderReceiptNoLineItemFoundError",
      "UpdatePurchaseOrderReceiptShipmentLineItemAlreadyCreatedInTheSameReceiptError"
    ],
    "UpdatePurchaseOrderRevisionError": [
      "PurchaseOrderRevisionEditInvalidRequestShipAtError",
      "UpdatePurchaseOrderRevisionCancelledLineItemError",
      "UpdatePurchaseOrderRevisionDuplicateLineItemError",
      "UpdatePurchaseOrderRevisionDuplicatedFriendlyNameError",
      "UpdatePurchaseOrderRevisionHasRemainUnresolvedFeedbackError",
      "UpdatePurchaseOrderRevisionInValidProductCatalogIdError",
      "UpdatePurchaseOrderRevisionInValidQuantityError",
      "UpdatePurchaseOrderRevisionInvalidActionError",
      "UpdatePurchaseOrderRevisionInvalidCancelByDateError",
      "UpdatePurchaseOrderRevisionInvalidDiscountError",
      "UpdatePurchaseOrderRevisionInvalidDistributionCenterError",
      "UpdatePurchaseOrderRevisionInvalidFeedbackActionError",
      "UpdatePurchaseOrderRevisionInvalidFriendlyNameError",
      "UpdatePurchaseOrderRevisionInvalidProductCatalogError",
      "UpdatePurchaseOrderRevisionInvalidUnitCostError",
      "UpdatePurchaseOrderRevisionNoAddActionInConfirmStateError",
      "UpdatePurchaseOrderRevisionNoCancelActionInConfirmStateError",
      "UpdatePurchaseOrderRevisionNoLineItemFoundError",
      "UpdatePurchaseOrderRevisionNotInAwaitingAckStateError",
      "UpdatePurchaseOrderRevisionNotInDraftStateError",
      "UpdatePurchaseOrderRevisionNotInModifiableStateError",
      "UpdatePurchaseOrderRevisionNotInModifiableStateForPartnerError",
      "UpdatePurchaseOrderRevisionNotInSubmitForReviewStateError",
      "UpdatePurchaseOrderRevisionNotPublishedError",
      "UpdatePurchaseOrderRevisionPublishError",
      "UpdatePurchaseOrderRevisionPurchaseOrderRevisionIdMissingError",
      "UpdatePurchaseOrderRevisionShipStartAtLaterThanEndAtError",
      "UpdatePurchaseOrderRevisionUnableToCancelError",
      "UpdatePurchaseOrderRevisionUnableToCloseError",
      "UpdatePurchaseOrderRevisionUnableToRevertCloseError",
      "UpdatePurchaseOrderRevisionUnknownError",
      "UpdatePurchaseOrderRevisionValidationError"
    ],
    "UpdatePurchaseOrderShipmentRevisionError": [
      "UpdatePurchaseOrderShipmentRevisionAlreadyDeliveredError",
      "UpdatePurchaseOrderShipmentRevisionAlreadyReceivedError",
      "UpdatePurchaseOrderShipmentRevisionCancelledLineItemError",
      "UpdatePurchaseOrderShipmentRevisionDuplicateProductCatalogIdError",
      "UpdatePurchaseOrderShipmentRevisionDuplicatedAdvanceShipNoticeNumberError",
      "UpdatePurchaseOrderShipmentRevisionGenericError",
      "UpdatePurchaseOrderShipmentRevisionInvalidActualShipAtError",
      "UpdatePurchaseOrderShipmentRevisionInvalidDeliveredAtError",
      "UpdatePurchaseOrderShipmentRevisionInvalidEstimatedFreightCostError",
      "UpdatePurchaseOrderShipmentRevisionInvalidPurchaseOrderLineItemError",
      "UpdatePurchaseOrderShipmentRevisionInvalidReceivedAtError",
      "UpdatePurchaseOrderShipmentRevisionInvalidShipmentStateError",
      "UpdatePurchaseOrderShipmentRevisionInvalidShippedQuantityError",
      "UpdatePurchaseOrderShipmentRevisionInvalidStateError",
      "UpdatePurchaseOrderShipmentRevisionLineItemOverQuantityError",
      "UpdatePurchaseOrderShipmentRevisionMustArchiveThroughApprovalError",
      "UpdatePurchaseOrderShipmentRevisionNoLineItemFoundError",
      "UpdatePurchaseOrderShipmentRevisionNotOtherCarrierError",
      "UpdatePurchaseOrderShipmentRevisionNotPublishedError",
      "UpdatePurchaseOrderShipmentRevisionNotSameDCError",
      "UpdatePurchaseOrderShipmentRevisionPOLineItemAlreadyCreatedInTheSameShipmentError",
      "UpdatePurchaseOrderShipmentRevisionPublishError"
    ],
    "UpdatePurchasePromotionCreditError": [
      "PurchasePromotionCreditEditDuplicatedNameError",
      "PurchasePromotionCreditEditInvalidPromoAmountError",
      "UpdatePurchasePromotionCreditInvalidStateError"
    ],
    "UpdatePurchaseRequestRevisionError": [
      "UpdatePurchaseRequestCancelledLineItemError",
      "UpdatePurchaseRequestCuratedBillingEntityError",
      "UpdatePurchaseRequestDuplicateLineItemError",
      "UpdatePurchaseRequestInvalidDiscountError",
      "UpdatePurchaseRequestInvalidDistributionCenterError",
      "UpdatePurchaseRequestInvalidImportActionError",
      "UpdatePurchaseRequestInvalidProductCatalogError",
      "UpdatePurchaseRequestInvalidQuantityError",
      "UpdatePurchaseRequestInvalidRequestShipAtError",
      "UpdatePurchaseRequestInvalidUnitCostError",
      "UpdatePurchaseRequestNoLineItemFoundError",
      "UpdatePurchaseRequestNotInDraftStateError",
      "UpdatePurchaseRequestUnknownError"
    ],
    "UpdateRegistryOrderError": [
      "DefaultErrorMessage"
    ],
    "UpdateRewardCreditGrantTemplateRevisionError": [
      "NotInDraftStateUpdateRewardCreditGrantTemplateRevisionError"
    ],
    "UpdateSearchQueryRewriteRuleError": [
      "DefaultErrorMessage"
    ],
    "UpdateSellableEditorialReviewRevisionError": [
      "UpdateSellableEditorialReviewInvalidMoveBlockOperationError",
      "UpdateSellableEditorialReviewInvalidMoveItemOperationError",
      "UpdateSellableEditorialReviewNotInDraftStateError"
    ],
    "UpdateSellableExpertOwnerReviewRevisionError": [
      "DefaultErrorMessage"
    ],
    "UpdateSellableExpertiseRequestError": [
      "DefaultErrorMessage"
    ],
    "UpdateSellableExpertiseRuleAttributeValuesError": [
      "SellableExpertiseRuleMappingNotFoundError",
      "SellableExpertiseRuleNotInDraftError"
    ],
    "UpdateSellableExpertiseRuleError": [
      "SellableExpertiseRuleDraftExistsError"
    ],
    "UpdateSellableExpertiseRuleMappingError": [
      "SellableExpertiseRuleMappingNotFoundError",
      "SellableExpertiseRuleNotInDraftError"
    ],
    "UpdateSellableSearchPageRuleRevisionError": [
      "UpdateSellableSearchPageRuleFacetAllowAndBlockListedError",
      "UpdateSellableSearchPageRuleNotAllowedStateError"
    ],
    "UpdateSellableSearchPageSitemapRuleRevisionError": [
      "UpdateSellableSearchPageSitemapRuleNotAllowedStateError"
    ],
    "UpdateSellableWishlistItemError": [
      "InArchivedStateUpdateSellableWishlistItemError"
    ],
    "UpdateShiftAssigmentRuleSetError": [
      "DefaultErrorMessage"
    ],
    "UpdateSuggestedActionRevisionError": [
      "SuggestedActionNotInDraftStateError"
    ],
    "UpdateSuggestedMessageByExpertError": [
      "DefaultErrorMessage"
    ],
    "UpdateSuggestedMessageRevisionError": [
      "SuggestedMessageNotInDraftStateError"
    ],
    "UpdateTemplateDataSourceRevisionError": [
      "DefaultErrorMessage"
    ],
    "UpdateTenantRevisionError": [
      "UpdateTenantRevisionNotInDraftStateError"
    ],
    "UpdateTextLinkifyRuleError": [
      "EditTextLinkifyRuleNoKeywordError",
      "UpdateTextLinkifyRuleInvalidStateError"
    ],
    "UpdateTrialOrderError": [
      "ItemIneligibleForPurchaseUpdateTrialOrderError",
      "TrialAlreadyCompletedUpdateTrialOrderError",
      "TrialOrderNotFoundUpdateTrialOrderError"
    ],
    "UpdateUpsRateCardRevisionError": [
      "UpdateUpsRateCardRevisionEnvelopeAlreadyExistsError",
      "UpdateUpsRateCardRevisionInvalidRoundingPrecisionError",
      "UpdateUpsRateCardRevisionLineItemWeightOverlapError",
      "UpdateUpsRateCardRevisionNoLineItemFoundError",
      "UpdateUpsRateCardRevisionNotInDraftStateError",
      "UpdateUpsRateCardRevisionOverlappedEffectiveDateRangeError",
      "UpsRateCardRevisionEditInvalidEffectiveTimeError",
      "UpsRateCardRevisionEditInvalidWeightError"
    ],
    "UpdateUpsSurchargeRevisionError": [
      "UpdateUpsSurchargeRevisionInvalidRateValueError",
      "UpdateUpsSurchargeRevisionInvalidZipCodeNumberError",
      "UpdateUpsSurchargeRevisionNotInDraftStateError",
      "UpdateUpsSurchargeRevisionOverlappedEffectiveDateRangeError",
      "UpsSurchargeRevisionEditInvalidEffectiveTimeError"
    ],
    "UpdateUpsZoneLocatorRevisionError": [
      "UpdateUpsZoneLocatorRevisionLineItemZipCodeOverlapError",
      "UpdateUpsZoneLocatorRevisionNoLineItemFoundError",
      "UpdateUpsZoneLocatorRevisionNotInDraftStateError",
      "UpsZoneLocatorRevisionEditInvalidEffectiveTimeError",
      "UpsZoneLocatorRevisionEditInvalidZipCodeNumberError",
      "UpsZoneLocatorRevisionEditZipCodeOverlapError"
    ],
    "UpdateVendorAgreementRevisionError": [
      "InvalidStateVendorAgreementRevisionError"
    ],
    "UpdateVirtualCategoryError": [
      "UpdateVirtualCategoryAlreadyExistError",
      "UpdateVirtualCategoryDuplicatePluralDisplayNameError",
      "UpdateVirtualCategoryDuplicateSingularDisplayNameError",
      "UpdateVirtualCategoryDuplicateSlugError",
      "UpdateVirtualCategoryParentNodeNotFoundError"
    ],
    "UpdateVirtualCategoryTreeRevisionError": [
      "UpdateVirtualCategoryTreeRevisionCannotMoveError",
      "UpdateVirtualCategoryTreeRevisionNotInDraftStateError"
    ],
    "UpdateWishlistError": [
      "InArchivedStateUpdateWishlistError"
    ],
    "UpdateWishlistShippingAddressError": [
      "InArchivedStateUpdateWishlistShippingAddressError"
    ],
    "UploadMediaError": [
      "UploadMediaNoFileError"
    ],
    "UpsSearchLocationError": [
      "UpsSearchLocationApiErrorError",
      "UpsSearchLocationBadRequestError",
      "UpsSearchLocationFailureError",
      "UpsSearchLocationServiceUnavailableError",
      "UpsSearchLocationUnauthorizedError"
    ],
    "UpsertCategoryInventoryPropertiesError": [
      "UpsertCategoryInventoryPropertiesMongoUpsertError"
    ],
    "UpsertProductInventoryPropertiesError": [
      "UpsertProductInventoryPropertiesMongoUpsertError"
    ],
    "UpsertSellableLMRatingError": [
      "NotParentSellableUpsertSellableLMRatingError",
      "UndefinedAttributeUpsertSellableLMRatingError"
    ],
    "UserActivity": [
      "CheckoutStartedUserActivity",
      "ViewPublisherPageUserActivity",
      "ViewSellableUserActivity",
      "ViewTipStoreCreditUserActivity",
      "VipStatusChangedActivity",
      "VoiceCallUserActivity"
    ],
    "UserHomepageModule": [
      "UserHomepageLeadModule",
      "UserHomepageOrderModule"
    ],
    "UserJourneyStage": [
      "UserJourneyLeadStage",
      "UserJourneyOrderStage"
    ],
    "UserNeedArchiveError": [
      "AlreadyArchivedUserNeedArchiveError",
      "MultipleUsersSpecifiedUserNeedArchiveError"
    ],
    "UserNeedArchived": [
      "UserNeedArchivedChildCategoryNeedExists",
      "UserNeedArchivedExpired",
      "UserNeedArchivedNoLongerNeeded",
      "UserNeedArchivedNotReadyToBuy",
      "UserNeedArchivedPurchasedOnCurated",
      "UserNeedArchivedPurchasedSomewhereElse",
      "UserNeedArchivedSplitIntoSeparateNeeds"
    ],
    "UserNeedCopyError": [
      "ArchivedUserNeedCopyError",
      "NeedExistsInCategoryUserNeedCopyError",
      "NoCategoriesUserNeedCopyError"
    ],
    "UserNeedCreateError": [
      "MultipleUsersSpecifiedUserNeedCreateError",
      "NeedForCategoryExistsUserNeedCreateError"
    ],
    "UserNeedEditNoteError": [
      "ArchivedUserNeedEditNoteError"
    ],
    "UserProfileEditAttributeError": [
      "AlreadyArchivedUserProfileArchiveError",
      "MultipleUsersSpecifiedUserNeedProfileError"
    ],
    "UserRegistrationPathNodeFlowViewModelValidationError": [
      "DefaultUserRegistrationPathNodeFlowViewModelValidationError",
      "RequireLoginUserRegistrationPathNodeFlowViewModelValidationError"
    ],
    "ValidateMappingsError": [
      "DefaultErrorMessage"
    ],
    "VendorAgreementDetail": [
      "VendorChargeBackManual",
      "VendorGuide",
      "VendorTerms"
    ],
    "Wallet": [
      "AppleWallet"
    ],
    "WarrantyContractDetails": [
      "ExtendWarrantyContractDetails"
    ],
    "WishlistItemDetail": [
      "WishlistItemGiftCardDetail",
      "WishlistItemProductBundleDetail",
      "WishlistItemSellableDetail"
    ],
    "WishlistItemOrderUserMessage": [
      "WishlistArchivedGiftCardOrderUserMessage",
      "WishlistArchivedSellableOrderUserMessage",
      "WishlistItemArchivedGiftCardOrderUserMessage",
      "WishlistItemArchivedSellableOrderUserMessage",
      "WishlistPrivateGiftCardOrderUserMessage",
      "WishlistPrivateSellableOrderUserMessage"
    ],
    "WithdrawExpertApplicationError": [
      "AlreadyWithdrawnWithdrawExpertApplicationError",
      "OnboardedWithdrawExpertApplicationError",
      "RejectedWithdrawExpertApplicationError"
    ],
    "WithdrawGigApplicationError": [
      "NotSubmittedWithdrawGigApplicationError"
    ],
    "WithdrawGigSubmissionRevisionError": [
      "DependencyErrorWithdrawGigSubmissionRevisionError",
      "NotInClaimedStatusWithdrawGigSubmissionRevisionError",
      "NotParentGigSubmissionRevisionError"
    ],
    "WithdrawInfluenceEarningError": [
      "DefaultErrorMessage"
    ]
  }
};
      export default result;
    