import React from 'react'
import { Hostname, HostnameConsumer } from '#src/app/containers/Hostname'
import config, { Config } from '#src/app/config'

export type IHostAwareConfig = Config & {
  _hostAwareConfig: true
}

const NoProviderFoundConfig: IHostAwareConfig = {
  get: () => {
    throw new Error('No config provider found!')
  },
  toObject: () => {
    throw new Error('No config provider found!')
  },
  has: () => {
    throw new Error('No config provider found!')
  },
  _hostAwareConfig: true
}

const HostConfigPrefix = /^api|^business|^consumer|^ops|^partner/

function prefixPath(hostname: Hostname, path: string) {
  return hostname === Hostname.Gcp && HostConfigPrefix.test(path) ? `gcp.${path}` : path
}

export function createHostAwareConfig(hostname: Hostname): IHostAwareConfig {
  return {
    get: (path: string) => {
      return config.get(prefixPath(hostname, path))
    },
    toObject: () => {
      return config.toObject()
    },
    has: (path: string) => {
      return config.has(prefixPath(hostname, path))
    },
    _hostAwareConfig: true
  }
}

const HostAwareConfigContext = React.createContext<IHostAwareConfig>(NoProviderFoundConfig)
const HostAwareConfigConsumer = HostAwareConfigContext.Consumer

const HostAwareConfigProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <HostnameConsumer>
    {hostname => (
      <HostAwareConfigContext.Provider value={createHostAwareConfig(hostname)}>
        {children}
      </HostAwareConfigContext.Provider>
    )}
  </HostnameConsumer>
)

export interface WithHostAwareConfigProps {
  config: IHostAwareConfig
}

export function withHostAwareConfig<P>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<P & WithHostAwareConfigProps>>
) {
  const WithHostAwareConfig: React.FC<React.PropsWithChildren<P>> = props => (
    <HostAwareConfigConsumer>
      {config => <WrappedComponent config={config} {...props} />}
    </HostAwareConfigConsumer>
  )
  return WithHostAwareConfig
}

export { HostAwareConfigProvider, HostAwareConfigConsumer, HostAwareConfigContext }
