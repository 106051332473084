// @ts-nocheck
import type * as Types from '../../generated/types';

import type { DocumentNode } from 'graphql';
import { OperatorFragmentDoc } from '../fragments/Operator.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AssumeOperatorIdentityMutationVariables = Types.Exact<{
  operatorId: Types.Scalars['OperatorId']['input'];
}>;


export type AssumeOperatorIdentityMutation = { __typename: 'Mutation', assumeOperatorIdentity: { __typename: 'AssumeOperatorIdentityPayload', error?: string | null, myself?: { __typename: 'Myself', id: any, operator?: { __typename: 'Operator', id: any, active: boolean, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, isAdmin: boolean, permissions: Array<Types.Permission>, onboardingSpecialistMessagingUserId: any, supportMessagingUserId: any, assignedTeams: Array<Types.OperatorTeam>, user: { __typename: 'User', id: any }, messagingUser: { __typename: 'MessagingUser', id: any }, assignedRoles: Array<{ __typename: 'Role', id: any, name: string, displayName: string }>, shiftPresence?: { __typename: 'OperatorShiftPresence', startsAt: any, endsAt: any } | null, knowledgeManagementUser?: { __typename: 'KnowledgeUser', id: any, lastReleaseCardViewedAt?: any | null } | null } | null } | null } };


export const AssumeOperatorIdentityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AssumeOperatorIdentity"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"operatorId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"OperatorId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assumeOperatorIdentity"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"operatorId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"operatorId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myself"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"operator"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Operator"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}},...OperatorFragmentDoc.definitions]} as unknown as DocumentNode;
export type AssumeOperatorIdentityMutationFn = Apollo.MutationFunction<AssumeOperatorIdentityMutation, AssumeOperatorIdentityMutationVariables>;
export type AssumeOperatorIdentityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssumeOperatorIdentityMutation, AssumeOperatorIdentityMutationVariables>, 'mutation'>;

    export const AssumeOperatorIdentityComponent = (props: AssumeOperatorIdentityComponentProps) => (
      <ApolloReactComponents.Mutation<AssumeOperatorIdentityMutation, AssumeOperatorIdentityMutationVariables> mutation={AssumeOperatorIdentityDocument} {...props} />
    );
    

/**
 * __useAssumeOperatorIdentityMutation__
 *
 * To run a mutation, you first call `useAssumeOperatorIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumeOperatorIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumeOperatorIdentityMutation, { data, loading, error }] = useAssumeOperatorIdentityMutation({
 *   variables: {
 *      operatorId: // value for 'operatorId'
 *   },
 * });
 */
export function useAssumeOperatorIdentityMutation(baseOptions?: Apollo.MutationHookOptions<AssumeOperatorIdentityMutation, AssumeOperatorIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssumeOperatorIdentityMutation, AssumeOperatorIdentityMutationVariables>(AssumeOperatorIdentityDocument, options);
      }
export type AssumeOperatorIdentityMutationHookResult = ReturnType<typeof useAssumeOperatorIdentityMutation>;
export type AssumeOperatorIdentityMutationResult = Apollo.MutationResult<AssumeOperatorIdentityMutation>;
export type AssumeOperatorIdentityMutationOptions = Apollo.BaseMutationOptions<AssumeOperatorIdentityMutation, AssumeOperatorIdentityMutationVariables>;