// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { OperatorFragmentDoc } from '../../fragments/Operator.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AuthenticatedUserFragment = { __typename: 'User', id: any, firstName?: string | null, lastName?: string | null, roles: Array<string>, messagingUser: { __typename: 'MessagingUser', id: any }, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null };

export type RealUserFragment = { __typename: 'User', id: any, firstName?: string | null, lastName?: string | null, assignedOperatorSeat?: { __typename: 'Operator', id: any } | null };

export type MyselfFragment = { __typename: 'Myself', id: any, user: { __typename: 'User', id: any, firstName?: string | null, lastName?: string | null, roles: Array<string>, messagingUser: { __typename: 'MessagingUser', id: any }, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null }, realUser: { __typename: 'User', id: any, firstName?: string | null, lastName?: string | null, assignedOperatorSeat?: { __typename: 'Operator', id: any } | null }, operator?: { __typename: 'Operator', id: any, active: boolean, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, isAdmin: boolean, permissions: Array<Types.Permission>, onboardingSpecialistMessagingUserId: any, supportMessagingUserId: any, assignedTeams: Array<Types.OperatorTeam>, user: { __typename: 'User', id: any }, messagingUser: { __typename: 'MessagingUser', id: any }, assignedRoles: Array<{ __typename: 'Role', id: any, name: string, displayName: string }>, shiftPresence?: { __typename: 'OperatorShiftPresence', startsAt: any, endsAt: any } | null, knowledgeManagementUser?: { __typename: 'KnowledgeUser', id: any, lastReleaseCardViewedAt?: any | null } | null } | null };

export type MyselfQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyselfQuery = { __typename: 'Query', me?: { __typename: 'Myself', id: any, user: { __typename: 'User', id: any, firstName?: string | null, lastName?: string | null, roles: Array<string>, messagingUser: { __typename: 'MessagingUser', id: any }, profilePhoto?: { __typename: 'MediaFile', id: any, url: string } | null }, realUser: { __typename: 'User', id: any, firstName?: string | null, lastName?: string | null, assignedOperatorSeat?: { __typename: 'Operator', id: any } | null }, operator?: { __typename: 'Operator', id: any, active: boolean, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, isAdmin: boolean, permissions: Array<Types.Permission>, onboardingSpecialistMessagingUserId: any, supportMessagingUserId: any, assignedTeams: Array<Types.OperatorTeam>, user: { __typename: 'User', id: any }, messagingUser: { __typename: 'MessagingUser', id: any }, assignedRoles: Array<{ __typename: 'Role', id: any, name: string, displayName: string }>, shiftPresence?: { __typename: 'OperatorShiftPresence', startsAt: any, endsAt: any } | null, knowledgeManagementUser?: { __typename: 'KnowledgeUser', id: any, lastReleaseCardViewedAt?: any | null } | null } | null } | null, session: { __typename: 'Session', id: any } };

export const AuthenticatedUserFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AuthenticatedUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"roles"}},{"kind":"Field","name":{"kind":"Name","value":"messagingUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"profilePhoto"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]} as unknown as DocumentNode;
export const RealUserFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RealUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"assignedOperatorSeat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export const MyselfFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Myself"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Myself"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthenticatedUser"}}]}},{"kind":"Field","name":{"kind":"Name","value":"realUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RealUser"}}]}},{"kind":"Field","name":{"kind":"Name","value":"operator"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Operator"}}]}}]}}]} as unknown as DocumentNode;
export const MyselfDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Myself"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Myself"}}]}},{"kind":"Field","name":{"kind":"Name","value":"session"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},...MyselfFragmentDoc.definitions,...AuthenticatedUserFragmentDoc.definitions,...RealUserFragmentDoc.definitions,...OperatorFragmentDoc.definitions]} as unknown as DocumentNode;
export type MyselfComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MyselfQuery, MyselfQueryVariables>, 'query'>;

    export const MyselfComponent = (props: MyselfComponentProps) => (
      <ApolloReactComponents.Query<MyselfQuery, MyselfQueryVariables> query={MyselfDocument} {...props} />
    );
    

/**
 * __useMyselfQuery__
 *
 * To run a query within a React component, call `useMyselfQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyselfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyselfQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyselfQuery(baseOptions?: Apollo.QueryHookOptions<MyselfQuery, MyselfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyselfQuery, MyselfQueryVariables>(MyselfDocument, options);
      }
export function useMyselfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyselfQuery, MyselfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyselfQuery, MyselfQueryVariables>(MyselfDocument, options);
        }
export function useMyselfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyselfQuery, MyselfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyselfQuery, MyselfQueryVariables>(MyselfDocument, options);
        }
export type MyselfQueryHookResult = ReturnType<typeof useMyselfQuery>;
export type MyselfLazyQueryHookResult = ReturnType<typeof useMyselfLazyQuery>;
export type MyselfSuspenseQueryHookResult = ReturnType<typeof useMyselfSuspenseQuery>;
export type MyselfQueryResult = Apollo.QueryResult<MyselfQuery, MyselfQueryVariables>;