import { Permission } from '#src/generated/types'
import { useHasPermission } from '#src/app/utils/permission'
import { useIdentityContext } from '#src/app/context/Identity'
import { useSupportConversationsCountSubscription } from '../useConversationsCount/SupportConversationsCount.generated'

export const useConversationsCount = () => {
  const { myself } = useIdentityContext()
  const [canViewMessageCenter] = useHasPermission(Permission.SUPPORT_CONVERSATION_SEARCH)

  const { data } = useSupportConversationsCountSubscription({
    variables: {
      needResponse: true
    },
    skip: !myself || !canViewMessageCenter
  })

  return data?.supportConversationsCount.conversationsCount || 0
}
