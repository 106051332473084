export default {
  "AcceptReturningUserConnectionPayload": [
    "returningUserConnection"
  ],
  "BusinessUser": [
    "activityConnection",
    "consumerExpertReviewConnection"
  ],
  "Category": [
    "consumerSellableRecommendations"
  ],
  "DeclineReturningUserConnectionPayload": [
    "returningUserConnection"
  ],
  "Department": [
    "consumerExpertReviewConnection"
  ],
  "ExpertCuratedItemsForLeadPayload": [
    "activationsConnection"
  ],
  "ExpertCuratedOrdersForLeadConnectionChangedPayload": [
    "connection"
  ],
  "FeedComment": [
    "commentsConnection"
  ],
  "FeedPost": [
    "commentsConnection"
  ],
  "FeedReactionSummaryItem": [
    "reactionsConnection"
  ],
  "PathGraphFlow": [
    "nodesConnection"
  ],
  "Query": [
    "brands",
    "callActivityConnection",
    "categories",
    "categoryChangeRevisionConnection",
    "commTemplatesConnection",
    "consumerExpertReviewConnection",
    "consumerProductReviewConnection",
    "consumerSellableCoRecommendationCategorySlates",
    "consumerSellableReactions",
    "consumerSellableRecommendations",
    "contentSelectionRuleRevisionConnection",
    "departmentExpertCuratedActivityFeed",
    "emailTemplateRevisionConnection",
    "expertApplicationFolderItems",
    "expertApplicationsConnection",
    "expertApplicationsPendingReview",
    "expertCuratedOrderListActivationsForLead",
    "expertCuratedOrdersForLeadConnection",
    "expertEmailTemplateConnection",
    "expertExpertCuratedActivityFeed",
    "expertLeadCampaignConnection",
    "expertLeadFolderItems",
    "expertSearchConnection",
    "expertSearchGigRequests",
    "expertSellableCoRecommendations",
    "expertSellableRecommendations",
    "expertTestedProductConnection",
    "expertUpdateCampaignConnection",
    "expertWishListProductConnection",
    "feedNotificationConnection",
    "feedPostConnection",
    "findFulfillmentChannels",
    "findSupportTasks",
    "findSupportTasksForOperatorFolder",
    "forYouFeedConnection",
    "fulfillmentCenters",
    "fulfillmentShipmentMethods",
    "leads",
    "mediaAssetConnection",
    "orderRefundsConnection",
    "ordersConnection",
    "pageTemplateComponentConnection",
    "pageTemplateRevisionConnection",
    "partnerOrderPaginationV2",
    "popularExpertCuratedItemsForSellable",
    "promotionsConnection",
    "purchaseJointBusinessPlanLineItemConnection",
    "rawSellableSearchConnection",
    "rawSellableStandardizationResultSearchConnection",
    "recommendedListDefinitions",
    "recommendedLists",
    "recommendedListsForCategory",
    "returningUserConnection",
    "runLeadSavedSearch",
    "savedSellablesConnection",
    "searchArticles",
    "searchDemoProductRequest",
    "searchDemoProductRequestWithFacets",
    "searchEBayItems",
    "searchGigRequests",
    "searchGigSubmissionRevisions",
    "searchHollingsworthOutboundOrders",
    "searchInventoryFlowConsolidationView",
    "searchInventoryFlowConsolidationViewOffsetBased",
    "searchPartnerAccounts",
    "searchPartnerOrderConsolidationView",
    "searchPartnerProductCatalogs",
    "searchProductCatalogGroups",
    "searchProductCatalogs",
    "searchProductsOnInventoryView",
    "searchPurchaseOrderConsolidationViews",
    "searchRecentExpertCuratedItemGroups",
    "searchSavedExpertCuratedItems",
    "searchSellableEditorialReviewRevisions",
    "searchSellables",
    "searchSuggestedAlternativeSellables",
    "searchTickets",
    "sellableExpertCuratedItemNoteStatsConnection",
    "sellableRecommendationEmailCategorySlates",
    "suggestExpertCuratedItemNotes",
    "suggestExpertCuratedItemsForLead",
    "suggestedAlternativeSellables",
    "supportConversationsConnection",
    "ticketsConnection",
    "triggersConnection"
  ],
  "RecommendedList": [
    "sellableConnection"
  ],
  "RecommendedListDefinition": [
    "previewResults"
  ],
  "ReturningUserConnectionCreatedActivity": [
    "returningUserConnection"
  ],
  "Sellable": [
    "consumerProductReviewConnection",
    "consumerSellableCoRecommendationCategorySlates",
    "consumerSellableReactions",
    "consumerSellableRecommendations",
    "recommendingExperts"
  ],
  "SellableExpertiseRequest": [
    "recommendedSellables"
  ],
  "User": [
    "consumerExpertReviewConnection"
  ]
}