import React, { PureComponent } from 'react'
import { Redirect } from '@deal/router'
import { Notification } from '@deal/components'

interface Props {
  to: string
  retainThen?: boolean
}
export default class ExternalUrlAwareRedirect extends PureComponent<Props> {
  public componentDidMount() {
    if (/^(http|https|ftp)/.test(this.props.to)) {
      window.location.href = this.props.to
    }
  }

  public render() {
    if (/^(http|https|ftp)/.test(this.props.to)) {
      return <Notification type="info">Redirecting to {this.props.to}</Notification>
    } else {
      return <Redirect to={this.props.to} />
    }
  }
}
