import React, { useContext } from 'react'
import { OperatorFragment } from '#src/app/fragments/Operator.generated'
import { MyselfComponent, MyselfFragment } from './Myself.generated'
import getPermissions, { OperatorPermissions } from './getPermissions'

// Context
type IdentityContextType = {
  myself: MyselfFragment | null | undefined
  operator: OperatorFragment | null | undefined
  permissions: OperatorPermissions | null
}

const IdentityContext = React.createContext<IdentityContextType>({
  myself: null,
  operator: null,
  permissions: null
})

// Consumer
const IdentityConsumer = IdentityContext.Consumer

// Provider
// This provider is a small wrapper around the context provider, in order to handle the loading state
const IdentityProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <MyselfComponent>
    {({ loading, error, data }) => {
      if (error) {
        return <div>Error!</div>
      } else if (loading) {
        return <div>Loading...</div>
      } else if (data) {
        return (
          <IdentityContext.Provider
            value={{
              myself: data.me,
              operator: data.me?.operator,
              permissions: data.me?.operator ? getPermissions(data.me.operator) : null
            }}
          >
            {children}
          </IdentityContext.Provider>
        )
      } else {
        // TODO: What do we do here?
        throw new Error('Unknown error in IdentityProvider.')
      }
    }}
  </MyselfComponent>
)

const useIdentityContext = () => {
  const identityContext = useContext(IdentityContext)

  if (!identityContext) {
    throw new Error('Invoked `IdentityContext` outside of provider')
  }

  return identityContext
}

export { IdentityProvider, IdentityConsumer, IdentityContext, useIdentityContext }
